
import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../../../config";
import { authHeader } from "../../../services/auth";
import CommonNotifyOnRejection from "../../../common-components/CommonNotifyOnRejection.vue";
import ViewDocument from "../../../common-components/ViewDocument.vue";
@Component({
  components: {
    ViewDocument,
    CommonNotifyOnRejection,
  },
})
export default class PayOffOrder extends Vue {
  /* eslint-disable */
  @Prop()
  public taskId: any;
  public documents: any = [];
  public isComplete = false;
  public notifyBroker = false;
  public payOffData: any = [];
  public doc: any = {};
  public error: any = {
    msg: null,
    show: false,
  };
  public isOrderPlacedOnce = false;
  // @Prop()
  // public isLoanInactive: any;
  /**
   * Taking dsData variable for checking form data is changed or not only for comaprison
   */
  public dsData: any = {};
  /**************************************************************** */
  public docLoader = false;

  public async checkSaveValidation() {
    try {
      let hasNoData = true;
      let valueIsNotSame = false;
      for (let i = 0; i < this.payOffData.creditorInfo.length; i++) {
        const e = this.payOffData.creditorInfo[i];
        if (e.option != this.dsData.creditorInfo[i].option)
          valueIsNotSame = true;
        else if (e.option) hasNoData = false;
      }
      if (
        !this.payOffData.isPayOffOrdered &&
        this.payOffData.isPayOffOrdered == this.dsData.isPayOffOrdered &&
        !valueIsNotSame &&
        hasNoData
      )
        return true;
      else return false;
    } catch (error) {
      console.log(error);
    }
  }

  public async savePayOffOrder() {
    if (this.isOrderPlacedOnce) return;

    this.callLoader(true, null);
    try {
      if (!this.isComplete) {
        let notValid1 = await this.checkSaveValidation();
        if (notValid1) {
          this.callLoader(false, null);
          this.$snotify.info("No Changes");
          return;
        }
      }

      if (this.isComplete && !this.payOffData.isPayOffOrdered) {
        this.error.show = true;
        this.error.msg = "All Fields are Required";
        this.callLoader(false, null);
        return;
      }
      if (this.isComplete && !this.doc) {
        this.error.show = true;
        this.error.msg = "Please Upload Borrower Authorization.";
        this.callLoader(false, null);
        return;
      }
      if (this.isComplete && this.doc && !this.doc.docApproved) {
        this.error.show = true;
        this.error.msg = "Borrower Authorization is not approved.";
        this.callLoader(false, null);
        return;
      }
      if (this.isComplete && this.payOffData.creditorInfo.length > 0) {
        let noInfo = this.payOffData.creditorInfo.filter(
          (e) => e.option && (e.email || e.fax)
        );
        if (noInfo.length == 0) {
          this.error.show = true;
          this.error.msg = "Please provide atleast 1 information";
          this.callLoader(false, null);
          return;
        }
      }
    

      let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      let checkvalidEmailData = this.payOffData.creditorInfo.filter(
        (e) => e.option == "email" && !e.email.match(mailformat)
      );
      let faxformat = /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;

      let checkvalidFaxData = this.payOffData.creditorInfo.filter(
        (e) => e.option == "fax" && !e.fax.match(faxformat)
      );
      if (this.isComplete && checkvalidEmailData.length > 0) {
        this.error.show = true;
        this.error.msg = "Please Enter Valid Email ";
        // edata.orderPlaced = false;
        this.callLoader(false, null);
        return;
      } else if (this.isComplete && checkvalidFaxData.length > 0) {
        this.error.show = true;
        this.error.msg = "Please Enter Valid Fax ";
        // edata.orderPlaced = false;

        this.callLoader(false, null);
        return;
      }

      if (this.isComplete) this.isOrderPlacedOnce = true;
      let response = await Axios.post(
        BASE_API_URL + "processor/savePayOffOrder",
        {
          loanId: this.$route.query.id,
          taskId: this.taskId,
          payOffData: this.payOffData,
          isComplete: this.isComplete,
        },
        {
          headers: authHeader(),
        }
      );

      if (response.status === 201)
        this.$snotify.success(
          this.isComplete
            ? "Pay Off Order Completed successfully."
            : "Pay Off Order Information has been saved"
        );
      if (this.isComplete) this.$emit("refresh-task-list", true);
      await this.getPayOffOrder();
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async getPayOffOrder() {
    this.callLoader(true, null);
    try {
      const response = await Axios.get(
        BASE_API_URL +
          "processor/getPayOffOrder/" +
          this.$route.query.id +
          "/" +
          this.taskId,
        { headers: authHeader() }
      );
      if (response.status === 201) {
        this.payOffData = JSON.parse(JSON.stringify(response.data));
        this.dsData = JSON.parse(JSON.stringify(response.data));
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async uploadFile(event) {
    this.docLoader = true;
    try {
      if (event.target.files.length <= 0) return;
      const formData: any = new FormData();
      Array.from(event.target.files).forEach((element) => {
        formData.append("file", element);
      });
      let loanId: any = this.$route.query.id;
      formData.append("loanId", loanId);
      formData.append("taskId", this.taskId);
      formData.append("docName", "Borrower Authorization");
      const response = await Axios.post(
        BASE_API_URL + "processor/uploadBorrowerAuthorization",
        formData,
        { headers: authHeader() }
      );
      if (response.status === 201) this.$snotify.success(response.data.message);
      this.error.msg = null;
      this.error.show = false;
      this.doc = response.data.doc;
      await this.getBorrowerAuthorizationrDocument();
    } catch (error) {
      console.log(error);
    }
    this.docLoader = false;
  }

  public async isBrokerNotifiedForRejectedDocs() {
    try {
      let res = await Axios.get(
        BASE_API_URL +
          "loanChecklist/isBrokerNotifiedForRejectedDocs/" +
          this.$route.query.id +
          "/" +
          this.taskId +
          "/" +
          null,
        {
          headers: authHeader(),
        }
      );
      if (res.status == 201) {
        this.notifyBroker = res.data.notifyBorker;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async getBorrowerAuthorizationrDocument() {
    this.callLoader(true, 1);
    try {
      let response = await Axios.get(
        BASE_API_URL +
          "processor/getBorrowerAuthorizationrDocument/" +
          this.$route.query.id,
        {
          headers: authHeader(),
        }
      );

      if (response.status === 201) {
        this.doc = response.data;
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, 1);
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public async viewDocument(document) {
    try {
      (document.loanId = this.$route.query.id),
        this.$refs.ref["showFile"](document);
    } catch (error) {
      console.log(error);
    }
  }

  async mounted() {
    await this.getBorrowerAuthorizationrDocument();
    await this.getPayOffOrder();
    await this.isBrokerNotifiedForRejectedDocs();
  }
}
