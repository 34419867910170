
import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../../../config";
import { authHeader } from "../../../services/auth";
import ViewDocument from "../../../common-components/ViewDocument.vue";
import FollowUpTask from "../../../common-components/FollowUpTask.vue";
import { HazardBinderReceivedChecklist } from "../../../models/workflow/hazardBinderReceived.model";
import datepicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import ReOrderTask from "../../../common-components/ReOrderTask.vue";

@Component({
  components: {
    ViewDocument,
    FollowUpTask,
    datepicker,
    ReOrderTask,
  },
})
export default class HazardBinderReceived extends Vue {
  /* eslint-disable */

  public hazardBinderRecieved: any = new HazardBinderReceivedChecklist();
  public hazardBinderRecievedData: any = new HazardBinderReceivedChecklist();
  @Prop()
  public taskId: any;
  public isComplete = false;
  public loanId: any = this.$route.query.id;
  public followUpHistory: any = [];
  public error: any = {
    show: false,
    msg: null,
  };

  public revisedUploading = false;
  public docLoader = false;
  public isCompleted = false;
  // @Prop()
  // public isLoanInactive: any;
  public countToSendOnServer: any = null;
  public showHistoryBtn = false;

  @Prop()
  public reOrderCount: any;
  public changedValues: any = [];
  @Prop()
  public isTaskDisabled: any;

  public agentContactId = null;
  public isOrderPlacedOnce = false 

  public async uploadReport(event, status) {
    if (this.isTaskDisabled) {
      this.callLoader(false, null);
      this.$snotify.info("Task is not assigned to you.");
      this.getHazardBinderReceived();
      return;
    }
    status != "Revised"
      ? (this.docLoader = true)
      : (this.revisedUploading = true);
    try {
      if (event.target.files.length <= 0) return;
      const formData: any = new FormData();
      Array.from(event.target.files).forEach((element) => {
        formData.append("file", element);
      });
      formData.append("loanId", this.loanId);
      formData.append("reOrderCount", this.countToSendOnServer);
      formData.append("taskId", this.taskId);
      const response = await Axios.post(
        BASE_API_URL + "processor/uploadHazardReport",
        formData,
        { headers: authHeader() }
      );
      if (response.status === 201) this.$snotify.success(response.data.message);
      this.hazardBinderRecieved.loanChecklistData =
        response.data.loanChecklistData;
      this.error.msg = null;
      this.error.show = false;
    } catch (error) {
      console.log(error);
    }
    status != "Revised"
      ? (this.docLoader = false)
      : (this.revisedUploading = false);
  }

  public async saveHazardRecievedTask() {
     if(this.isOrderPlacedOnce) return
    this.callLoader(true, null);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getHazardBinderReceived();
        return;
      }
      if (
        this.isComplete &&
        this.hazardBinderRecieved.monthlyPaymentOn1003CorrectlyReflected.mode ==
          "No"
      ) {
        this.error.show = true;
        this.error.msg = "Please select yes for the Question No. 8";
        this.callLoader(false, null);
        return;
      }

      if (this.isComplete && !this.hazardBinderRecieved.isHazardRecieved) {
        this.error.show = true;
        this.error.msg = "All fields are Required.";
        this.callLoader(false, null);
        return;
      }
      if (this.isComplete && !this.hazardBinderRecieved.loanChecklistData) {
        this.error.show = true;
        this.error.msg = "Upload Hazard Binder Report";
        this.callLoader(false, null);
        return;
      }
      if (
        this.isComplete &&
        this.hazardBinderRecieved.loanChecklistData.revisedDoc &&
        this.hazardBinderRecieved.loanChecklistData.docLength < 2
      ) {
        this.error.show = true;
        this.error.msg = "Upload Hazard Binder Updated Report.";
        await this.callLoader(false, null);
        return;
      }
      if(this.isComplete)
      this.isOrderPlacedOnce = true
      const response = await Axios.post(
        `${BASE_API_URL}processor/saveHazardRecievedTask`,
        {
          hazardBinderRecieved: this.hazardBinderRecieved,
          taskId: this.taskId,
          loanId: this.$route.query.id,
          isComplete: this.isComplete,
          reOrderCount: this.countToSendOnServer,
          changedValues: this.changedValues,
        },
        {
          headers: authHeader(),
        }
      );
      // if (!this.isComplete) {
      //   this.$snotify.success(
      //     "Hazard Binder Received Information has been saved"
      //   );
      // }

      if (response.status === 201 && this.isComplete)
        this.$snotify.success(response.data.msg);
      this.error.show = false;
      this.error.msg = null;
      if (this.isComplete) this.$emit("refresh-task-list", true);
      await this.getHazardBinderReceived();
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async handleChange(currentVal, previousVal, fieldName) {
    let index = this.changedValues.findIndex((c) => c.name === fieldName);
    if (index !== -1) {
      if (currentVal === previousVal) {
        this.changedValues.splice(index, 1);
      } else {
        let data = this.changedValues.find((v) => v.name === fieldName);
        data.from = previousVal;
        data.to = currentVal;
      }
    } else {
      this.changedValues.push({
        name: fieldName,
        from: previousVal,
        to: currentVal,
      });
    }
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public async getHazardBinderReceived() {
    this.changedValues = [];
    this.callLoader(true, null);
    try {
      const response = await Axios.get(
        BASE_API_URL +
          "processor/getHazardBinderReceived/" +
          this.$route.query.id +
          "/" +
          this.taskId +
          "/" +
          this.countToSendOnServer,
        {
          headers: authHeader(),
        }
      );

      if (response.status === 201) {
        this.agentContactId = response.data.agentContactId;
        this.hazardBinderRecieved = response.data.hazardBinderRecieved
          ? JSON.parse(JSON.stringify(response.data.hazardBinderRecieved))
          : new HazardBinderReceivedChecklist();
        this.hazardBinderRecieved.loanChecklistData =
          response.data.loanChecklistData;
        this.isCompleted = response.data.isCompleted;
        this.showHistoryBtn = response.data.showHistoryBtn;
        this.hazardBinderRecievedData = JSON.parse(
          JSON.stringify(this.hazardBinderRecieved)
        );
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async viewDocument(document) {
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getHazardBinderReceived();
        return;
      }
      document.loanId = this.$route.query.id;
      this.$refs.ref["showFile"](document);
    } catch (error) {
      console.log(error);
    }
  }

  public async sendFollowUpMailToHazardAgent() {
    this.callLoader(true, 1);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getHazardBinderReceived();
        return;
      }
      this.$refs.refFollowUp["openFollowUpModal"]({
        label: "Message To Hazard Agent",
        message: "Follow Up",
        apiPath: `processor/sendFollowUpMailToHazardAgent`,
        data: {
          loanId: this.$route.query.id,
          taskId: this.taskId,
          agentContactId: this.agentContactId,
          reOrderCount: this.countToSendOnServer
        },
      });
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async viewFollowUpHistory() {
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getHazardBinderReceived();
        return;
      }
      
      this.$refs.refFollowUp["openHistoryModal"]({
        label: "Select method to send",
        message: "Follow Up",
        apiPath: `processor/getFollowUpHistoryOfTitle`,
        data: { loanId: this.$route.query.id, taskId: this.taskId, reOrderCount: this.countToSendOnServer },
      });
    } catch (error) {
      console.log(error);
    }
  }

  public async showHistory() {
    this.callLoader(true, 1);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getHazardBinderReceived();
        return;
      }
      this.$refs.refReOrder["showHistory"](this.taskId);
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public showUploadRevisedReportDiv() {
    let show = false;
    let value;
    let key;
    for ([key, value] of Object.entries(this.hazardBinderRecieved)) {
      if (
        value &&
        Object.keys(value).length > 0 &&
        key != "createdBy" &&
        key != "createdOn" &&
        key != "loanChecklistData" &&
        key != "updatedBy" &&
        key != "updatedOn"
      ) {
        if (value.mode == "No" && value.revisionRequestedOn) {
          show = true;
          this.hazardBinderRecieved.loanChecklistData.revisedDoc = true;
        }
      }
    }

    return show;
  }
  public async allMountFunction(count) {
    this.countToSendOnServer = count;
    await this.getHazardBinderReceived();
  }

  public async mounted() {
    await this.allMountFunction(this.reOrderCount);
  }
}
