var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"white-card loan-process-height-249 mb-2 d-flex flex-column"},[_c('div',{staticClass:"loan-process-heading"},[_c('div',{staticClass:"col-lg-12"},[_c('p',{staticClass:"heading mb-0"},[_vm._v(" "+_vm._s(_vm.secondAppraisalCountForServer ? "Second" : "")+" COC - Manufactured Home ")])])]),_c('div',{staticClass:"col-lg-12 pt-3 pb-3 flex-1 height_100"},[_c('div',{staticClass:"row m-0 flex-column min_293"},[_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"col-lg-12 p-0"},[_c('div',{staticClass:"custom-checkbox mb-1 d-flex"},[_c('label',{staticClass:"label"},[_vm._v("Not Applicable ")]),_c('div',{staticClass:"position_relative ml-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataObj.nA),expression:"dataObj.nA"}],class:[
                  _vm.isTaskCompleted
                    ? 'custom-checkbox-input cursor-pointer cursor-not-allow'
                    : 'custom-checkbox-input cursor-pointer',
                ],attrs:{"type":"checkbox","disabled":_vm.isTaskCompleted},domProps:{"checked":Array.isArray(_vm.dataObj.nA)?_vm._i(_vm.dataObj.nA,null)>-1:(_vm.dataObj.nA)},on:{"change":[function($event){var $$a=_vm.dataObj.nA,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.dataObj, "nA", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.dataObj, "nA", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.dataObj, "nA", $$c)}},function($event){_vm.error = {
                    show: false,
                    msg: null,
                  }}]}}),_c('div',{staticClass:"indicator"})])]),_c('hr',{staticClass:"loan-process-border-top mb-1 mt-1"})]),(!_vm.dataObj.nA)?_c('div',[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-lg-3 col-md-6 col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label"},[_vm._v("Interest Rate")]),_c('vueNumeric',{staticClass:"login-input",attrs:{"precision":3},on:{"input":function($event){_vm.error.show = false;
                    _vm.error.msg = null;}},model:{value:(_vm.dataObj.interestRate),callback:function ($$v) {_vm.$set(_vm.dataObj, "interestRate", $$v)},expression:"dataObj.interestRate"}})],1)]),_c('div',{staticClass:"col-lg-3 col-md-6 col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label"},[_vm._v("Property Type")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataObj.propertyType),expression:"dataObj.propertyType"}],staticClass:"login-input",attrs:{"type":"text"},domProps:{"value":(_vm.dataObj.propertyType)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.dataObj, "propertyType", $event.target.value)},function($event){_vm.error.show = false;
                    _vm.error.msg = null;}]}})])]),_c('div',{staticClass:"col-lg-3 col-md-6 col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label"},[_vm._v("Occupancy")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataObj.occupancy),expression:"dataObj.occupancy"}],staticClass:"login-input",attrs:{"type":"text"},domProps:{"value":(_vm.dataObj.occupancy)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.dataObj, "occupancy", $event.target.value)},function($event){_vm.error.show = false;
                    _vm.error.msg = null;}]}})])]),_c('div',{staticClass:"col-lg-3 col-md-6 col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label"},[_vm._v("Product/Program Type")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataObj.productProgramType),expression:"dataObj.productProgramType"}],staticClass:"login-input",attrs:{"type":"text"},domProps:{"value":(_vm.dataObj.productProgramType)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.dataObj, "productProgramType", $event.target.value)},function($event){_vm.error.show = false;
                    _vm.error.msg = null;}]}})])]),_c('div',{staticClass:"col-lg-3 col-md-6 col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label"},[_vm._v("Appraised Value")]),_c('vueNumeric',{staticClass:"login-input",attrs:{"precision":2,"currency":"$"},on:{"input":function($event){_vm.error.show = false;
                    _vm.error.msg = null;}},model:{value:(_vm.dataObj.appraisedValue),callback:function ($$v) {_vm.$set(_vm.dataObj, "appraisedValue", $$v)},expression:"dataObj.appraisedValue"}})],1)]),_c('div',{staticClass:"col-lg-3 col-md-6 col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label"},[_vm._v("Sales Price")]),_c('vueNumeric',{staticClass:"login-input",attrs:{"precision":2,"currency":"$"},on:{"input":function($event){_vm.error.show = false;
                    _vm.error.msg = null;}},model:{value:(_vm.dataObj.salesPrice),callback:function ($$v) {_vm.$set(_vm.dataObj, "salesPrice", $$v)},expression:"dataObj.salesPrice"}})],1)]),_c('div',{staticClass:"col-lg-3 col-md-6 col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label"},[_vm._v("Base Loan Amount")]),_c('vueNumeric',{staticClass:"login-input",attrs:{"precision":2,"currency":"$"},on:{"input":function($event){_vm.error.show = false;
                    _vm.error.msg = null;}},model:{value:(_vm.dataObj.baseLoanAmount),callback:function ($$v) {_vm.$set(_vm.dataObj, "baseLoanAmount", $$v)},expression:"dataObj.baseLoanAmount"}})],1)]),_c('div',{staticClass:"col-lg-3 col-md-6 col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label"},[_vm._v("Escrow / Impounds")]),_c('vueNumeric',{staticClass:"login-input",attrs:{"precision":2,"currency":"$"},on:{"input":function($event){_vm.error.show = false;
                    _vm.error.msg = null;}},model:{value:(_vm.dataObj.escrowImpounds),callback:function ($$v) {_vm.$set(_vm.dataObj, "escrowImpounds", $$v)},expression:"dataObj.escrowImpounds"}})],1)]),_c('div',{staticClass:"col-lg-3 col-md-6 col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label"},[_vm._v("Address Change")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataObj.addressChange),expression:"dataObj.addressChange"}],staticClass:"login-input",attrs:{"type":"text"},domProps:{"value":(_vm.dataObj.addressChange)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.dataObj, "addressChange", $event.target.value)},function($event){_vm.error.show = false;
                    _vm.error.msg = null;}]}})])]),_c('div',{staticClass:"col-lg-3 col-md-6 col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label"},[_vm._v("Other")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataObj.other),expression:"dataObj.other"}],staticClass:"login-input",attrs:{"type":"text"},domProps:{"value":(_vm.dataObj.other)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.dataObj, "other", $event.target.value)},function($event){_vm.error.show = false;
                    _vm.error.msg = null;}]}})])])])]):_vm._e()])]),_c('hr',{staticClass:"loan-process-border-top"}),_c('div',{staticClass:"d-flex justify-content-end form-group align-items-center-768 flex_767"},[(_vm.error.show)?_c('span',{staticClass:"login-error show mr-2 show_768"},[_vm._v(" "+_vm._s(_vm.error.msg)+" ")]):_vm._e(),_c('div',[_c('button',{staticClass:"tmpco-btn tmpco-btn__default-blue mr-2",class:[
            _vm.isTaskCompleted
              ? 'btn-upload cursor-pointer cursor-not-allow'
              : 'btn-upload cursor-pointer',
          ],attrs:{"type":"button","disabled":_vm.isTaskCompleted},on:{"click":function($event){return _vm.saveChangeOfCircumstance(false)}}},[_vm._v(" Save ")]),_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-theme height_40",class:[
            _vm.isTaskCompleted
              ? 'btn-upload cursor-pointer cursor-not-allow'
              : 'btn-upload cursor-pointer',
          ],attrs:{"type":"submit","disabled":_vm.isTaskCompleted},on:{"click":function($event){return _vm.$modal.show('cocConfirmationModal')}}},[_vm._v(" "+_vm._s(_vm.isTaskCompleted ? "Completed" : "Complete")+" ")])]),_c('div',{staticClass:"show_767"},[(_vm.error.show)?_c('span',{staticClass:"login-error show mr-2"},[_vm._v(" "+_vm._s(_vm.error.msg)+" ")]):_vm._e()])]),_c('modal',{staticClass:"modal",attrs:{"centered":"","name":"cocConfirmationModal","hide-footer":true}},[_c('div',{staticClass:"modal--md-regular"},[_c('div',{staticClass:"tmpco-modal__content"},[_c('div',{staticClass:"tmpco-modal__header"},[_c('p',{staticClass:"tmpco-modal__title"},[_vm._v("COC - Manufactured Home")]),_c('button',{staticClass:"tmpco-modal__close",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":function($event){return _vm.$modal.hide('cocConfirmationModal')}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"tmpco-modal__body"},[_c('div',{staticClass:"col-lg-12 col-xl-12"},[_c('p',{staticClass:"mt-3 mb-3 content-modal"},[_vm._v(" Are you sure you want to complete? ")])])]),_c('div',{staticClass:"tmpco-modal__footer"},[_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-default mr-2",on:{"click":function($event){return _vm.$modal.hide('cocConfirmationModal')}}},[_vm._v(" No ")]),_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-theme",attrs:{"type":"button"},on:{"click":function($event){_vm.saveChangeOfCircumstance(true);
                _vm.$modal.hide('cocConfirmationModal');}}},[_vm._v(" Yes ")])])])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }