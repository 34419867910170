
import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../../../config";
import { authHeader } from "../../../services/auth";
// import CommonNotifyOnRejection from "../../../common-components/CommonNotifyOnRejection.vue";
import ViewDocument from "../../../common-components/ViewDocument.vue";

@Component({
  components: {
    ViewDocument,
    // CommonNotifyOnRejection,
  },
})
export default class ReInspectionLenderRequestReceived extends Vue {
  /* eslint-disable */
  @Prop()
  public taskId: any;
  public loanId: any = this.$route.query.id;
  public reInspectionLenderRequestReceived: any = {};
  public error: any = {
    show: false,
    msg: null,
  };
  /**
   * Taking dsData variable for checking form data is changed or not only for comaprison
   */
  public dsData: any = {};
  /**************************************************************** */


  public docLoader = false;
  public isCompleted = false;
  @Prop()
  public isLoanInactive: any;
  public isOrderPlacedOnce = false;
  // public notifyBroker = false;

 

public async checkSaveValidation() {
    try {
      if (!this.reInspectionLenderRequestReceived.isreInspectionLenderRequestRecieved &&
      this.reInspectionLenderRequestReceived.isreInspectionLenderRequestRecieved == this.dsData.isreInspectionLenderRequestRecieved)
        return true;
      else return false;
    } catch (error) {
      console.log(error);
    }
  }

  public async saveReInspectionLenderRequestReceived(isComplete) {
        if(this.isOrderPlacedOnce) return
    this.callLoader(true, null);
    try {
      if (!isComplete)  {
        let notValid1 = await this.checkSaveValidation();
        if (notValid1) {
          this.callLoader(false, null);
          this.$snotify.info('No Changes')
          return;
        }
      }
      if (
        isComplete &&
        !this.reInspectionLenderRequestReceived.isreInspectionLenderRequestRecieved
      ) {
        this.error.show = true;
        this.error.msg = "All fields are Required.";
        this.callLoader(false, null);
        return;
      }
     
   if(isComplete) this.isOrderPlacedOnce=true;
      const response = await Axios.post(
        `${BASE_API_URL}processor/saveReInspectionLenderRequestReceived`,
        {
          reInspectionLenderRequestReceived: this.reInspectionLenderRequestReceived,
          taskId: this.taskId,
          loanId: this.$route.query.id,
          isComplete: isComplete,
          formData: this.dsData
        },
        {
          headers: authHeader(),
        }
      );

       if (!isComplete) {
        this.$snotify.success(
          "Re-Inspection Lenders Request Received Information has been saved"
        );
      }

      if (response.status === 201 && isComplete) {
        this.$snotify.success(response.data.msg);
        this.$emit("refresh-task-list", true);
      }
      await this.getReInspectionLenderRequestReceived();
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public async getReInspectionLenderRequestReceived() {
    this.callLoader(true, null);
    try {
      const response = await Axios.get(
        BASE_API_URL +
          "processor/getReInspectionLenderRequestReceived/" +
          this.$route.query.id +
          "/" +
          this.taskId,
        {
          headers: authHeader(),
        }
      );

      if (response.status === 201) {
        this.isCompleted = response.data.isCompleted;
        this.reInspectionLenderRequestReceived = JSON.parse(JSON.stringify(response.data.reInspectionLenderRequestReceived));
        this.dsData = JSON.parse(JSON.stringify(response.data.reInspectionLenderRequestReceived));
       // this.reInspectionLenderRequestReceived.loanChecklistData = response.data.loanChecklistData;
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }



  

  public mounted() {
    this.getReInspectionLenderRequestReceived();
  }
}
