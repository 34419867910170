
import { Component, Vue, Prop } from "vue-property-decorator";
import {
  Asset,
  OtherAssets,
  OtherCredits,
} from "../../../models/urla/financial-info.model";
import Axios from "axios";
import Multiselect from "vue-multiselect";
import { BASE_API_URL, EventBus } from "../../../config";
import { authHeader } from "../../../services/auth";
import {
  ASSET_ACCOUNT_TYPE,
  ASSET_TYPE,
  ASSET_CREDIT_PURCHASE_TYPE,
} from "../../../constants/constants";
@Component({ components: { Multiselect } })
export default class Assets extends Vue {
  /* eslint-disable */
  public assets = [new Asset()];
  public otherAssets = [new OtherAssets()];
  public otherCredits = [new OtherCredits()];
  public assetsData: any = [new Asset()];
  public otherAssetsData: any = [new OtherAssets()];
  public otherCreditsData: any = [new OtherCredits()];
  public loanId = this.$route.query.id;
  public accountTypes = ASSET_ACCOUNT_TYPE;
  public assetTypes = ASSET_TYPE;
  public borrowers = [];
  public creditTypes = ASSET_CREDIT_PURCHASE_TYPE;
  public doesNotApplyForOtherAsset: any = null;
  public doesNotApplyForAssets: any = null;
  public doesNotApplyForOtherCredit: any = null;
  public doesNotApplyForOtherAssetData: any = null;
  public doesNotApplyForAssetsData: any = null;
  public doesNotApplyForOtherCreditData: any = null;
  public total: any = {};
  public count = 0;
  public changedValues: any = [];
  @Prop()
  public validationData: any;
  @Prop()
  public isLoanInactive: any;

  public hasAnychangedValues() {
    return this.changedValues;
  }

  public async getAssetData() {
    try {
      this.changedValues = [];
      this.callLoader(true, null);
      let response = await Axios.post(
        BASE_API_URL + "urla/getAssetData",
        {
          loanId: this.loanId,
        },
        {
          headers: authHeader(),
        }
      );
      this.count = 0;
      this.assets =  response.data.assets && response.data.assets.length > 0
          ? response.data.assets : [new Asset()];
      this.otherAssets =
        response.data.otherAssets && response.data.otherAssets.length > 0
          ? response.data.otherAssets
          : [new OtherAssets()];
      console.log(this.otherAssets, " this.otherAssets ");
      this.otherCredits =
        response.data.otherCredits && response.data.otherCredits.length > 0
          ? response.data.otherCredits
          : [new OtherCredits()];
      this.borrowers = response.data.borrowers;

      this.doesNotApplyForOtherAsset = response.data.doesNotApplyForOtherAssets;
      this.doesNotApplyForAssets = response.data.doesNotApplyForAssets;
      this.doesNotApplyForOtherCredit =
        response.data.doesNotApplyForOtherCredit;
      this.assetsData = JSON.parse(JSON.stringify(this.assets));
      this.otherAssetsData = JSON.parse(JSON.stringify(this.otherAssets));
      this.otherCreditsData = JSON.parse(JSON.stringify(this.otherCredits));
      this.doesNotApplyForOtherAssetData = JSON.parse(
        JSON.stringify(this.doesNotApplyForOtherAsset)
      );
      this.doesNotApplyForAssetsData = JSON.parse(
        JSON.stringify(this.doesNotApplyForAssets));
      this.doesNotApplyForOtherCreditData = JSON.parse(
        JSON.stringify(this.doesNotApplyForOtherCredit)
      );
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public addBorrowers(borrowerId, i) {
    this.assets[i].borrowerId.push({ id: borrowerId });
  }

  public async addAssets(isAdd, i) {
    isAdd ? this.assets.push(new Asset()) : this.assets.splice(i, 1);
    if (!isAdd) {
       if (i == 0 && this.assets.length == 0)  {
        this.doesNotApplyForAssets = true;
         this.assets = [new Asset()];
      }
            this.calculateAssets();

    }
  }

  public addOtherAssets(isAdd, i) {
    isAdd
      ? this.otherAssets.push(new OtherAssets())
      : this.otherAssets.splice(i, 1);
    if (!isAdd) {
        if (i == 0 && this.otherAssets.length == 0)  {
        this.doesNotApplyForOtherAsset = true;
        this.otherAssets = [new OtherAssets()];
      }
      this.calculateOtherAssets();
    }
  }

  public addOtherCredits(isAdd, i) {
    isAdd
      ? this.otherCredits.push(new OtherCredits())
      : this.otherCredits.splice(i, 1);
    if (!isAdd) {
        if (i == 0 && this.otherCredits.length == 0)  {
        this.doesNotApplyForOtherCredit = true;
         this.otherCredits = [new OtherCredits()];
      }
      this.calculateOtherCredits();
    }
  }

  public async saveAndUpdateAssets() {
    try {
      this.assets.forEach((a) => {
        a.borrowerId.map((b: any) => {
          b.id = b.borrowerId;
        });
      });
      this.otherAssets.forEach((o) => {
        o.borrowerId.map((b: any) => {
          b.id = b.borrowerId;
        });
      });
      let response = await Axios.post(
        BASE_API_URL + "urla/saveAndUpdateAssets",
        {
          loanId: this.loanId,
          assets: this.assets,
          otherAssets: this.otherAssets,
          otherCredits: this.otherCredits,
          doesNotApplyForOtherAsset: this.doesNotApplyForOtherAsset,
          doesNotApplyForAssets: this.doesNotApplyForAssets,
          doesNotApplyForOtherCredit: this.doesNotApplyForOtherCredit,
          changedValues: this.changedValues,
        },
        {
          headers: authHeader(),
        }
      );
      if ((response.status = 200) && this.count > 0) {
        this.getAssetData();
        this.$snotify.success("Assets Information has been Updated");
      }
      this.count = 0;
      this.$emit("referesh-tab", true);
    } catch (error) {
      console.log(error);
    }
  }

  public async handleChange(currentVal, previousVal, fieldName) {
    if (previousVal !== undefined) {
      const index = this.changedValues.findIndex((c) => c.name === fieldName);
      if (index !== -1) {
        if (JSON.stringify(currentVal) === JSON.stringify(previousVal)) {
          this.changedValues.splice(index, 1);
        } else {
          let data = this.changedValues.find((v) => v.name === fieldName);
          data.from = previousVal;
          data.to = currentVal;
        }
      } else {
        this.changedValues.push({
          name: fieldName,
          from: previousVal,
          to: currentVal,
        });
      }
    }
  }

  public calculateOtherCredits() {
    this.total.totalOtherCredits = 0;
    this.otherCredits.forEach((oc) => {
      this.total.totalOtherCredits = this.total.totalOtherCredits + oc.cash;
    });
    this.total = JSON.parse(JSON.stringify(this.total));
  }

  public calculateOtherAssets() {
    this.total.totalOtherAssets = 0;
    this.otherAssets.forEach((oa) => {
      this.total.totalOtherAssets = this.total.totalOtherAssets + oa.cash;
    });
    this.total = JSON.parse(JSON.stringify(this.total));
  }

  public calculateAssets() {
    this.total.totalAssets = 0;
    this.assets.forEach((a) => {
      this.total.totalAssets = this.total.totalAssets + a.cash;
    });
    this.total = JSON.parse(JSON.stringify(this.total));
  }

  public cashValue(total) {
    const totals = total.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return totals;
  }

  public initializeOtherValue(asset) {
    if (asset && asset.assetType && asset.assetType.name != "Other") {
      asset.assetType.description = null;
    }
  }

  public initializeOtherCreditValue(otherCredit) {
    if (
      otherCredit.creditType.name &&
      otherCredit.creditType &&
      otherCredit.creditType.name != "Other"
    ) {
      otherCredit.creditType.description = null;
    }
  }

  public initializeOtherAssetData() {
   // this.otherAssets = [new OtherAssets()];
  }

public initializeAssetData() {
    //this.assets = [new Asset()];
  }
  public initializeOtherCreditData() {
   // this.otherCredits = [new OtherCredits()];
  }

  public checkValidationForMobile() {
    EventBus.$emit("check-validation", true);
  }

  public async mounted() {
    await this.getAssetData();
    await this.calculateAssets();
    await this.calculateOtherAssets();
    await this.calculateOtherCredits();
    console.log(this.validationData, "dd");
    this.validationData ? this.$emit("call-mount", true) : "";
    console.log(this.validationData, "dd");
  }
}
