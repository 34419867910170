
import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../../../config";
import { authHeader } from "../../../services/auth";
// import CommonNotifyOnRejection from "../../../common-components/CommonNotifyOnRejection.vue";
import ViewDocument from "../../../common-components/ViewDocument.vue";
@Component({
  components: {
    ViewDocument,
    // CommonNotifyOnRejection,
  },
})
export default class CDReceived extends Vue {
  /* eslint-disable */
  public cdReceived: any = {};
  @Prop()
  public taskId: any;
  public loanId: any = this.$route.query.id;
  public error: any = {
    show: false,
    msg: null,
  };
  /**
   * Taking dsData variable for checking form data is changed or not only for comaprison
   */
  public dsData: any = {};
  /**************************************************************** */

  public doc: any = {};
  public imageLoader = false;
  @Prop()
  public isLoanInactive: any;
  //public notifyBroker = false;
  public isTaskCompleted = false;
  @Prop()
  public isTaskDisabled: any;
  public isOrderPlacedOnce = false

  public async uploadReport(event) {
    this.imageLoader = true;
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        return;
      }
      let files = event.target.files;
      let formData: any = new FormData();
      formData.append("loanId", this.loanId);
      formData.append("taskId", this.taskId);
      Array.from(files).forEach((f) => {
        formData.append("file", f);
      });
      let response = await Axios.post(
        BASE_API_URL + "processor/uploadCD",
        formData,
        { headers: authHeader() }
      );
      if (response.status === 201) this.$snotify.success(response.data.message);
      this.doc = response.data.doc;
    } catch (error) {
      console.log(error);
    }
    this.imageLoader = false;
  }

  public async getCDReceived() {
    this.callLoader(true, 1);
    try {
      let response = await Axios.get(
        BASE_API_URL +
          "processor/getCDReceived/" +
          this.loanId +
          "/" +
          this.taskId,
        { headers: authHeader() }
      );

      if (response.status === 201) {
        // this.cdReceived = response.data.cdReceived;
        this.doc = response.data.doc;
        this.isTaskCompleted = response.data.isCompleted;
        this.cdReceived = JSON.parse(JSON.stringify(response.data.cdReceived));
        this.dsData = JSON.parse(JSON.stringify(response.data.cdReceived));
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async checkSaveValidation() {
    try {
      if (
        !this.cdReceived.isCdReceived &&
        this.cdReceived.isCdReceived == this.dsData.isCdReceived
      )
        return true;
      else return false;
    } catch (error) {
      console.log(error);
    }
  }

  public async saveCDReceived(isComplete) {
        if(this.isOrderPlacedOnce) return

    this.callLoader(true, 2);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getCDReceived()
        return;
      }
      if (!isComplete) {
        let notValid1 = await this.checkSaveValidation();
        if (notValid1) {
          this.callLoader(false, null);
          this.$snotify.info("No Changes");
          return;
        }
      }
      if (isComplete && !this.cdReceived.isCdReceived) {
        this.error.show = true;
        this.error.msg = "All fields are Mandatory.";
        this.callLoader(false, null);
        return;
      }
      if (isComplete && !this.doc) {
        this.error.show = true;
        this.error.msg = "Upload CD Report.";
        this.callLoader(false, null);
        return;
      }

      if(isComplete) this.isOrderPlacedOnce = true;

      let response = await Axios.post(
        BASE_API_URL + "processor/saveCDReceived",
        {
          loanId: this.loanId,
          taskId: this.taskId,
          cdReceived: this.cdReceived,
          isComplete: isComplete,
          formData: this.dsData,
        },
        { headers: authHeader() }
      );
      if (!isComplete) {
        this.$snotify.success("CD Received Information has been saved");
      }

      if (response.status === 201 && isComplete)
        this.$snotify.success("CD Received Completed successfully.");
      if (isComplete) this.$emit("refresh-task-list", true);
      await this.getCDReceived();
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public async viewDocument(document) {
    try {
      document.loanId = this.$route.query.id;
      this.$refs.ref["showFile"](document);
    } catch (error) {
      console.log(error);
    }
  }

  public mounted() {
    this.getCDReceived();
  }
}
