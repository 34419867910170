
import { Component, Vue, Prop } from "vue-property-decorator";
import Address from "../urlaAddress.vue";
import datepicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { OTHER_INCOME_SOURCE } from "../../../constants/constants";
import Axios from "axios";
import { authHeader } from "../../../services/auth";
import { BASE_API_URL, EventBus } from "../../../config";
import {
  Employment,
  OtherIncome,
  EmploymentInfo,
  Income,
  WorkingPeriod,
} from "../../../models/urla/employment.model";
@Component({
  components: {
    Address,
    datepicker,
  },
})
export default class EmploymentAndIncome extends Vue {
  /* eslint-disable */
  public otherIncome: any = new OtherIncome();
  public other_Income = OTHER_INCOME_SOURCE;
  public employmentData = new Employment();
  public employmentInfo = new EmploymentInfo();
  public employmentDataCheck = new Employment();
  public income = new Income();
  public workingPeriod = new WorkingPeriod();
  public borrowerInfo: any = [];
  public currEmpYear: any = null;
  public count = 0;
  public changedValues: any = [];
  public loanType = null;
  public isPreviousDosNot = false;
  public isCurrerntDosNot = false;
  public isClosePre = false;
  public isCloseCurrent = false;

  @Prop()
  public validationData: any;
  @Prop()
  public isLoanInactive: any;

  public addInputField(identifier, type, index, childIndex) {
    if (identifier === "empAndIncome") {
      if (type === "add") {
        this.employmentInfo.workingPeriod = new WorkingPeriod();
        this.employmentInfo.income = new Income();
        console.log(this.employmentInfo, "this.employmentInfo");
        const currentEmployment = { ...this.employmentInfo };

        currentEmployment.address = {
          street: null,
          unit: null,
          city: null,
          zip: null,
          state: null,
          country: "US",
          unitType: null,
          numberOfUnits: 0,
        };
        //  currentEmployment.income = {
        //     base : 0,
        // overTime : 0,
        // bonus : 0,
        // commissions : 0,
        // militaryEntitlements : 0,
        // other : 0

        //  }addnewInputField

        currentEmployment["isCurrentEmployment"] = true;

        this.employmentData.borrowerInfo[index].employments.push(
          currentEmployment
        );
      } else if (type === "del") {
        console.log(childIndex, "ff");
        this.employmentData.borrowerInfo[index].employments.splice(
          childIndex,
          1
        );
        const data = this.employmentData.borrowerInfo[index].employments.filter(
          (e) => e.isCurrentEmployment
        );
        if (data.length == 0) {
          this.employmentData.borrowerInfo[index][
            "doesNotApplyForCurrentEmployment"
          ] = true;

          this.employmentInfo.workingPeriod = new WorkingPeriod();
          this.employmentInfo.income = new Income();
          const currentEmployment = { ...this.employmentInfo };
          currentEmployment.address = {
            street: null,
            unit: 0,
            city: null,
            zip: null,
            state: null,
            country: "US",
            unitType: null,
            numberOfUnits: 0,
          };
          // console.log(currentEmployment.address)
          currentEmployment["isCurrentEmployment"] = true;
          currentEmployment["isPrimaryEmployment"] = true;

          const checkCurrentEmplData = this.employmentData.borrowerInfo[
            index
          ].employments.filter((e) => e.isCurrentEmployment);
        }
      }
    } else if (identifier === "prevEmp") {
      if (type === "add") {
        this.employmentInfo = new EmploymentInfo();
        this.employmentInfo.address.country = "US";
        // this.employmentInfo.address = {
        //   street: null,
        //    unit: 0,
        //    city: null,
        //      zip: null,
        //      state: null,
        //     country: 'US',
        //       unitType: null,
        //    numberOfUnits: 0
        // }
        this.employmentData.borrowerInfo[index].employments.push(
          this.employmentInfo
        );
      } else if (type === "del") {
        this.employmentData.borrowerInfo[index].employments.splice(
          childIndex,
          1
        );

        const data = this.employmentData.borrowerInfo[index].employments.filter(
          (e) => !e.isCurrentEmployment
        );
        if (data.length == 0) {
          this.employmentData.borrowerInfo[index][
            "doesNotApplyForPreviousEmployment"
          ] = true;
        }
      }
    } else if (identifier === "otherIncome") {
      if (type === "add") {
        this.employmentData.borrowerInfo[index].otherIncome.push(
          new OtherIncome()
        );
      } else if (type === "del") {
        this.employmentData.borrowerInfo[index].otherIncome.splice(
          childIndex,
          1
        );

        if (this.employmentData.borrowerInfo[index].otherIncome.length == 0) {
          this.employmentData.borrowerInfo[index][
            "doesNotApplyForOtherIncome"
          ] = true;
          this.employmentData.borrowerInfo[index].otherIncome = [
            new OtherIncome(),
          ];
        }
      }
    }
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public initializePreviousEmployment(borrowerInfo, borrIndex) {
    this.callLoader(true, null);
    try {
      this.isPreviousDosNot = borrowerInfo.doesNotApplyForPreviousEmployment
        ? true
        : false;
      let data = borrowerInfo.employments.filter((e) => !e.isCurrentEmployment);
      this.isClosePre = data.length > 0 ? false : true;
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public initializeCurrentEmployment(borrowerInfo, borrIndex) {
    this.callLoader(true, null);
    try {
      this.isCurrerntDosNot = borrowerInfo.doesNotApplyForCurrentEmployment
        ? true
        : false;

      let data = borrowerInfo.employments.filter((e) => e.isCurrentEmployment);

      this.isCloseCurrent = data.length > 0 ? false : true;
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async getEmploymentInfo() {
    this.callLoader(true, null);
    try {
      this.changedValues = [];
      const response: any = await Axios.post(
        BASE_API_URL + "urla/getEmploymentInfo",
        {
          loanId: this.$route.query.id,
        },
        {
          headers: authHeader(),
        }
      );
      if (response.status == 201) {
        this.employmentData = response.data.employmentData;
        await this.assignData(this.employmentData);
        this.loanType = response.data.loanType.type;
        this.borrowerInfo = response.data.borrowerInfo;
        this.employmentData.borrowerInfo.forEach((e) => {
          if (e.otherIncome.length == 0) {
            e.otherIncome.push(new OtherIncome());
          }
        });
        this.employmentDataCheck = JSON.parse(
          JSON.stringify(this.employmentData)
        );
        //  this.isClosePre = this.borrowerInfo.doesNotApplyForPreviousEmployment
        // ? false
        // : true;
        //  this.isCloseCurrent = this.borrowerInfo.doesNotApplyForCurrentEmployment
        // ? false
        // : true;
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async assignData(data) {
    this.callLoader(true, null);
    try {
      data.borrowerInfo.forEach((e, i) => {
        e.employments.forEach((j) => {
          if (j.isCurrentEmployment && !j.income) {
            j["income"] = this.income;
          }
          if (!j.workingPeriod) j["workingPeriod"] = this.workingPeriod;
          if (j.isCurrentEmployment && j.income && j.income.base)
            j["monthlyIncome"] = j.income.base;
        });
      });
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public initializeBorrowerData(data) {
    data.borrowerInfo.forEach((e, i) => {
      if (e.doesNotApplyForCurrentEmployment) {
        this.employmentInfo.workingPeriod = new WorkingPeriod();
        this.employmentInfo.income = new Income();
        const currentEmployment = { ...this.employmentInfo };
        currentEmployment.address = {
          street: null,
          unit: 0,
          city: null,
          zip: null,
          state: null,
          country: "US",
          unitType: null,
          numberOfUnits: 0,
        };
        // console.log(currentEmployment.address)
        currentEmployment["isCurrentEmployment"] = true;
        currentEmployment["isPrimaryEmployment"] = true;

        const checkCurrentEmplData = e.employments.filter(
          (e) => e.isCurrentEmployment
        );
        if (
          !e.doesNotApplyForCurrentEmployment &&
          checkCurrentEmplData.length == 0
        ) {
          this.employmentData.borrowerInfo[i].employments.push(
            currentEmployment
          );
        } else {
          const data = e.employments.filter((e) => !e.isCurrentEmployment);
          this.employmentData.borrowerInfo[i].employments = data;
        }
      }
      if (e.doesNotApplyForPreviousEmployment) {
        this.employmentInfo.workingPeriod = new WorkingPeriod();
        this.employmentInfo.income = new Income();
        const previousEmployment = { ...this.employmentInfo };
        previousEmployment.address = {
          street: null,
          unit: null,
          city: null,
          zip: null,
          state: null,
          country: "US",
          unitType: null,
          numberOfUnits: 0,
        };
        const checkPreviousEmplData = e.employments.filter(
          (e) => !e.isCurrentEmployment
        );
        if (
          !e.doesNotApplyForPreviousEmployment &&
          checkPreviousEmplData.length == 0
        ) {
          this.employmentData.borrowerInfo[i].employments.push(
            previousEmployment
          );
        } else {
          const data = e.employments.filter((e) => e.isCurrentEmployment);
          this.employmentData.borrowerInfo[i].employments = data;
        }
      }
      if (e.doesNotApplyForOtherIncome) {
        this.employmentData.borrowerInfo[i].otherIncome = [new OtherIncome()];
      }
    });
  }

  public async saveEmploymentInfo() {
    this.callLoader(true, null);
    try {
      console.log("employmentData", this.employmentData);

      this.initializeBorrowerData(this.employmentData);
      const response: any = await Axios.post(
        BASE_API_URL + "urla/saveEmploymentInfo",
        {
          loanId: this.$route.query.id,
          employmentData: this.employmentData,
          changedValues: this.changedValues,
        },
        {
          headers: authHeader(),
        }
      );
      if (response.status == 201 && this.count > 0)
        this.$snotify.success(response.data);
      this.count = 0;
      await this.getEmploymentInfo();
      this.$emit("referesh-tab", true);
      EventBus.$emit("refesh-loaninfo");
      this.validationData ? this.refreshBorrowerTabs() : "";
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async handleChange(currentVal, previousVal, fieldName) {
    if (previousVal !== undefined) {
      const index = this.changedValues.findIndex((c) => c.name === fieldName);
      if (index !== -1) {
        if (currentVal === previousVal) {
          this.changedValues.splice(index, 1);
        } else {
          let data = this.changedValues.find((v) => v.name === fieldName);
          data.from = previousVal;
          data.to = currentVal;
        }
      } else {
        this.changedValues.push({
          name: fieldName,
          from: previousVal,
          to: currentVal,
        });
      }
    }
  }

  public async refreshBorrowerTabs() {
    this.callLoader(true, null);
    try {
      const response = await Axios.post(
        BASE_API_URL + "urla/refreshBorrowerTabs",
        {
          loanId: this.$route.query.id,
          identifier: "Employment",
        },
        {
          headers: authHeader(),
        }
      );
      const borIndex = response.data;
      this.employmentData.borrowerInfo.forEach((b, i) => {
        const hasExist =
          borIndex.length > 0 ? borIndex.find((index) => index == i) : null;
        Vue.set(b, "showValidation", hasExist != null ? true : false);
      });
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  showIsPrimary(index) {
    try {
      const currentEmployment = this.employmentData.borrowerInfo[
        index
      ].employments.filter((a) => a.isCurrentEmployment);
      return currentEmployment.length > 1 ? true : false;
    } catch (error) {
      console.log(error);
    }
  }

  calculateIncome(borrowerIndex, currentEmpIndex) {
    this.callLoader(true, null);
    try {
      let total = 0;
      const data =
        this.employmentData.borrowerInfo[borrowerIndex].employments[
          currentEmpIndex
        ].income;
      Object.keys(data).forEach((key) => {
        if (data[key]) total = total + Number(data[key]);
      });
      const totals = total.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      this.callLoader(false, null);
      return totals;
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public initializeValue(currentEmp) {
    currentEmp.ownershipPercentage = null;
    currentEmp.monthlyIncome = null;
  }

  public employmentIncome(borrowerIndex) {
    try {
      let total = 0;
      const data = this.employmentData.borrowerInfo[borrowerIndex].employments;
      data.forEach((e) => {
        Object.keys(e.income).forEach((key) => {
          total = total + Number(e.income[key]);
        });
      });
      return total;
    } catch (error) {
      console.log(error);
    }
  }

  public calculateOtherIncome(i) {
    let total = 0;
    this.employmentData.borrowerInfo[i].otherIncome.forEach((element) => {
      total = total + Number(element.monthlyIncome);
    });

    const totals = total.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return totals;
  }

  totalIncome(borrowerIndex) {
    let total = 0;
    total =
      Number(this.employmentIncome(borrowerIndex)) +
      Number(this.calculateOtherIncome(borrowerIndex));

    const totalStr = total.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return totalStr;
  }

  public checkIncomeSrcVal(otherIncome) {
    //  if (this.validationData) {
    if (!otherIncome.incomeSource && otherIncome.monthlyIncome != 0) {
      return true;
    } else {
      return false;
    }

    // }
  }

  public checkMonthlyIncomeVal(otherIncome) {
    //  if (this.validationData) {
    if (otherIncome.incomeSource && otherIncome.monthlyIncome == 0) {
      return true;
    } else {
      return false;
    }
  }

  public addPreviousInputField(borrowerInfo, borrIndex) {
    this.callLoader(true, null);
    try {
      this.employmentInfo.workingPeriod = new WorkingPeriod();
      this.employmentInfo.income = new Income();
      const previousEmployment = { ...this.employmentInfo };
      previousEmployment.address = {
        street: null,
        unit: null,
        city: null,
        zip: null,
        state: null,
        country: "US",
        unitType: null,
        numberOfUnits: 0,
      };
      const checkPreviousEmplData = borrowerInfo.employments.filter(
        (e) => !e.isCurrentEmployment
      );
      if (
        !borrowerInfo.doesNotApplyForPreviousEmployment &&
        checkPreviousEmplData.length == 0
      ) {
        this.employmentData.borrowerInfo[borrIndex].employments.push(
          previousEmployment
        );
      } else {
        const data = borrowerInfo.employments.filter(
          (e) => e.isCurrentEmployment
        );
        this.employmentData.borrowerInfo[borrIndex].employments = data;
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public addCurrentInputField(borrowerInfo, borrIndex) {
    this.callLoader(true, null);
    try {
      this.employmentInfo.workingPeriod = new WorkingPeriod();
      this.employmentInfo.income = new Income();
      const currentEmployment = { ...this.employmentInfo };
      currentEmployment.address = {
        street: null,
        unit: 0,
        city: null,
        zip: null,
        state: null,
        country: "US",
        unitType: null,
        numberOfUnits: 0,
      };
      // console.log(currentEmployment.address)
      currentEmployment["isCurrentEmployment"] = true;
      currentEmployment["isPrimaryEmployment"] = true;

      const checkCurrentEmplData = borrowerInfo.employments.filter(
        (e) => e.isCurrentEmployment
      );
      if (
        !borrowerInfo.doesNotApplyForCurrentEmployment &&
        checkCurrentEmplData.length == 0
      ) {
        this.employmentData.borrowerInfo[borrIndex].employments.push(
          currentEmployment
        );
      } else {
        const data = borrowerInfo.employments.filter(
          (e) => !e.isCurrentEmployment
        );
        this.employmentData.borrowerInfo[borrIndex].employments = data;
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public calculateTotalIncome(i) {
    let totalInc = 0;
    let totalPrevInc = 0;
    let otherIncomeData = 0;
    this.employmentData.borrowerInfo[i].otherIncome.forEach((element) => {
      otherIncomeData = otherIncomeData + Number(element.monthlyIncome);
    });
    const currentEmpData = this.employmentData.borrowerInfo[
      i
    ].employments.filter((e) => e.isCurrentEmployment);
    if (currentEmpData.length > 0) {
      currentEmpData.forEach((e) => {
        Object.keys(e.income).forEach((key) => {
          totalInc = totalInc + Number(e.income[key]);
        });
      });
    }

    const prevEmpData = this.employmentData.borrowerInfo[i].employments.filter(
      (b) => !b.isCurrentEmployment
    );

    let totals = 0;
    totals = totalInc + otherIncomeData;

    const totalVal = totals.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return totalVal;
  }

  public async checkIsPrimaryEmployment(borrowerIndex, empIndex) {
    try {
      this.employmentData.borrowerInfo[borrowerIndex].employments.forEach(
        (e: any, i) => {
          if (e.isCurrentEmployment && empIndex === i) {
            this.employmentData.borrowerInfo[borrowerIndex].employments[
              i
            ].isPrimaryEmployment = true;
          }
        }
      );
      this.employmentData.borrowerInfo[borrowerIndex].employments.forEach(
        (e: any, i) => {
          if (e.isCurrentEmployment && empIndex !== i) {
            this.employmentData.borrowerInfo[borrowerIndex].employments[
              i
            ].isPrimaryEmployment = false;
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  public returnName(id) {
    const bor: any = this.borrowerInfo.find((b: any) => b._id == id);
    return bor ? bor.name.firstName + " " + bor.name.lastName : "";
  }

  public overrideBaseAmount() {
    this.callLoader(true, null);
    this.employmentData.borrowerInfo.forEach((b: any) => {
      if (b.employments.length > 0) {
        b.employments.forEach((e) => {
          if (e.hasSelfEmployed) {
            Vue.set(e, "monthlyIncome", e.income.base);
          }
        });
      }
    });
    this.callLoader(false, null);
  }

  public checkValidation() {
    this.callLoader(true, null);
    try {
      this.employmentData.borrowerInfo.forEach((d, i) => {
        const hasExist =
          this.validationData.borIndex.length > 0
            ? this.validationData.borIndex.find((index) => index == i)
            : null;
        Vue.set(d, "showValidation", hasExist != null ? true : false);
      });
      this.$emit("call-mount", true);
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public initializeOtherIncome(index, doesNotApply) {
    if (doesNotApply) {
      this.employmentData.borrowerInfo[index].otherIncome =
        this.employmentData.borrowerInfo[index].otherIncome;
    }
  }

  public headingCurrentEmp(index, currentEmpIndex) {
    const firstMostIndex = this.employmentData.borrowerInfo[
      index
    ].employments.findIndex((e) => e.isCurrentEmployment);
    if (currentEmpIndex != firstMostIndex)
      return "Current Employment/Self Employment and Income";
  }

  public headingPreviousEmp(index, prevEmpIndex) {
    const firstMostIndex = this.employmentData.borrowerInfo[
      index
    ].employments.findIndex((e) => !e.isCurrentEmployment);
    if (prevEmpIndex != firstMostIndex) return "Previous Employment";
  }

  public checkValidationForMobile() {
    EventBus.$emit("check-validation", true);
  }

  public hasAnychangedValues() {
    return this.changedValues;
  }

  async mounted() {
    await this.getEmploymentInfo();
    await this.overrideBaseAmount();

    this.validationData ? await this.checkValidation() : "";
  }
  /* eslint-disable */
}
