
import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../../../config";
import { authHeader } from "../../../services/auth";
import { ApplicationReviewChecklist } from "../../../models/workflow/applicationReview.model";
import ViewDocument from "../../../common-components/ViewDocument.vue";
@Component({
  components: {
    ViewDocument,
  },
})
export default class ApplicationReview extends Vue {
  /* eslint-disable */

  public creditReporData: any = {};
  public options: any = {};
  public remarks: any = "";
  public status: number = 0;
  public statusDetail: any = {};
  public error: any = false;
  public applicationReview: any = new ApplicationReviewChecklist();
  public applicationReviewData: any = new ApplicationReviewChecklist();
  public data: any = {};
  public showAus: any = false;
  public noChange = false;
  public changedValues: any = [];
  public isCanceled = false;
  public isRequired = false;
  public errors: any = {
    show: false,
    msg: null,
  };

  @Prop()
  public taskId: any;
  @Prop()
  public isLoanInactive: any;
  public isTaskCompleted = false;
  public isComplete = false;
  @Prop()
  public isTaskDisabled: any;
  public isOrderPlacedOnce = false;

  public async checkValidation() {
    try {
      if (
        !this.applicationReview.isReviewCreditPackage ||
        (this.showAus &&
          (!this.applicationReview.runAus.isRunAus ||
            !this.applicationReview.runAus.status))
      )
        return true;
      else false;
    } catch (error) {
      console.log(error);
    }
  }

  // public holdCompleteCondition() {
  //   if (
  //     this.applicationReview.isForeclosureDeedInLieuShortSale.ismatterResolved
  //       .mode == "Yes" &&
  //     this.applicationReview.isMortgageLatesReflected.ismatterResolved.mode ==
  //       "Yes" &&
  //     this.applicationReview.isBankruptcyReflected.ismatterResolved.mode ==
  //       "Yes" &&
  //     this.applicationReview.isDisputedaccounts.ismatterResolved.mode == "Yes"
  //   )
  //     return true;
  //   else return false;
  // }

  public holdCondition() {
    if (
      this.applicationReview.isForeclosureDeedInLieuShortSale.ismatterResolved
        .mode == "No" ||
      this.applicationReview.isMortgageLatesReflected.ismatterResolved.mode ==
        "No" ||
      this.applicationReview.isBankruptcyReflected.ismatterResolved.mode ==
        "No" ||
      this.applicationReview.isDisputedaccounts.ismatterResolved.mode == "No" 
    )
      return true;
    else return false;
  }

  public unSetHoldConditions() {
    try {
      this.applicationReview.isForeclosureDeedInLieuShortSale.ismatterResolved
        .mode == "No"
        ? (this.applicationReview.isForeclosureDeedInLieuShortSale.ismatterResolved.mode =
            "Yes")
        : null;
      this.applicationReview.isMortgageLatesReflected.ismatterResolved.mode ==
      "No"
        ? (this.applicationReview.isMortgageLatesReflected.ismatterResolved.mode =
            "Yes")
        : null;
      this.applicationReview.isBankruptcyReflected.ismatterResolved.mode == "No"
        ? (this.applicationReview.isBankruptcyReflected.ismatterResolved.mode =
            "Yes")
        : null;
      this.applicationReview.isDisputedaccounts.ismatterResolved.mode == "No"
        ? (this.applicationReview.isDisputedaccounts.ismatterResolved.mode =
            "Yes")
        : null;
    } catch (error) {
      console.log(error);
    }
  }


   public checkQuestionValidation(data) 
   {
     this.isRequired = true;
     this.errors.msg = `{{data}} This field is required `;
  }

  public async saveApplicationReviewInfo() {
    if(this.isOrderPlacedOnce) return
    this.callLoader(true, 2);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        await this.getApplicationReview();
        return;
      }
      if (
        this.isComplete && 
        this.holdCondition()
        // this.statusDetail.statusDetails && this.statusDetail.statusDetails.status == 1
      ) {
        this.errors.show = true;
        this.errors.msg = `Loan has been put on Hold `;
        this.callLoader(false, null);
        return;
      }
      if (
        this.isComplete &&
        !this.isCanceled &&
        this.applicationReview.isBorrowersnameSocialDOBReflected.mode == "No"
      ) {
        this.errors.show = true;
        this.errors.msg = `Please select yes for the Question No. 1 `;
        this.callLoader(false, null);
        return;
      }
      if (
        this.isComplete &&
        !this.isCanceled &&
        this.applicationReview.isSubjectPropertyAddress.mode == "No"
      ) {
        this.errors.show = true;
        this.errors.msg = `Please select yes for the Question No. 2 `;
        this.callLoader(false, null);
        return;
      }
      if (
        this.isComplete &&
        !this.isCanceled &&
        this.applicationReview.isSalesPriceLoanmount.mode == "No"
      ) {
        this.errors.show = true;
        this.errors.msg = `Please select yes for the Question No. 3 `;
        this.callLoader(false, null);
        return;
      }
      if (
        this.isComplete &&
        !this.isCanceled &&
        this.applicationReview.isClosingDateReflected.mode == "No"
      ) {
        this.errors.show = true;
        this.errors.msg = `Please select yes for the Question No. 4 `;
        this.callLoader(false, null);
        return;
      }
      if (
        this.isComplete &&
        !this.isCanceled &&
        this.applicationReview.isVerifiedIncomeAssetsLiabilities.mode == "No"
      ) {
        this.errors.show = true;
        this.errors.msg = `Please select yes for the Question No. 5 `;
        this.callLoader(false, null);
        return;
      }
      if (
        this.isComplete &&
        !this.isCanceled &&
        this.applicationReview.isCumulativeBalance.mode == "No"
      ) {
        this.errors.show = true;
        this.errors.msg = `Please select yes for the Question No. 11 `;
        this.callLoader(false, null);
        return;
      }
      if (
        this.isComplete &&
        !this.isCanceled &&
        this.applicationReview.isLeasePayments.mode == "No"
      ) {
        this.errors.show = true;
        this.errors.msg = `Please select yes for the Question No. 12 `;
        this.callLoader(false, null);
        return;
      }
      if (
        this.isComplete &&
        !this.isCanceled &&
        this.applicationReview.isAuthorizedUserAccounts.mode == "No"
      ) {
        this.errors.show = true;
        this.errors.msg = `Please select yes for the Question No. 13 `;
        this.callLoader(false, null);
        return;
      }
      if (
        this.isComplete &&
        !this.isCanceled &&
        this.applicationReview.isEmployersNamePaystubsMatches.mode == "No"
      ) {
        this.errors.show = true;
        this.errors.msg = `Please select yes for the Question No. 14 `;
        this.callLoader(false, null);
        return;
      }
      if (
        this.isComplete &&
        !this.isCanceled &&
        this.applicationReview.isEscrowDepositSellerContribution.mode == "No"
      ) {
        this.errors.show = true;
        this.errors.msg = `Please select yes for the Question No. 16 `;
        this.callLoader(false, null);
        return;
      }
      if (
        this.isComplete &&
        !this.isCanceled &&
        this.applicationReview.isLargeDepositsNSFFees.mode == "No"
      ) {
        this.errors.show = true;
        this.errors.msg = `Please select yes for the Question No. 17 `;
        this.callLoader(false, null);
        return;
      }
      if (
        this.isComplete &&
        !this.isCanceled &&
        this.applicationReview.isAUSRun.mode == "No"
      ) {
        this.errors.show = true;
        this.errors.msg = `Please select yes for the Question No. 19 `;
        this.callLoader(false, null);
        return;
      }

      if (this.noChange) {
        this.$modal.hide("status-modal");
        this.noChange = false;
        await this.getApplicationReview();
        return;
      }

      let notValid = await this.checkValidation();
      if (this.isComplete && !this.isCanceled) {
        if (notValid) {
          this.error = true;
          this.callLoader(false, null);
          return;
        }
      }

      if (
        this.isComplete &&
        !this.isCanceled &&
        this.applicationReview.isCreditReportValidUnexpired.mode == "No" &&
        !this.creditReporData.creditReportUploaded
      ) {
        this.errors.show = true;
        this.errors.msg = `Please Upload Credit Report`;
        this.callLoader(false, null);
        return;
      }
      if (
        this.isComplete &&
        !this.isCanceled &&
        this.applicationReview.isCreditReportValidUnexpired.mode == "No" &&
        !this.creditReporData.docApproved
      ) {
        this.errors.show = true;
        this.errors.msg = `Please Approve Credit Report`;
        this.callLoader(false, null);
        return;
      }
      if (
        this.isComplete &&
        !this.isCanceled &&
        this.applicationReview.isCreditReportValidUnexpired.mode == "No"
      ) {
        this.errors.show = true;
        this.errors.msg = `Credit Report is Approved Please Select Yes to Proceed.`;
        this.callLoader(false, null);
        return;
      }
      // console.log(this.applicationReview.hasLiabilitySectionIn1003,'this.applicationReview.hasLiabilitySectionIn1003');
      if (
        this.isComplete &&
        !this.isCanceled &&
        this.applicationReview.hasLiabilitySectionIn1003 &&
        this.applicationReview.hasLiabilitySectionIn1003.mode == "No"
      ) {
        this.errors.show = true;
        this.errors.msg = `Please select yes for "Has the liability section in 1003 been updated" to proceed`;
        this.callLoader(false, null);
        return;
      }
      if(this.isComplete)
      this.isOrderPlacedOnce = true
      let response = await Axios.post(
        BASE_API_URL + "processor/saveApplicationReviewInfo",
        {
          loanId: this.$route.query.id,
          taskId: this.taskId,
          applicationReview: this.applicationReview,
          isComplete: this.isComplete,
          creditReporData: this.creditReporData,
          changedValues: this.changedValues,
        },
        { headers: authHeader() }
      );

      if (response.status === 201 && this.isComplete)
        this.$snotify.success("Application Review Completed successfully.");
      this.$modal.hide("confirmationModal");
      this.errors.show = false;
      this.errors.msg = null;

      if (this.isComplete) this.$emit("refresh-task-list", true);

      if (this.holdCondition() && this.statusDetail.statusDetails.status != 1) {
        this.$snotify.success("Loan has been put on Hold");
        this.$emit("refresh-task-list", true);
        this.$modal.hide("status-modal");
      }

      await this.getApplicationReview();
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async handleChange(currentVal, previousVal, fieldName) {
    let index = this.changedValues.findIndex((c) => c.name === fieldName);
    if (index !== -1) {
      if (currentVal === previousVal) {
        this.changedValues.splice(index, 1);
      } else {
        let data = this.changedValues.find((v) => v.name === fieldName);
        data.from = previousVal;
        data.to = currentVal;
      }
    } else {
      this.changedValues.push({
        name: fieldName,
        from: previousVal,
        to: currentVal,
      });
    }
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public deSelectRadionOnChange() {
    this.applicationReview.runAus.status = null;
  }
 

  public async getApplicationReview() {
    this.callLoader(true, null);
    try {
      this.changedValues = [];
      const response = await Axios.get(
        BASE_API_URL +
          "processor/getApplicationReview/" +
          this.$route.query.id +
          "/" +
          this.taskId,
        { headers: authHeader() }
      );
      if (response.status === 201 && response.data) {
        this.applicationReview = response.data.applicationReview
          ? JSON.parse(JSON.stringify(response.data.applicationReview))
          : new ApplicationReviewChecklist();

        this.isTaskCompleted = JSON.parse(
          JSON.stringify(response.data.isCompleted)
        );
        this.creditReporData = response.data.creditReport
          ? response.data.creditReport
          : {};
        this.creditReporData.creditReportUploaded =
          response.data.creditReportUploaded;
        this.showAus = response.data.showAus;
        this.statusDetail = response.data.statusDetails;
        this.applicationReviewData = JSON.parse(
          JSON.stringify(this.applicationReview)
        );
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async viewDocument(document) {
    try {
      document.loanId = this.$route.query.id;
      this.$refs.ref["showFile"]({
        ...document,
      });
    } catch (error) {
      console.log(error);
    }
  }

  public openStatusModal() {
    this.$modal.show("status-modal");
  }

  public openLoanCancelModal() {
    
    this.$modal.show("confirmationModal");
  }

  public closeLoanCancelModal() {
    this.applicationReview.loanCancel.remark = null;
    this.applicationReview.loanCancel.isCancel = null;
    this.isCanceled = false;
    this.$modal.hide("confirmationModal");
    this.errors.show = false;
    this.errors.msg = "";
    this.error = false;
  }

  public async mounted() {
    await this.getApplicationReview();
    if (this.holdCondition()) {
      if (!this.isTaskCompleted && !this.isLoanInactive) {
        await this.unSetHoldConditions();
        await this.saveApplicationReviewInfo();
      }
    }
  }
}
