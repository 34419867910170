
import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "../../config";
import Axios from "axios";
import { authHeader } from "../../services/auth";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css"
@Component({
  components: {
    Multiselect,
  },
})
export default class ShowWorkFlow extends Vue {
    public workFlow: any = [];
    public processorList: any = [];
    public selectedWorkFlow: any = null;
    public borrowerName: any = null;

    public async getWorkFlow() {
    this.$store.state.tmpcoLoader = true;
    try {
        const response = await Axios.get(
        BASE_API_URL + "common/getWorkFlow/" + this.$route.query.id,
        { headers: authHeader(), });
        this.workFlow = response.data.workflow;
        const primaryBorrower = response.data.primaryBorrower;
        this.borrowerName = `${primaryBorrower.firstName?primaryBorrower.firstName:''} ${primaryBorrower.middleName?primaryBorrower.middleName:''} ${primaryBorrower.lastName?primaryBorrower.lastName:''} `;
    } catch (error) {
        console.log(error);
    }
    this.$store.state.tmpcoLoader = false;
    }

    public async getProcessorList(){
    this.$store.state.tmpcoLoader = true;
    try {
        const response = await Axios.get(`${BASE_API_URL}common/getActiveAllProcessorList`,
        { headers: authHeader() },
        );
        if (response.status === 201) {
            this.processorList = response.data;
        }
    } catch (error) {
      console.log(error);
    }
    this.$store.state.tmpcoLoader = false;
    }

    public async changeAssignedProcessor() {
        try {
        this.selectedWorkFlow.loanId = this.$route.query.id;
        this.$modal.hide('youSure');
        const response = await Axios.post(
        `${BASE_API_URL}common/changeAssignedProcessor`, this.selectedWorkFlow, { headers: authHeader() });
        if (response.status === 201) this.$snotify.success(response.data.message);
        else this.$snotify.error("Something Went Wrong");
        } catch (error) {
            console.log(error);
        }
    }

    public checkCondition(date) {
        if (date) return true;
        else return false; 
    }

    public showConfirmationPopup(WFlow){
        this.selectedWorkFlow = WFlow;
        this.$modal.show('youSure');
    }

    async mounted() {
        await this.getWorkFlow();
        await this.getProcessorList();
    }
}
