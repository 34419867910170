
import { Component, Vue, Prop } from "vue-property-decorator";
import { BASE_API_URL } from "../../../config";
import { authHeader } from "../../../services/auth";
import Axios from "axios";
@Component
export default class ReInspectionSubjectToRepairReceived extends Vue {
  /* eslint-disable */
  @Prop()
  public taskId: any;

  public error: any = false;
  public reInspectionReceivedSubjectToRepair: any = {};
  public isCompleted = false;
  // @Prop()
  // public reOrderCount: any;
  @Prop()
  public secondAppraisalCount: any;
  // public countToSendOnServer: any = null;
  public secondAppraisalCountForServer: any = null;
  public showReOrderBtn = false;
  public showHistoryBtn = false;
  @Prop()
  public isLoanInactive: any;
  @Prop()
  public totalTaskCount: any;
  public totalTaskCountForServer = null;
  @Prop()
  public isTaskDisabled: any;
  public isOrderPlacedOnce = false;
  /**
   * Taking dsData variable for checking form data is changed or not only for comaprison
   */
  public dsData: any = {};
  /**************************************************************** */

  public async checkSaveValidation() {
    try {
      if (
        !this.reInspectionReceivedSubjectToRepair.orderReceived &&
        this.reInspectionReceivedSubjectToRepair.orderReceived ==
          this.dsData.orderReceived
      )
        return true;
      else return false;
    } catch (error) {
      console.log(error);
    }
  }

  public async saveReInspectionSubjectToRepairReceivedInfo(isComplete) {
      if(this.isOrderPlacedOnce) return
    this.callLoader(true, null);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        await this.getReInspectionSubjectToRepairReceivedInfo();
        return;
      }
      if (
        isComplete &&
        !this.reInspectionReceivedSubjectToRepair.orderReceived
      ) {
        this.error = true;
        this.callLoader(false, null);
        return;
      }
      if (!isComplete) {
        let notValid1 = await this.checkSaveValidation();
        if (notValid1) {
          this.callLoader(false, null);
          this.$snotify.info("No Changes");
          return;
        }
      }
   if(isComplete) this.isOrderPlacedOnce=true;

      const response = await Axios.post(
        BASE_API_URL + "processor/saveReInspectionSubjectToRepairReceivedInfo",
        {
          loanId: this.$route.query.id,
          reInspectionReceivedSubjectToRepair:
            this.reInspectionReceivedSubjectToRepair,
          taskId: this.taskId,
          isComplete: isComplete,
          totalTaskCount: this.totalTaskCountForServer,
          // reOrderCount: this.countToSendOnServer,
          secondAppraisalCount: this.secondAppraisalCountForServer,
          formData: this.dsData,
        },
        { headers: authHeader() }
      );

      if (response.status === 201)
        this.$snotify.success(
          isComplete
            ? "Re Inspection Completed successfully."
            : "Re Inspection Received Information has been saved"
        );
      if (isComplete) this.$emit("refresh-task-list", true);
      await this.getReInspectionSubjectToRepairReceivedInfo();
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async getReInspectionSubjectToRepairReceivedInfo() {
    this.callLoader(true, null);
    try {
      const response = await Axios.post(
        BASE_API_URL + "processor/getReInspectionSubjectToRepairReceivedInfo",
        {
          loanId: this.$route.query.id,
          taskId: this.taskId,
          totalTaskCount: this.totalTaskCountForServer,
          // reOrderCount: this.countToSendOnServer,
          secondAppraisalCount: this.secondAppraisalCountForServer,
        },
        {
          headers: authHeader(),
        }
      );
      if (response.status === 201) {
        this.reInspectionReceivedSubjectToRepair = response.data
          .reInspectionReceivedSubjectToRepair
          ? JSON.parse(
              JSON.stringify(response.data.reInspectionReceivedSubjectToRepair)
            )
          : {};
        this.isCompleted = response.data.isComplete;
        this.showHistoryBtn = response.data.showHistoryBtn;
        this.dsData = response.data.reInspectionReceivedSubjectToRepair
          ? JSON.parse(
              JSON.stringify(response.data.reInspectionReceivedSubjectToRepair)
            )
          : {};
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public async showHistory() {
    this.callLoader(true, 1);
    try {
      this.$refs.refReOrder["showHistory"](this.taskId, "Re Inspection");
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async allMountFunction(count, secondAppraisalCount) {
    this.totalTaskCountForServer = count;
    // this.countToSendOnServer = count;
    this.secondAppraisalCountForServer = secondAppraisalCount;
    await this.getReInspectionSubjectToRepairReceivedInfo();
  }

  async mounted() {
    await this.allMountFunction(this.totalTaskCount, this.secondAppraisalCount);
    // this.allMountFunction(this.reOrderCount, this.secondAppraisalCount);
  }
}

/* eslint-disable */
