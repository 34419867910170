
import { Component, Vue, Prop } from "vue-property-decorator";
import { BASE_API_URL, EventBus, ENV } from "../../../config";
import Axios from "axios";
import { authHeader } from "../../../services/auth";
import {
  REFINANCE_TYPE,
  AMORTIZATION_TYPE,
  RefinanceProgramme,
  ESTATE_HELD,
  RELATIONSHIP_VESTINGS,
  TRUST_CLASSIFICATIONS,
  NATIVE_AMERICAN_LAND_TYPE_OTHER,
  NATIVE_AMERICAN_LAND_TYPE,
  LIEN_PRIORITY_TYPE,
} from "../../../constants/constants";
import { LenderInfo } from "../../../models/urla/lender-info.model";
import datepicker from "vue2-datepicker";
import { sumObjectValues as sumObject } from "../../../services/calculations";

@Component({
  components: {
    datepicker,
  },
})
export default class Lender extends Vue {
  /* eslint-disable */
  public refinanceType = REFINANCE_TYPE;
  public amortizationTypes = AMORTIZATION_TYPE;
  public lenderInfo: any = new LenderInfo();
  public lenderData: any = new LenderInfo();
  public refinanceProgramme = RefinanceProgramme;
  public loanAndPropertyInfo: any = {};
  public hasOtherMortgage: any = null;
  public estateHeld = ESTATE_HELD;
  public mannerHeld = RELATIONSHIP_VESTINGS;
  public trustClass = TRUST_CLASSIFICATIONS;
  public nativeLand = NATIVE_AMERICAN_LAND_TYPE;
  public otherNativeLand = NATIVE_AMERICAN_LAND_TYPE_OTHER;
  public lienPriorityType = LIEN_PRIORITY_TYPE;
  public sumObjectValues = sumObject;
  public fundingFee: any = null;
  public fundingAmount: any = null;
  public dpCon: any = null;
  public percentageOfFunding: any = null;
  public loanTermInYear: any = null;
  public amount: any = null;
  public rate: any = null;
  public miCondition: any = null;
  public ltv: any = null;
  public fhaVal: any = null;
  public count = 0;
  public changedValues: any = [];
  @Prop()
  public validationData: any;
  @Prop()
  public isLoanInactive: any;
  public fundingFeeIndicator: any = false;
  public ENV = ENV;

  public hasAnychangedValues() {
    return this.changedValues;
  }

  public async getLenderInfo() {
    try {
      this.callLoader(true, null);
      this.changedValues = [];
      let res = await Axios.get(
        BASE_API_URL + "urla/getLenderInfo/" + this.$route.query.id,
        {
          headers: authHeader(),
        }
      );
      this.lenderInfo = res.data.lenderInfo;
      console.log('res.data.lenderInfo',this.lenderInfo.transactionDetail)
      this.lenderInfo.transactionDetail.constructionToPermanent.improvementCost =
       this.lenderInfo.transactionDetail &&
        this.lenderInfo.transactionDetail.constructionToPermanent &&
        this.lenderInfo.transactionDetail.constructionToPermanent
          .improvementCost
          ? Number(
              this.lenderInfo.transactionDetail.constructionToPermanent
                .improvementCost
            )
          : 0;
      this.loanAndPropertyInfo = res.data.loanAndPropertyInfo;
      this.hasOtherMortgage = res.data.hasOtherMortgage;
      this.lenderData.transactionDetail.constructionToPermanent = JSON.parse(
        JSON.stringify(
          this.lenderInfo.transactionDetail.constructionToPermanent
        )
      );
      this.lenderInfo.proposedMonthlyPaymentForProperty.firstMortgage = this.getPandI()
      this.lenderData = JSON.parse(JSON.stringify(this.lenderInfo));
      this.calculateClosingCost();
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public async updateLenderInfo() {
    try {
      let res = await Axios.post(
        BASE_API_URL + "urla/updateLenderInfo",
        {
          ...this.lenderInfo,
          loanId: this.$route.query.id,
          changedValues: this.changedValues,
        },
        {
          headers: authHeader(),
        }
      );
      if (res.status == 201 && this.count > 0) {
        this.$snotify.success("Lender Information has been Updated");
      }
      this.count = 0;
      // await this.getLenderInfo();
      this.$emit("referesh-tab", true);
      EventBus.$emit("refesh-loaninfo");
    } catch (error) {
      console.log(error);
    }
  }

  public async handleChange(currentVal, previousVal, fieldName) {
    let index = this.changedValues.findIndex((c) => c.name === fieldName);
    if (index !== -1) {
      if (currentVal === previousVal) {
        this.changedValues.splice(index, 1);
      } else {
        let data = this.changedValues.find((v) => v.name === fieldName);
        data.from = previousVal;
        data.to = currentVal;
      }
    } else {
      this.changedValues.push({
        name: fieldName,
        from: previousVal,
        to: currentVal,
      });
    }
  }

  public getSum(label: string) {
    let keys: any = [];
    switch (label) {
      case "loanAmount":
        keys = ["loanExcludingFinanced", "financedMortgage"];
        break;
      case "totalDueForBorrower":
        keys = [
          "salesContractPrice",
          "improvementRepair",
          "land",
          "loanForRefinance",
          "creditCardAndOther",
          "closingCosts",
          "discount",
          "estimatedClosingCost",
          "miFundingFeeTotal",
          "prepaidItemsEstimatedAmount",
        ];
        break;
      case "totalMortgage":
        keys = [
          "loanExcludingFinanced",
          "financedMortgage",
          "otherNewMortgage",
        ];
        break;
      case "totalCredit":
        keys = ["sellerCredit", "otherCredit"];
        break;
      case "totalMortgageAndCredit":
        keys = [
          "otherNewMortgage",
          "sellerCredit",
          "otherCredit",
          "loanExcludingFinanced",
          "financedMortgage",
        ];
        break;
    }
    return this.sumObjectValues(this.lenderInfo, keys);
  }

  public getTotalLoanAmount() {
    
    try {
      let totalLoanAmount = 0;
      totalLoanAmount =
        Number(this.loanAndPropertyInfo.loanAmount) + Number(this.lenderInfo.financedMortgage);
      return totalLoanAmount;
    } catch (error) {
      console.log(error);
    }
  }

  public getTotalCalculation() {
    return (
      Number(this.getSum("totalDueForBorrower")) -
      Number(this.getSum("totalMortgageAndCredit"))
    ).toFixed(2);
  }

  public changeAmortization() {
    try {
      if (this.lenderInfo.amortizationType.type !== "Other") {
        this.lenderInfo.amortizationType.description = null;
      }
      if (this.lenderInfo.amortizationType.type !== "AdjustableRate")
        this.lenderInfo.amortizationType.priorAdjustmentPeriod = 0;
      this.lenderInfo.amortizationType.subsequentAdjustmentPeriod = 0;
    } catch (err) {
      console.error(err);
    }
  }

  public initializeDescriptionValue() {
    if (
      this.lenderInfo.titleInformation &&
      this.lenderInfo.titleInformation.indianLandTenure.type !== "Other"
    ) {
      this.lenderInfo.titleInformation.indianLandTenure.description = null;
    }
  }

  public initializeOtherValue() {
    if (
      this.lenderInfo.titleInformation &&
      this.lenderInfo.titleInformation.titleMannerHeld &&
      this.lenderInfo.titleInformation.titleMannerHeld.type != "Other"
    )
      this.lenderInfo.titleInformation.titleMannerHeld.description = null;
  }

  public changeOtherValue() {
    if (
      this.lenderInfo.loanFeatures &&
      this.lenderInfo.loanFeatures.other &&
      !this.lenderInfo.loanFeatures.other.isOther &&
      this.lenderInfo.loanFeatures.other.description
    )
      this.lenderInfo.loanFeatures.other.description = null;
  }

  public changeBalloonTerm() {
    if (
      this.lenderInfo.loanFeatures &&
      this.lenderInfo.loanFeatures.ballonTerm &&
      !this.lenderInfo.loanFeatures.ballonTerm.isBallonTerm &&
      this.lenderInfo.loanFeatures.ballonTerm.term
    )
      this.lenderInfo.loanFeatures.ballonTerm.term = 0;
  }

  public changeInterestOnlyTerm() {
    if (
      this.lenderInfo.loanFeatures &&
      this.lenderInfo.loanFeatures.interestTerm &&
      !this.lenderInfo.loanFeatures.interestTerm.isInterestTerm &&
      this.lenderInfo.loanFeatures.interestTerm.term
    )
      this.lenderInfo.loanFeatures.interestTerm.term = 0;
  }

  public changeprePenaltyTerm() {
    if (
      this.lenderInfo.loanFeatures &&
      this.lenderInfo.loanFeatures.prePaymentPenaltyTerm &&
      !this.lenderInfo.loanFeatures.prePaymentPenaltyTerm
        .isPrePaymentPenaltyTerm &&
      this.lenderInfo.loanFeatures.prePaymentPenaltyTerm.term
    )
      this.lenderInfo.loanFeatures.prePaymentPenaltyTerm.term = 0;
  }

  public changeTemporary() {
    if (
      this.lenderInfo.loanFeatures &&
      this.lenderInfo.loanFeatures.buyDownInterestrate &&
      !this.lenderInfo.loanFeatures.buyDownInterestrate.isBuyDownInterestrate &&
      this.lenderInfo.loanFeatures.buyDownInterestrate.rate
    )
      this.lenderInfo.loanFeatures.buyDownInterestrate.rate = 0;
  }

  public changeConstructionValue() {
    if (
      this.lenderInfo.transactionDetail &&
      this.lenderInfo.transactionDetail.constructionToPermanent &&
      !this.lenderInfo.transactionDetail.constructionToPermanent
        .isConstructionToPermanent &&
      this.lenderInfo.transactionDetail.constructionToPermanent
        .typeOfConstruction
    ) {
      this.lenderInfo.transactionDetail.constructionToPermanent.typeOfConstruction =
        null;
    }

    if (
      this.lenderInfo.transactionDetail &&
      this.lenderInfo.transactionDetail.constructionToPermanent &&
      !this.lenderInfo.transactionDetail.constructionToPermanent
        .isConstructionToPermanent &&
      this.lenderInfo.transactionDetail.constructionToPermanent.improvementCost
    ) {
      this.lenderInfo.transactionDetail.constructionToPermanent.improvementCost = 0;
    }
  }

  public changeRefDescription() {
    if (
      this.lenderInfo.refinanceProgramme &&
      this.lenderInfo.refinanceProgramme.type != "Other" &&
      this.lenderInfo.refinanceProgramme.description
    )
      this.lenderInfo.refinanceProgramme.description = null;
  }

  roudOffValue(key, type) {
    try {
      let amount;
      if (type === "object") {
        amount = Number(this.lenderInfo.proposedMonthlyPaymentForProperty[key]);
        this.lenderInfo.proposedMonthlyPaymentForProperty[key] =
          amount.toFixed(2);
      } else {
        amount = Number(this.lenderInfo[key]);
        this.lenderInfo[key] = amount ? amount.toFixed(2) : 0;
      }
      return amount;
    } catch (err) {
      console.error(err);
    }
    return 0;
  }

  public checkForValidation() {
    this.$emit("call-mount", true);
  }

  public async getCalculatedValues() {
    try {
      this.callLoader(true, null);
      let res = await Axios.post(
        BASE_API_URL + "urla/getCalculatedValue",
        {
          loanId: this.$route.query.id,
        },
        {
          headers: authHeader(),
        }
      );
      // if(res.data.fundingFee !=0) this.lenderInfo.financedMortgage = res.data.fundingFee
      this.fundingFeeIndicator = res.data.fundingFeeIndicator;
      this.lenderInfo.proposedMonthlyPaymentForProperty.firstMortgage = res.data.calculateValue;
      //if(res.data.miVal != 0) this.lenderInfo.proposedMonthlyPaymentForProperty.mortgageInsurance = res.data.miVal
      this.dpCon = res.data.dpCon ? res.data.dpCon : 0;
      this.percentageOfFunding = res.data.percentageOfFunding
        ? res.data.percentageOfFunding
        : 0;
      this.amount = res.data.amount ? res.data.amount : 0;
      this.rate = res.data.rate ? res.data.rate : 0;
      this.ltv = res.data.ltv ? res.data.ltv : 0;
      this.fhaVal = res.data.fhaMi ? res.data.fhaMi : 0;
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  //   public getDownPayment() {
  //    try {
  //      let loanAmt =  this.loanAndPropertyInfo.loanAmount ? Number(this.loanAndPropertyInfo.loanAmount) : 0
  //       let pptVal = this.loanAndPropertyInfo.propertyValue ? Number(this.loanAndPropertyInfo.propertyValue) : 0
  //       let downPaymentAmt = pptVal - loanAmt
  //        let percentage = (downPaymentAmt/pptVal) * 100
  //     isNaN(percentage) || !isFinite(percentage)? percentage = 0 : percentage
  //     return  percentage
  //    } catch(error){
  //      console.log(error)
  //    }
  //  }

  //  public getFundingFeeForVA() {
  //    try{
  //     let fundingFee = 0
  //     let downPaymentPer: any = Number(this.getDownPayment())
  //      if(this.loanAndPropertyInfo.loanPurpose &&  this.loanAndPropertyInfo.loanPurpose.name === "Purchase"
  //          && this.loanAndPropertyInfo.loanType && this.loanAndPropertyInfo.loanType.type == 'VA'){
  //        if(downPaymentPer == 0){
  //          this.dpCon = "downPaymentPer = 0"
  //        if(this.loanAndPropertyInfo.vaType == 'firstTimeUse'){
  //           let pr= 2.3 / 100
  //           this.percentageOfFunding = 2.3
  //           let ff = this.loanAndPropertyInfo.loanAmount * pr
  //           fundingFee = ff
  //        }
  //        else if(this.loanAndPropertyInfo.vaType == 'subsequentUse'){
  //           let pr= 3.6/ 100
  //            this.percentageOfFunding = 3.6
  //           let ff = this.loanAndPropertyInfo.loanAmount * pr
  //           fundingFee = ff
  //        }
  //      }
  //      else if(downPaymentPer > 5 && downPaymentPer < 10) {
  //             this.dpCon = "downPaymentPer > 5 && downPaymentPer < 10"
  //           let pr= 1.65 / 100
  //            this.percentageOfFunding = 1.65
  //           let ff = this.loanAndPropertyInfo.loanAmount * pr
  //           fundingFee = ff
  //      }
  //        else if(downPaymentPer > 10) {
  //            this.dpCon = "downPaymentPer > 10"
  //           let pr= 1.4 / 100
  //            this.percentageOfFunding = 1.4
  //           let ff = this.loanAndPropertyInfo.loanAmount * pr
  //           fundingFee = ff
  //      }
  //   }
  //   else if( this.loanAndPropertyInfo.loanPurpose && this.loanAndPropertyInfo.loanPurpose.name === "Refinance" &&
  //            this.loanAndPropertyInfo.loanType &&  this.loanAndPropertyInfo.loanType.type === 'VA')
  //    {
  //    if(this.loanAndPropertyInfo.refinanceType === 'CashOut'){
  //    if(this.loanAndPropertyInfo.vaType == 'firstTimeUse') {
  //        let pr = 2.3 / 100
  //         this.percentageOfFunding = 2.3
  //        let ff = this.loanAndPropertyInfo.loanAmount * pr
  //        fundingFee = ff
  //    }
  //    else if(this.loanAndPropertyInfo.vaType == 'subsequentUse') {
  //       let pr = 3.6 / 100
  //       this.percentageOfFunding = 3.6
  //        let ff = this.loanAndPropertyInfo.loanAmount * pr
  //        fundingFee = ff
  //    }
  //    }
  //     if(this.loanAndPropertyInfo.refinanceType === 'NoCashOut'){
  //        let pr = 0.5 / 100
  //         this.percentageOfFunding = 0.5
  //        let ff = this.loanAndPropertyInfo.loanAmount * pr
  //        fundingFee = ff

  //     }
  //   }
  //      fundingFee = fundingFee && !isNaN(fundingFee) && isFinite(fundingFee) ? fundingFee : 0;
  //    return fundingFee
  //    } catch(error){
  //      console.log(error)
  //    }

  //   }

  public getPandI() {
  try {
      let monthlyPayment = 0;

      let loanTerm = this.loanAndPropertyInfo.loanTerm
      this.amount = this.loanAndPropertyInfo.totalLoanAmount
      
      this.rate = this.loanAndPropertyInfo.interestRate / 100 / 12;
      let newInterest = this.rate + 1;
      let numerator = Math.pow(newInterest, loanTerm);
      let newValue = (numerator * this.rate) / (numerator - 1);
      let calculateValue = newValue * this.amount;
      monthlyPayment = Math.round(calculateValue * 100) / 100;
      monthlyPayment = monthlyPayment && !isNaN(monthlyPayment) && isFinite(monthlyPayment) ? monthlyPayment : 0;
      this.lenderInfo.proposedMonthlyPaymentForProperty.firstMortgage = monthlyPayment
      return monthlyPayment;
    

  } catch (err) {
      console.log(err);
  }
  return 0;

  }

  // public getLtv() {
  //   console.log('coming')
  //    let ltv: any = 0.0;
  //   if (this.loanAndPropertyInfo.loanAmount && this.loanAndPropertyInfo.propertyValue) {
  //   ltv =
  //    (this.loanAndPropertyInfo.loanAmount / this.loanAndPropertyInfo.propertyValue) * 100;
  //   ltv = ltv && !isNaN(ltv) && isFinite(ltv) ? ltv : "0.00";
  //   }
  //     return ltv
  // }

  //    public  getMiForFHA () {
  //     try {
  //     let ltv = this.getLtv()
  //      this.loanTermInYear = this.loanAndPropertyInfo.loanTerm / 12
  //     let fundingFee = 0
  //     let mip = 0
  //      if(this.loanAndPropertyInfo.loanType && this.loanAndPropertyInfo.loanType.type == 'FHA'
  //        && this.loanAndPropertyInfo.refinanceType != 'NoCashOut') {
  //        if(this.loanTermInYear > 15) {
  //        if(this.loanAndPropertyInfo.loanAmount <= 625500){
  //         //  if(ltv <= 90 || (ltv > 90 && ltv <= 95)) {
  //             if(ltv <= 95) {
  //           mip = 80 / 100
  //          }
  //          else if(ltv > 95)
  //           mip = 85 / 100
  //        }
  //        else {
  //           // if(ltv <= 90 || (ltv > 90 && ltv <= 95))
  //           if(ltv <= 95)
  //            mip = 100 / 100
  //            else if(ltv > 95)
  //             mip = 105 / 100
  //        }
  //        }
  //        else {
  //          if(this.loanAndPropertyInfo.loanAmount <= 625500){
  //            if(ltv <= 90)
  //             mip = 45 / 100
  //             if(ltv > 90)
  //              mip = 70 / 100
  //          }
  //          else {
  //            if(ltv <= 78)
  //             mip = 45 / 100
  //             if(ltv > 78 && (ltv <= 90))
  //              mip = 70 / 100
  //             else
  //              mip = 95 / 100
  //          }

  //        }
  //      }
  //     else if(this.loanAndPropertyInfo.loanType && this.loanAndPropertyInfo.loanType.type === 'FHA'
  //        && this.loanAndPropertyInfo.refinanceType === 'NoCashOut'){
  //          mip = 55 / 100
  //        }
  //        let data = mip * this.loanAndPropertyInfo.loanAmount
  //        let datas = data / 1200
  //        datas = datas && !isNaN(datas) && isFinite(datas) ?  Number(datas) : 0;
  //       return datas

  //   } catch(error) {
  //      console.log(error)
  //    }

  //  }

  //  public getFundingFeeForFHA() {
  //    try {
  //      let upfrontMi = 0
  //       upfrontMi = 1.75 / 100
  //      let fundFee = this.loanAndPropertyInfo.loanAmount * upfrontMi
  //       fundFee = fundFee && !isNaN(fundFee) && isFinite(fundFee) ? fundFee : 0;
  //     return  fundFee
  //    } catch(error) {
  //      console.log(error)
  //    }
  //  }

  //   public getFundingFee() {
  //     try {
  //       let upfrontFee = 1/100
  //        let fundingAmount = 0
  //      if(this.loanAndPropertyInfo.loanType && this.loanAndPropertyInfo.loanType.type === 'VA')
  //       return this.getFundingFeeForVA()
  //       else if( this.loanAndPropertyInfo.loanType && this.loanAndPropertyInfo.loanType.type === 'FHA' )
  //       return this.getFundingFeeForFHA()
  //       else if(this.loanAndPropertyInfo.loanType && this.loanAndPropertyInfo.loanType.type === 'USDARuralDevelopment') {
  //          fundingAmount = this.loanAndPropertyInfo.loanAmount * upfrontFee
  //          fundingAmount = fundingAmount && !isNaN(fundingAmount) && isFinite(fundingAmount) ? fundingAmount : 0;
  //            this.fundingAmount = fundingAmount
  //           return fundingAmount
  //       }
  //       else
  //       return 0
  //     } catch(error) {
  //       console.log(error)
  //     }
  //   }

  // public getMiVal() {
  //   if(this.loanAndPropertyInfo.loanType && this.loanAndPropertyInfo.loanType.type === 'FHA'){
  //    this.lenderInfo.proposedMonthlyPaymentForProperty.mortgageInsurance = this.getMiForFHA()
  //   return this.getMiForFHA()
  //   }
  //   else if(this.loanAndPropertyInfo.loanType && this.loanAndPropertyInfo.loanType.type === 'USDARuralDevelopment'){
  //     let mi = 0.35 / 100
  //     let miVal = (mi * this.loanAndPropertyInfo.loanAmount)/12
  //      miVal = miVal && !isNaN(miVal) && isFinite(miVal) ? miVal : 0;
  //      this.lenderInfo.proposedMonthlyPaymentForProperty.mortgageInsurance = miVal
  //     return miVal
  //   }
  //   else
  //   // this.lenderInfo.proposedMonthlyPaymentForProperty.mortgageInsurance = 0
  //   return 0
  // }











  public calculateClosingCost() {
    this.lenderInfo.closingCost =
      this.lenderInfo.estimatedClosingCost +
      this.lenderInfo.miFundingFeeTotal +
      this.lenderInfo.prepaidItemsEstimatedAmount;
  }

  public checkValidationForMobile() {
    EventBus.$emit("check-validation", true);
  }

  async mounted() {
    await this.getLenderInfo();
    // await  this.calculateClosingCost();
    await this.getCalculatedValues();
    this.validationData ? this.checkForValidation() : "";
    EventBus.$on(
      "refreshLenderInfoTab",
      async () => await this.getLenderInfo()
    );
    EventBus.$on(
      "refreshLenderInfoTab",
      async () => await this.getCalculatedValues()
    );
  }
}
