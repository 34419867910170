var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"white-card loan-process-height-249 mb-2 d-flex flex-column"},[_vm._m(0),_c('div',{staticClass:"col-lg-12 pt-3 pb-3 px-1 flex-1 height_100"},[_c('div',{staticClass:"form-row min_293 flex-column col-12 m-0"},[_c('div',{staticClass:"flex-1 col-12"},[_c('div',{staticClass:"form-row",staticStyle:{"flex-direction":"column"}},[_c('div',{staticClass:"col-lg-12 p-0 mb-2"},[_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("Cancelled/Withdrawn")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.mileStoneId),expression:"mileStoneId"}],attrs:{"type":"radio","name":"mileStoneId","disabled":_vm.isTaskCompleted,"value":"59"},domProps:{"checked":_vm._q(_vm.mileStoneId,"59")},on:{"change":[function($event){_vm.mileStoneId="59"},function($event){_vm.error = { show: false, msg: null }; _vm.isRemark = false;
                  _vm.isCompleted = false; _vm.saveCancelledLoanData();}]}}),_c('span',{staticClass:"checkround"})]),_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("Denial")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.mileStoneId),expression:"mileStoneId"}],attrs:{"type":"radio","name":"mileStoneId","disabled":_vm.isTaskCompleted,"value":"37"},domProps:{"checked":_vm._q(_vm.mileStoneId,"37")},on:{"change":[function($event){_vm.mileStoneId="37"},function($event){_vm.error = { show: false, msg: null };
                  _vm.isRemark = false;
                  _vm.isCompleted = false;
                  _vm.saveCancelledLoanData();}]}}),_c('span',{staticClass:"checkround"})]),(!_vm.isTaskCompleted)?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 p-0"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label"},[_vm._v("Add Remark")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.remark),expression:"remark"}],staticClass:"login-input",attrs:{"type":"text","value":"remark","id":"remark"},domProps:{"value":(_vm.remark)},on:{"change":function($event){_vm.error = { show: false, msg: null }},"input":function($event){if($event.target.composing)return;_vm.remark=$event.target.value}}})])]):_vm._e()]),_c('div',{staticClass:"form-group d-flex align-items-center col-12 p-0"},[_c('div',{staticClass:"file-upload mt-1 overflow_hidden"},[_c('label',{staticClass:"file-upload-label mb-0",attrs:{"for":"uploadCCA"}},[_c('i',{staticClass:"fa fa-upload mr-1"}),_vm._v(" "+_vm._s(_vm.docLoader ? "Uploading..." : "Upload NOAT"))]),_c('input',{staticClass:"file-upload-input",attrs:{"id":"uploadCCA","type":"file","name":"file-upload"},on:{"change":function($event){_vm.uploadReport($event);
                  _vm.errorObj = { show: false, msg: null };}}})]),(_vm.loanChecklistData && _vm.loanChecklistData.documentId)?_c('i',{staticClass:"ri-file-text-line ml-2 mr-2 text__blue cursor-pointer",on:{"click":function($event){return _vm.viewDocument(_vm.loanChecklistData)}}}):_vm._e()]),(_vm.cancelDenail && _vm.cancelDenail.remark)?_c('div',{staticClass:"col-12 p-0 mt-1"},[_c('div',{staticClass:"loan-process-border p-3 mb-2"},[_c('p',{staticClass:"mb-0 card-content-title"},[_c('b',{},[_vm._v(_vm._s(_vm.mileStoneId == 59?'Cancelled By':'Denied By')+" :- "+_vm._s(_vm.addedBy)+" Reason :- "+_vm._s(_vm.cancelDenail.remark)+" "+_vm._s(_vm.mileStoneId == 59?'Cancelled On':'Denied On')+" :- "+_vm._s(_vm.$moment(_vm.cancelDenail.createdOn).format( "MM/DD/YYYY, h:mm:ss a" ))+" ")])])])]):_vm._e()])]),_c('hr',{staticClass:"loan-process-border-top"}),_c('div',{staticClass:"d-flex justify-content-end form-group align-items-center"},[(_vm.error.show || _vm.isRemark)?_c('span',{staticClass:"login-error show mr-2"},[_vm._v(_vm._s(_vm.error.msg))]):_vm._e(),_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('button',{staticClass:"tmpco-btn tmpco-btn__theme",class:[
              _vm.isTaskCompleted ? 'btn-upload cursor-pointer cursor-not-allow'
                : 'btn-upload cursor-pointer', ],attrs:{"type":"button","disabled":_vm.isTaskCompleted},on:{"click":function($event){return _vm.$modal.show('deleteConfirmationModal')}}},[_vm._v(" "+_vm._s(_vm.isTaskCompleted ? "Completed" : "Complete")+" ")])])])]),_c('modal',{staticClass:"modal",attrs:{"centered":"","name":"deleteConfirmationModal","hide-footer":true}},[_c('div',{staticClass:"modal--md-regular"},[_c('div',{staticClass:"tmpco-modal__content"},[_c('div',{staticClass:"tmpco-modal__header"},[_c('h4',{staticClass:"tmpco-modal__title"},[_vm._v("Confirmation")]),_c('button',{staticClass:"tmpco-modal__close",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":function($event){return _vm.$modal.hide('deleteConfirmationModal')}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"tmpco-modal__body"},[_c('div',{staticClass:"col-lg-12 col-xl-12"},[_c('p',{staticClass:"mt-3 mb-3 content-modal"},[_vm._v(" Are you sure want to Cancel this Loan ? ")])])]),_c('div',{staticClass:"tmpco-modal__footer"},[_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-default mr-2",on:{"click":function($event){return _vm.$modal.hide('deleteConfirmationModal')}}},[_vm._v(" No ")]),_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-theme",on:{"click":function($event){_vm.isCompleted = true; _vm.saveCancelledLoanData();
                _vm.$modal.hide('deleteConfirmationModal');}}},[_vm._v(" Yes ")])])])])])],1),_c('ViewDocument',{ref:"ref",on:{"call-mount-funtion":function($event){return _vm.getDocument()}}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"loan-process-heading"},[_c('div',{staticClass:"col-lg-12"},[_c('p',{staticClass:"heading mb-0"},[_vm._v("Cancelled")])])])
}]

export { render, staticRenderFns }