
import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../../../config";
import { authHeader } from "../../../services/auth";
import ReOrderTask from "../../../common-components/ReOrderTask.vue";
@Component({
  components: {
    ReOrderTask,
  },
})
export default class IbtsOrder extends Vue {
  /* eslint-disable */
  public ibtsOrder: any = {};
  @Prop()
  public taskId: any;
  // @Prop()
  // public reOrderCount: any;
  // @Prop()
  // public isLoanInactive: any;
  public isLoanCompleted = false;
  public error: any = {
    show: false,
    msg: null,
  };
  public showReOrderBtn = false;
  // public countToSendOnServer: any = null;
  public showHistoryBtn = false;
  @Prop()
  public secondAppraisalCount: any;
  public secondAppraisalCountForServer: any = null;
  @Prop()
  public totalTaskCount: any;
  public totalTaskCountForServer = null;
  public dsData: any = {};
  @Prop()
  public isTaskDisabled: any;
  public isOrderPlacedOnce = false;

  public saveValidation() {
    if (this.dsData.isIbtsOrdered == this.ibtsOrder.isIbtsOrdered) return true;
    else return false;
  }

  public async saveIbtsOrder(isComplete) {
    if (this.isOrderPlacedOnce) return;
    this.callLoader(true, null);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        await this.getIbtsOrder();
        return;
      }
      if (!isComplete) {
        let notValid1 = await this.saveValidation();
        if (notValid1) {
          this.$snotify.info("No Changes");
          this.callLoader(false, null);
          return;
        }
      }

      if (isComplete && !this.ibtsOrder.isIbtsOrdered) {
        this.error.show = true;
        this.error.msg = "All fields are Required.";
        this.callLoader(false, null);
        return;
      }
      if (isComplete) this.isOrderPlacedOnce = true;
      const response = await Axios.post(
        BASE_API_URL + "processor/saveIbtsOrder",
        {
          taskId: this.taskId,
          loanId: this.$route.query.id,
          ibtsOrder: this.ibtsOrder,
          totalTaskCount: this.totalTaskCountForServer,
          // reOrderCount: this.countToSendOnServer,
          secondAppraisalCount: this.secondAppraisalCountForServer,
          isComplete: isComplete,
          formData: this.dsData,
        },
        { headers: authHeader() }
      );
      if (response.status === 201)
        this.$snotify.success(
          isComplete
            ? "IBTS Order Completed successfully."
            : "IBTS  Order Information has been saved."
        );

      if (isComplete) this.$emit("refresh-task-list", true);
      await this.getIbtsOrder();
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async getIbtsOrder() {
    this.callLoader(true, null);
    try {
      const response = await Axios.post(
        BASE_API_URL + "processor/getIbtsOrder/",
        {
          loanId: this.$route.query.id,
          taskId: this.taskId,
          totalTaskCount: this.totalTaskCountForServer,
          // reOrderCount: this.countToSendOnServer,
          secondAppraisalCount: this.secondAppraisalCountForServer,
        },
        {
          headers: authHeader(),
        }
      );

      if (response.status === 201) {
        this.isLoanCompleted = response.data.isCompleted;
        this.showReOrderBtn = response.data.showReOrderBtn;
        this.ibtsOrder = response.data.taskData
          ? JSON.parse(JSON.stringify(response.data.taskData))
          : {};
        this.dsData = response.data.taskData
          ? JSON.parse(JSON.stringify(response.data.taskData))
          : {};
        this.showHistoryBtn = response.data.showHistoryBtn;
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public async reOrderIbts() {
    this.callLoader(true, 1);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        return;
      }
      this.$refs.refReOrder["confitmationBox"]({
        message: "Are you sure you want to RE-Order IBTS Order?",
        apiPath: `processor/reOrderIbts`,
        data: { loanId: this.$route.query.id, taskId: this.taskId },
      });
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async allMountFunction(count, secondAppraisalCount) {
    this.totalTaskCountForServer = count;
    this.secondAppraisalCountForServer = secondAppraisalCount;
    // this.countToSendOnServer = count;
    this.getIbtsOrder();
  }

  public async showHistory() {
    this.callLoader(true, null);
    try {
      this.$refs.refReOrder["showHistory"](this.taskId, "IBTS");
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async mounted() {
    await this.allMountFunction(this.totalTaskCount, this.secondAppraisalCount);
    // await this.allMountFunction(this.reOrderCount, this.secondAppraisalCount);
  }
}
