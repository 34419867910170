
import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../../../config";
import { authHeader } from "../../../services/auth";
import {
  VerificationOfAgent,
  //VendorData,
} from "../../../models/workflow/credit-supplement.model";
@Component
export default class CreditSupplimentOrder extends Vue {
  /* eslint-disable */
  @Prop()
  public taskId: any;
  public creditSupplementOrder: any = new VerificationOfAgent();
  public error = false;
  public agent: any = {};
  @Prop()
  public isLoanInactive: any;
  public isLoanCompleted = false;
  public mortgageClause: any = {};
  public creditAgencies: any = [];
  public selectedCompany: any = null;
  @Prop()
  public isTaskDisabled: any;
  public isOrderPlacedOnce = false
  /**
   * Taking dsData variable for checking form data is changed or not only for comaprison
   */
  public dsData: any = {};
  /**************************************************************** */

  public async checkSaveValidation() {
    try {
      if (
        !this.creditSupplementOrder.iscreditSupplementOrdered &&
        !this.creditSupplementOrder.creditaAgenciesId &&
        this.creditSupplementOrder.iscreditSupplementOrdered ==
          this.dsData.iscreditSupplementOrdered
      )
        return true;
      else return false;
    } catch (error) {
      console.log(error);
    }
  }

  public async checkValidation() {
    try {
      if (
        !this.creditSupplementOrder.creditaAgenciesId ||
        !this.creditSupplementOrder.iscreditSupplementOrdered
      )
        return true;
      else false;
    } catch (error) {
      console.log(error);
    }
  }

  async getCreditAgenciesList() {
    this.callLoader(true, 1);
    try {
      let response = await Axios.get(
        BASE_API_URL +
          "processor/getCreditAgenciesList/" +
          this.$route.query.id,
        {
          headers: authHeader(),
        }
      );
      if (response.status === 201) {
        this.creditAgencies = response.data;
        this.onChangeF(this.creditSupplementOrder.creditaAgenciesId);
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async saveCreditSupplimentOrder(isComplete) {
    if(this.isOrderPlacedOnce) return
    this.callLoader(true, null);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        await this.getCreditSupplimentOrder();
        return;
      }
      let notValid = await this.checkValidation();
      if (!isComplete) {
        let notValid1 = await this.checkSaveValidation();
        if (notValid1) {
          this.callLoader(false, null);
          this.$snotify.info("No Changes");
          return;
        }
      }
      if (isComplete && notValid) {
        this.error = true;
        this.callLoader(false, null);
        return;
      }
      // if (!this.creditSupplementOrder.iscreditSupplementOrdered && isComplete) {
      //   this.error = true;
      //   this.callLoader(false, null);
      //   return;
      // }

      if(isComplete) this.isOrderPlacedOnce=true;
      let response = await Axios.post(
        BASE_API_URL + "processor/saveCreditSupplimentOrder",
        {
          taskId: this.taskId,
          loanId: this.$route.query.id,
          creditSupplementOrder: this.creditSupplementOrder,
          isComplete: isComplete,
          formData: this.dsData,
        },
        { headers: authHeader() }
      );
      if (!isComplete) {
        this.$snotify.success(
          "Credit Supplement Order Information has been saved"
        );
      }

      if (response.status === 201 && isComplete) {
        this.$snotify.success(
          "Credit Supplement Order Completed successfully."
        );
      }
      if (isComplete) this.$emit("refresh-task-list", true);
      await this.getCreditSupplimentOrder();
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async getCreditSupplimentOrder() {
    this.callLoader(true, null);
    try {
      const response = await Axios.get(
        BASE_API_URL +
          "processor/getCreditSupplimentOrder/" +
          this.$route.query.id +
          "/" +
          this.taskId,
        {
          headers: authHeader(),
        }
      );

      if (response.status === 201) {
        this.isLoanCompleted = response.data.isCompleted;
        this.creditSupplementOrder = JSON.parse(
          JSON.stringify(response.data.creditSupplementOrder)
        );
        this.dsData = JSON.parse(
          JSON.stringify(response.data.creditSupplementOrder)
        );
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public onChangeF(id) {
    this.selectedCompany = id
      ? this.creditAgencies.filter((e) => e._id === id)[0]
      : null;
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public mounted() {
    this.getCreditSupplimentOrder();
    this.getCreditAgenciesList();
  }
}
