var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mb-2 white-card loan-process-height-249 d-flex flex-column"},[_c('div',{staticClass:"loan-process-heading"},[_c('div',{staticClass:"col-lg-12 d-flex justify-content-between"},[_c('p',{staticClass:"heading mb-0"},[_vm._v("VOD Order")]),(_vm.notifyBroker)?_c('div',[_c('CommonNotifyOnRejection',{ref:"refNotify",attrs:{"loanTxnId":_vm.$route.query.id},on:{"refresh-task":function($event){return _vm.isBrokerNotifiedForRejectedDocs()}}})],1):_vm._e()])]),_c('div',{staticClass:"col-lg-12 flex-1 height_100 pb-2"},[_c('div',{staticClass:"form-row min_293 flex-column m-0"},[_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"col-lg-12 pt-2 pb-3 pl-0 pr-0"},[_c('div',{staticClass:"col-12 p-0 mb-3"},[_c('div',{staticClass:"form-group d-flex align-items-center"},[_c('div',{staticClass:"file-upload mt-1 d-flex overflow_hidden"},[_c('label',{staticClass:"file-upload-label mb-0",attrs:{"for":"upload"}},[_c('i',{staticClass:"fa fa-upload mr-1"}),_vm._v(" "+_vm._s(_vm.docLoader ? "Uploading..." : "Upload Borrower authorization"))]),_c('input',{staticClass:"file-upload-input",attrs:{"id":"upload","type":"file","name":"file-upload","disabled":_vm.vodData.isCompleted},on:{"change":function($event){_vm.uploadFile($event);

                      _vm.error = false;}}})]),(_vm.docsDetails)?_c('div',[(!_vm.docsDetails.docApproved && !_vm.docsDetails.comment)?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"fa fa-exclamation-circle color-red ml-2",attrs:{"title":"Not Approved"}}):_vm._e(),(!_vm.docsDetails.docApproved && _vm.docsDetails.comment)?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"fa fa-exclamation-circle ml-2",staticStyle:{"color":"orange"},attrs:{"title":_vm.docsDetails.comment}}):_vm._e(),(_vm.docsDetails.docApproved)?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"fa fa-check-circle-o text__blue ml-2",attrs:{"title":"Approved"}}):_vm._e(),_c('i',{staticClass:"ri-file-text-line ml-2 text__blue cursor-pointer",on:{"click":function($event){_vm.isRejected = false;
                      _vm.isAccepted = true;
                      _vm.viewDocument(_vm.docsDetails);}}})]):_vm._e()])]),_vm._l((_vm.vodData.creditorInfo),function(vod,i){return _c('div',{key:i,staticClass:"form-row"},[_c('div',{staticClass:"col-lg-12"},[_c('label',{staticClass:"heading bold text__blue mb-0"},[_vm._v(_vm._s(vod.institutionName)+" "+_vm._s(vod.accountNumber))])]),_c('div',{staticClass:"col-lg-3 col-md-6 col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label"},[_vm._v("Select method to send VOD")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(vod.option),expression:"vod.option"}],staticClass:"custom-select login-input",class:[
                      _vm.vodData.isCompleted
                        ? 'custom-select login-input cursor-pointer cursor-not-allow'
                        : 'custom-select login-input cursor-pointer',
                    ],attrs:{"name":"Select Method","disabled":_vm.vodData.isCompleted},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(vod, "option", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"selected":""},domProps:{"value":null}},[_vm._v("Choose Type")]),_c('option',{attrs:{"value":"email"}},[_vm._v("Email")]),_c('option',{attrs:{"value":"fax"}},[_vm._v("Fax")])])])]),_c('div',{staticClass:"col-lg-3 col-md-6 col-12"},[(vod.option == 'email')?_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"label"},[_vm._v("Type Company Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(vod.email),expression:"vod.email"}],staticClass:"login-input",class:[
                        _vm.vodData.isCompleted
                          ? 'login-input cursor-pointer cursor-not-allow'
                          : 'login-input cursor-pointer',
                      ],attrs:{"type":"text","name":"Email","disabled":_vm.vodData.isCompleted},domProps:{"value":(vod.email)},on:{"change":function($event){errors = false},"input":function($event){if($event.target.composing)return;_vm.$set(vod, "email", $event.target.value)}}}),(errors)?_c('span',{staticClass:"login-error show"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1):(vod.option == 'fax')?_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"rules":"length:12"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"label"},[_vm._v("Type Company Fax")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(vod.fax),expression:"vod.fax"},{name:"mask",rawName:"v-mask",value:('###-###-####'),expression:"'###-###-####'"}],staticClass:"login-input",attrs:{"type":"text","name":"Fax"},domProps:{"value":(vod.fax)},on:{"change":function($event){errors = false},"input":function($event){if($event.target.composing)return;_vm.$set(vod, "fax", $event.target.value)}}}),(errors)?_c('span',{staticClass:"login-error show"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1):_vm._e()]),_c('div',{staticClass:"mt-1"},[_c('div',[(vod.option)?_c('button',{staticClass:"tmpco-btn tmpco-btn__default ml-2 mt_4_media",class:[
                      _vm.vodData.isCompleted || _vm.isTaskDisabled
                        ? 'btn-upload cursor-pointer cursor-not-allow'
                        : 'btn-upload cursor-pointer',
                    ],attrs:{"type":"button","disabled":_vm.vodData.isCompleted || _vm.isTaskDisabled},on:{"click":function($event){_vm.$modal.show('confirmationModal');
                      _vm.selectedCreditor = vod.refId;}}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Re Order ")]):_vm._e()])])])})],2)]),_c('hr',{staticClass:"loan-process-border-top"}),_c('div',{staticClass:"d-flex justify-content-end form-group align-items-center-768 flex_767"},[(_vm.errors)?_c('span',{staticClass:"login-error show pr-2 show_768"},[_vm._v(_vm._s(_vm.msg))]):_vm._e(),_c('div',[_c('button',{staticClass:"tmpco-btn tmpco-btn__default-blue mr-2",class:[
                _vm.vodData.isCompleted
                  ? 'btn-upload cursor-pointer cursor-not-allow'
                  : 'btn-upload cursor-pointer',
              ],attrs:{"type":"button","disabled":_vm.vodData.isCompleted},on:{"click":function($event){_vm.isComplete = false;
                _vm.saveVodOrder();}}},[_vm._v(" Save ")]),_c('button',{staticClass:"tmpco-btn tmpco-btn__theme",class:[
                _vm.vodData.isCompleted
                  ? 'btn-upload cursor-pointer cursor-not-allow'
                  : 'btn-upload cursor-pointer',
              ],attrs:{"type":"submit","disabled":_vm.vodData.isCompleted},on:{"click":function($event){return _vm.$modal.show('VODOrderConfirmationModal')}}},[_vm._v(" "+_vm._s(_vm.vodData.isCompleted ? "Completed" : "Complete")+" ")])]),_c('div',{staticClass:"show_767"},[(_vm.errors)?_c('span',{staticClass:"login-error show pr-2"},[_vm._v(_vm._s(_vm.msg))]):_vm._e()])])])]),_c('modal',{staticClass:"modal",attrs:{"centered":"","name":"VODOrderConfirmationModal","hide-footer":true}},[_c('div',{staticClass:"modal--md-regular"},[_c('div',{staticClass:"tmpco-modal__content"},[_c('div',{staticClass:"tmpco-modal__header"},[_c('p',{staticClass:"tmpco-modal__title"},[_vm._v("VOD Order")]),_c('button',{staticClass:"tmpco-modal__close",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":function($event){return _vm.$modal.hide('VODOrderConfirmationModal')}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"tmpco-modal__body"},[_c('div',{staticClass:"col-lg-12 col-xl-12"},[_c('p',{staticClass:"mt-3 mb-3 content-modal"},[_vm._v(" Are you sure you want to complete? ")])])]),_c('div',{staticClass:"tmpco-modal__footer"},[_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-default mr-2",on:{"click":function($event){return _vm.$modal.hide('VODOrderConfirmationModal')}}},[_vm._v(" No ")]),_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-theme",attrs:{"type":"button"},on:{"click":function($event){_vm.isComplete = true;
                _vm.saveVodOrder();
                _vm.$modal.hide('VODOrderConfirmationModal');}}},[_vm._v(" Yes ")])])])])]),_c('modal',{staticClass:"modal",attrs:{"centered":"","name":"confirmationModal","hide-footer":true}},[_c('div',{staticClass:"modal--md-regular"},[_c('div',{staticClass:"tmpco-modal__content"},[_c('div',{staticClass:"tmpco-modal__header"},[_c('h4',{staticClass:"tmpco-modal__title"},[_vm._v("Re Order")]),_c('button',{staticClass:"tmpco-modal__close",attrs:{"type":"button","aria-label":"tmpco-modal__close"},on:{"click":function($event){_vm.$modal.hide('confirmationModal');
                _vm.selectedCreditor = null;}}},[_vm._v(" × ")])]),_c('div',{staticClass:"tmpco-modal__body"},[_c('div',{staticClass:"col-lg-12 col-xl-12"},[_c('p',{staticClass:"mt-3 mb-3 content-modal"},[_vm._v(" Are you sure you want to Re Order? ")])])]),_c('div',{staticClass:"tmpco-modal__footer"},[_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-theme mr-2",on:{"click":function($event){_vm.resetCreditorData();
                _vm.$modal.hide('confirmationModal');}}},[_vm._v(" Yes ")]),_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-default",on:{"click":function($event){_vm.$modal.hide('confirmationModal');
                _vm.selectedCreditor = null;}}},[_vm._v(" No ")])])])])]),_c('ViewDocument',{ref:"ref",on:{"call-mount-funtion":function($event){_vm.isBrokerNotifiedForRejectedDocs();
        _vm.getBorrowerAuthorizationrDocument();}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }