
import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../../../config";
import { authHeader } from "../../../services/auth";
// import CommonNotifyOnRejection from "../../../common-components/CommonNotifyOnRejection.vue";
import ViewDocument from "../../../common-components/ViewDocument.vue";

@Component({
  components: {
    ViewDocument,
    // CommonNotifyOnRejection,
  },
})
export default class CreditSupplementReceived extends Vue {
  /* eslint-disable */
  @Prop()
  public taskId: any;
  public loanId: any = this.$route.query.id;
  public creditSupplementReceived: any = {};
  public error: any = {
    show: false,
    msg: null,
  };
  @Prop()
  public isTaskDisabled: any;
  /**
   * Taking dsData variable for checking form data is changed or not only for comaprison
   */
  public dsData: any = {};
  /**************************************************************** */

  public docLoader = false;
  public isCompleted = false;
  @Prop()
  public isLoanInactive: any;
  public isOrderPlacedOnce = false;
  // public notifyBroker = false;

  public async uploadReport(event) {
    this.docLoader = true;
    try {
      if (this.isTaskDisabled) {
        this.docLoader = false;
        this.$snotify.info("Task is not assigned to you.");
        return;
      }
      if (event.target.files.length <= 0) return;
      const formData: any = new FormData();
      Array.from(event.target.files).forEach((element) => {
        formData.append("file", element);
      });
      formData.append("loanId", this.loanId);
      formData.append("taskId", this.taskId);
      const response = await Axios.post(
        BASE_API_URL + "processor/uploadCreditSupplimentReport",
        formData,
        { headers: authHeader() }
      );
      if (response.status === 201) this.$snotify.success(response.data.message);
      this.creditSupplementReceived.loanChecklistData =
        response.data.loanChecklistData;
    } catch (error) {
      console.log(error);
    }
    this.docLoader = false;
  }

  public async checkSaveValidation() {
    try {
      if (
        !this.creditSupplementReceived.iscreditSupplementRecieved &&
        this.creditSupplementReceived.iscreditSupplementRecieved ==
          this.dsData.iscreditSupplementRecieved
      )
        return true;
      else return false;
    } catch (error) {
      console.log(error);
    }
  }

  public async saveCreditSupplimentRecievedTask(isComplete) {
    if(this.isOrderPlacedOnce) return
    this.callLoader(true, null);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        await this.getCreditSupplimentRecieved();
        return;
      }
      if (!isComplete) {
        let notValid1 = await this.checkSaveValidation();
        if (notValid1) {
          this.callLoader(false, null);
          this.$snotify.info("No Changes");
          return;
        }
      }
      if (
        isComplete &&
        !this.creditSupplementReceived.iscreditSupplementRecieved
      ) {
        this.error.show = true;
        this.error.msg = "All fields are Required.";
        this.callLoader(false, null);
        return;
      }
      if (isComplete && !this.creditSupplementReceived.loanChecklistData) {
        this.error.show = true;
        this.error.msg = "Upload Credit Supplement Report";
        this.callLoader(false, null);
        return;
      }
    if(isComplete) this.isOrderPlacedOnce=true;
      const response = await Axios.post(
        `${BASE_API_URL}processor/saveCreditSupplimentRecievedTask`,
        {
          creditSupplementReceived: this.creditSupplementReceived,
          taskId: this.taskId,
          loanId: this.$route.query.id,
          isComplete: isComplete,
          formData: this.dsData,
        },
        {
          headers: authHeader(),
        }
      );

      if (!isComplete) {
        this.$snotify.success(
          "Credit Supplement Received Information has been saved"
        );
      }

      if (response.status === 201 && isComplete) {
        this.$snotify.success(response.data.msg);
        this.$emit("refresh-task-list", true);
      }
      await this.getCreditSupplimentRecieved();
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public async getCreditSupplimentRecieved() {
    this.callLoader(true, null);
    try {
      const response = await Axios.get(
        BASE_API_URL +
          "processor/getCreditSupplimentRecieved/" +
          this.$route.query.id +
          "/" +
          this.taskId,
        {
          headers: authHeader(),
        }
      );

      if (response.status === 201) {
        this.isCompleted = response.data.isCompleted;
        this.creditSupplementReceived = JSON.parse(
          JSON.stringify(response.data.creditSupplementReceived)
        );
        this.dsData = JSON.parse(
          JSON.stringify(response.data.creditSupplementReceived)
        );
        this.creditSupplementReceived.loanChecklistData =
          response.data.loanChecklistData;
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async viewDocument(document) {
    try {
      document.loanId = this.$route.query.id;
      this.$refs.ref["showFile"](document);
    } catch (error) {
      console.log(error);
    }
  }

  public mounted() {
    this.getCreditSupplimentRecieved();
  }
}
