
import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL, EventBus } from "../../config";
import { authHeader } from "../../services/auth";
import ViewDocument from "../ViewDocument.vue";
import { DOCUMENT_CATEGORY } from "../../constants/constants";
import moment from "moment";
import pdf from "vue-pdf";
@Component({
  components: {
    ViewDocument,
    pdf,
  },
})
export default class ConditionDocs extends Vue {
  /* eslint-disable */
  @Prop()
  public documentValidation;
  public documents: any = [];
  public loanId: any = this.$route.query.id;
  public files = [];
  public isValid: any = false;
  public loanDetails: any = null;
  public hasSubmit = false;
  public docId = null;
  public documentCategory = DOCUMENT_CATEGORY;
  public document: any = {};
  public showCriticalBtn = false;
  public fileToDisplay: any = null;
  public fileData: any = [];
  public originalFilename = null;
  public fileType: any = null;
  public selectedDocumentId: any = "";
  public numPages = 0;
  public downloadFileContent: any = null;
  public page = 1;
  public rotate = 0;
  public loadedRatio = 0;
  public isRejected = false;
  public isAccepted = false;
  public isChecklist = true;
  public docCategory = "Condition";

  public toBeUploadedBy(uploadedBy) {
    if (uploadedBy.includes(2)) {
      return "Broker";
    }
    if (uploadedBy.includes(4)) {
      return "Borrower";
    }
    if (uploadedBy.includes(3)) {
      return "Sr. Processor";
    }
    if (uploadedBy.includes(0)) {
      return "Jr. Processor";
    }
  }
  public async getDropDocument(event, document) {
    try {
      let droppedFiles = event.dataTransfer.files;
      if (droppedFiles.length == 0) return;
      else await this.uploadDocument(droppedFiles, document);
    } catch (error) {
      console.log(error);
    }
  }

  public async getSelectedDocument(event, document) {
    try {
      let files = event.target.files;
      if (files.length == 0) return;
      else await this.uploadDocument(files, document);
    } catch (error) {
      console.log(error);
    }
  }

  public async getConditionDocuments() {
    try {
      this.callLoader(true, null);
      let response = await Axios.get(
        BASE_API_URL +
          "loanChecklist/getConditionDocuments/" +
          this.$route.query.id,
        {
          headers: authHeader(),
        }
      );

      this.documents = response.data.documents;
      this.documents = this.documents.map((ele) => {
        let msg = "";
        if (ele.allRejectedComments && ele.allRejectedComments.length > 0) {
          for (let j = 0; j < ele.allRejectedComments.length; j++) {
            const e = ele.allRejectedComments[j];
            msg += `${e.comment} ${
              e.acceptedOrRejectedOn
                ? moment(e.acceptedOrRejectedOn).format("MM/DD/YYYY, h:mm:ss a")
                : ""
            } -${e.name ? e.name : ""} *** `;
          }
        }
        ele["msgString"] = msg;

        let msg2 = "";
        if (ele.allAcceptedData && ele.allAcceptedData.length > 0) {
          for (let j = 0; j < ele.allAcceptedData.length; j++) {
            const e = ele.allAcceptedData[j];
            msg2 += `${
              e.acceptedOrRejectedOn
                ? moment(e.acceptedOrRejectedOn).format("MM/DD/YYYY, h:mm:ss a")
                : ""
            } -${e.name ? e.name : ""} *** `;
          }
        }
        ele["msgString1"] = msg2;
        return ele;
      });

      this.loanDetails = response.data.loanDetails;
      if (this.$store.state.sessionObj.userType === 3)
        EventBus.$emit("brokerNotifed");
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async viewDocument(document) {
    try {
      await this.$refs.ref["showFile"]({
        documentId: document._id,
        loanId: this.$route.query.id,
        docName: document.name,
        docCategory: document.category,
        isComplete: document.isComplete,
        borrowerName: document.borrowerName,
        isRejected: this.isRejected,
        isAccepted: this.isAccepted,
        isChecklist: this.isChecklist,
      });
    } catch (error) {
      console.log(error);
    }
  }

  public async uploadDocument(files, document) {
    document.isUploading = true;
    try {
      let formData = new FormData();
      this.docId = document._id;
      formData.append("loanId", this.loanId);
      formData.append("documentId", document._id);
      formData.append("Category", this.docCategory);
      if (document.borrowerUserId)
        formData.append("borrowerUserId", document.borrowerUserId);
      for (let i = 0; i < files.length; i++) {
        const element = files[i];
        formData.append("file", element);
      }

      let response = await Axios.post(
        BASE_API_URL + "loanChecklist/uploadDocument",
        formData,
        { headers: authHeader() }
      );

      if (response.status == 201) {
        this.$snotify.success("Document has been uploaded");
        let doc = response.data.checklist.find((c) => c._id == document._id);
        document["docStatus"] = doc.docStatus;
        document["docFiles"] = doc.docFiles;
        document["isComplete"] = doc.isComplete;
        EventBus.$emit("processorNotifed");
        document["docUploadedBy"] = response.data.uploadedBy;
        document["acceptedByComment"] = response.data.acceptedByComment;
        await this.getConditionDocuments();
        await this.conditionToShowCriticalDoCBtn();
        if (this.$route.query.name == "disclosure" && this.hasSubmit) {
          await this.validateDocuments();
          EventBus.$emit("validation");
        }
      }
    } catch (error) {
      console.log(error);
    }
    document.isUploading = false;
  }

  public getDate(document) {
    let files = document.docFiles[document.docFiles.length - 1].files;
    document.lastUpdatedOn = files[files.length - 1].uploadedOn
      ? files[files.length - 1].uploadedOn
      : null;
    return document;
  }

  public async submit() {
    this.hasSubmit = true;
    this.isValid = this.validateDocuments();
    if (!this.isValid) {
      this.$snotify.warning("Please upload mandatory documents");
      return;
    }
    this.$emit("nextTab");
    if (this.$route.query.name == "disclosure") {
      EventBus.$emit("validation");
    }
  }

  public validateDocuments() {
    let hasDocToUpload: any;
    if (this.loanDetails.idsHandledByTMPCO) {
      this.documents.forEach((d: any) => {
        Vue.set(
          d,
          "showValidation",
          d.source == "Broker Disclosure" ? true : false
        );
      });
      hasDocToUpload = this.documents.find(
        (d: any) =>
          d.source == "Broker Disclosure" && d.docStatus === "Required"
      );
      if (this.$route.query.name === "disclosure") {
        this.documents.forEach((d: any) => {
          Vue.set(
            d,
            "showValidation",
            d.docStatus == "Required" ? true : false
          );
        });
        hasDocToUpload = this.documents.find(
          (d: any) => d.docStatus === "Required"
        );
      }
    } else {
      this.documents.forEach((d: any) => {
        Vue.set(d, "showValidation", d.docStatus == "Required" ? true : false);
      });
      hasDocToUpload = this.documents.find(
        (d: any) => d.docStatus == "Required"
      );
    }
    return hasDocToUpload ? false : true;
  }

  public async updateLoanChecklist(documentId, isComplete) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "loanChecklist/updateLoanChecklist",
        {
          loanId: this.loanId,
          documentId: documentId,
          isComplete: isComplete,
        },
        {
          headers: authHeader(),
        }
      );
      if (response.data == 200) {
        this.$snotify.success("Checklist has been Updated");
      }
    } catch (error) {
      console.log(error);
    }
  }

  public isProcessor() {
    return this.loanDetails.userType == 3 ? true : false;
  }

  public selectedDoc(doc) {
    this.selectedDocumentId = doc;
  }

  public getDocName(document) {
    return document.name.replace(`<p>\g`, "").replace(`</p>\g`, "");
  }

  public isUploadedByProcessor(document) {
    let isUploadedByProcessor = false;
    if (document.hasOwnProperty("toBeUploadedBy"))
      isUploadedByProcessor = document.toBeUploadedBy.find((u: any) => u == 3);
    return this.$store.state.sessionObj.userType != 3 && isUploadedByProcessor
      ? true
      : false;
  }

  public async conditionToShowCriticalDoCBtn() {
    try {
      let res = await Axios.get(
        BASE_API_URL +
          "loanChecklist/conditionToShowCriticalDoCBtn/" +
          this.loanId,
        {
          headers: authHeader(),
        }
      );

      if (res.status === 201) {
        this.showCriticalBtn = res.data.show;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async getFile(document) {
    try {
      this.callLoader(true, null);
      document.loanId = this.loanId;
      const response = await Axios.post(
        `${BASE_API_URL}loanChecklist/getSelectedAttachedDocument`,
        document,
        { headers: authHeader() }
      );
      this.fileData = response.data;
      this.fileType = this.fileData[0].fileType;
      this.downloadFileContent = this.fileData[0].image;
      if (this.fileType && this.fileType.toLowerCase() == "pdf") {
        this.fileToDisplay = pdf.createLoadingTask(this.fileData[0].image);
        this.fileToDisplay.promise.then((pdf) => {
          this.numPages = pdf.numPages;
        });
      } else this.fileToDisplay = this.fileData[0].image;
      // this.fileToDisplay =
      //   this.fileData.length > 0 ? this.fileData[0].image : null;
      this.originalFilename =
        this.fileData.length > 0 ? this.fileData[0].path.originalName : null;
      // this.fileType =
      //   this.fileData.length > 0 ? this.fileData[0].path.split(".").pop() : null;
      this.$modal.show("showAttachmentModal");
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async deleteAddedDoc() {
    try {
      this.callLoader(true, null);
      let response = await Axios.post(
        BASE_API_URL + "loanChecklist/deleteAdditionalDoc",
        {
          loanId: this.loanId,
          additionDocId: this.selectedDocumentId,
        },
        {
          headers: authHeader(),
        }
      );
      if (response.data) {
        this.$snotify.success(response.data.msg);
        this.$modal.hide("deleteConfirmationModal");
        this.getConditionDocuments();
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public async mounted() {
    await this.getConditionDocuments();
    this.conditionToShowCriticalDoCBtn();

    if (this.documentValidation) {
      this.validateDocuments();
    }
    EventBus.$on("refreshDocs", async () => {
      await this.getConditionDocuments();
    });
  }
  /* eslint-disable */
}
