var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mb-2 white-card white-card loan-process-height-249"},[_vm._m(0),_c('div',{staticClass:"col-lg-12 pt-3 pb-3 flex-1 height_100"},[_c('div',{staticClass:"form-row min_293 flex-column m-0"},[_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"custom-checkbox mb-1 d-flex align-items-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.cdReceived.isCdReceived),expression:"cdReceived.isCdReceived"}],class:[
                _vm.isTaskCompleted
                ? 'custom-checkbox-input cursor-pointer cursor-not-allow'
                : 'custom-checkbox-input cursor-pointer',
                ],attrs:{"type":"checkbox","disabled":_vm.isTaskCompleted,"id":"isCdReceived"},domProps:{"checked":Array.isArray(_vm.cdReceived.isCdReceived)?_vm._i(_vm.cdReceived.isCdReceived,null)>-1:(_vm.cdReceived.isCdReceived)},on:{"change":[function($event){var $$a=_vm.cdReceived.isCdReceived,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.cdReceived, "isCdReceived", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.cdReceived, "isCdReceived", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.cdReceived, "isCdReceived", $$c)}},function($event){_vm.error = { show: false, msg: null }}]}}),_c('label',{staticClass:"label",staticStyle:{"color":"#2c3e50"},attrs:{"for":"isCdReceived"}},[_vm._v(" CD Received ")]),_c('div',{staticClass:"indicator"})]),_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"file-upload mt-2"},[_c('label',{staticClass:"file-upload-label mb-0",attrs:{"for":"upload"}},[_c('i',{staticClass:"fa fa-upload mr-1",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.imageLoader ? "Uploading..." : "Upload CD Report"))]),_c('input',{staticClass:"file-upload-input",attrs:{"id":"upload","type":"file","name":"file-upload","disabled":_vm.isTaskCompleted,"multiple":""},on:{"change":function($event){_vm.uploadReport($event); _vm.error = { show: false, msg: null };}}})]),(_vm.doc)?_c('i',{staticClass:"ri-file-text-line cursor-pointer text__blue ml-2",attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.viewDocument(_vm.doc)}}}):_vm._e()])]),_c('hr',{staticClass:"loan-process-border-top"}),_c('div',{staticClass:"d-flex align-items-center justify-content-end form-group"},[(_vm.error.show)?_c('span',{staticClass:"login-error show mr-2"},[_vm._v(_vm._s(_vm.error.msg))]):_vm._e(),_c('button',{staticClass:"tmpco-btn tmpco-btn__default-blue mr-2",class:[
          _vm.isTaskCompleted
          ? 'btn-upload cursor-pointer cursor-not-allow'
          : 'btn-upload cursor-pointer',
          ],attrs:{"type":"button","disabled":_vm.isTaskCompleted},on:{"click":function($event){return _vm.saveCDReceived(false)}}},[_vm._v(" Save ")]),_c('button',{staticClass:"tmpco-btn tmpco-btn__theme",class:[
         _vm.isTaskCompleted
        ? 'btn-upload cursor-pointer cursor-not-allow'
        : 'btn-upload cursor-pointer',
        ],attrs:{"type":"button","disabled":_vm.isTaskCompleted},on:{"click":function($event){return _vm.$modal.show('cdReceivedConfirmationModal')}}},[_vm._v(" "+_vm._s(_vm.isTaskCompleted ? "Completed" : "Complete")+" ")])]),_c('modal',{staticClass:"modal",attrs:{"centered":"","name":"cdReceivedConfirmationModal","hide-footer":true}},[_c('div',{staticClass:"modal--md-regular"},[_c('div',{staticClass:"tmpco-modal__content"},[_c('div',{staticClass:"tmpco-modal__header"},[_c('p',{staticClass:"tmpco-modal__title"},[_vm._v("CD Received")]),_c('button',{staticClass:"tmpco-modal__close",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":function($event){return _vm.$modal.hide('cdReceivedConfirmationModal');}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"tmpco-modal__body"},[_c('div',{staticClass:"col-lg-12 col-xl-12"},[_c('p',{staticClass:"mt-3 mb-3 content-modal"},[_vm._v(" Are you sure you want to complete? ")])])]),_c('div',{staticClass:"tmpco-modal__footer"},[_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-default mr-2",on:{"click":function($event){return _vm.$modal.hide('cdReceivedConfirmationModal');}}},[_vm._v("No")]),_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-theme",attrs:{"type":"button"},on:{"click":function($event){_vm.saveCDReceived(true);_vm.$modal.hide('cdReceivedConfirmationModal')}}},[_vm._v("Yes")])])])])])],1)]),_c('ViewDocument',{ref:"ref",on:{"call-mount-funtion":function($event){return _vm.getCDReceived()}}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"loan-process-heading"},[_c('div',{staticClass:"col-lg-12"},[_c('p',{staticClass:"heading mb-0"},[_vm._v("CD Received")])])])
}]

export { render, staticRenderFns }