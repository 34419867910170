
  import { Component, Vue, Prop } from "vue-property-decorator";
  import Axios from "axios";
  import { BASE_API_URL } from "../../../config";
  import { authHeader } from "../../../services/auth";
  import ViewDocument from "../../../common-components/ViewDocument.vue";
  import { PayoffReceivedChecklist } from "../../../models/workflow/payoffReceived.model";
  import datepicker from "vue2-datepicker";
  import "vue2-datepicker/index.css";

  @Component({
    components: {
      ViewDocument,
      datepicker,
    },
  })
  export default class PayOffReceived extends Vue {
    /* eslint-disable */
    public payOffReceived: any = new PayoffReceivedChecklist();
    @Prop()
    public taskId: any;
    public loanId: any = this.$route.query.id;
    public isComplete = false;
    public isOrderPlacedOnce = false;
    public error: any = {
      show: false,
      msg: null,
    };
  // public notifyBroker = false;
  public docLoader = false;
  // @Prop()
  // public isLoanInactive: any;
  public isTaskCompleted = false;
  /**
   * Taking dsData variable for checking form data is changed or not only for comaprison
   */
   public dsData: any = {};

   /**************************************************************** */

   public async uploadPayOffReport(event) {
    this.docLoader = true;
    try {
      if (event.target.files.length <= 0) return;
      const formData: any = new FormData();
      Array.from(event.target.files).forEach((element) => {
        formData.append("file", element);
      });

      formData.append("loanId", this.loanId);
      formData.append("taskId", this.taskId);

      const response = await Axios.post(
        BASE_API_URL + "processor/uploadPayOffReport",
        formData,
        { headers: authHeader() }
        );

      if (response.status === 201) this.$snotify.success(response.data.message);
      this.payOffReceived.loanChecklistData = response.data.loanChecklistData;
      this.error.msg = null;
      this.error.show = false
      this.getPayOffReceived();
    } catch (error) {
      console.log(error);
    }
    this.docLoader = false;
  }

  // public async checkSaveValidation() {
  //   try {
  //     if (!this.payOffReceived.isPayOffReceived && this.payOffReceived.isPayOffReceived == this.dsData.isPayOffReceived
  //       )
  //       return true;
  //     else return false;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  public async savePayOffReceived() {
  if(this.isOrderPlacedOnce) return
    this.callLoader(true, null);
    try {
      //  if (!this.isComplete)  {
      //   let notValid1 = await this.checkSaveValidation();
      //   if (notValid1) {
      //     this.callLoader(false, null);
      //     this.$snotify.info('No Changes')
      //     return;
      //   }
      // }
      if (this.isComplete && this.payOffReceived.isPayOffUpdateOn1003.mode== 'No') {
        this.error.show = true;
        this.error.msg = "Please select yes for the Question No. 5";
        this.callLoader(false, null);
        return;
      }

      if (this.isComplete && !this.payOffReceived.isPayOffReceived) {
        this.error.show = true;
        this.error.msg = "All Fields are Required.";
        this.callLoader(false, null);
        return;
      }
      if (this.isComplete && !this.payOffReceived.loanChecklistData) {
        this.error.show = true;
        this.error.msg = "Upload PayOff Demand.";
        this.callLoader(false, null);
        return;
      }
      if (
        this.isComplete &&
        this.payOffReceived.loanChecklistData.revisedDoc &&
        this.payOffReceived.loanChecklistData.docLength < 2
        ) {
        this.error.show = true;
      this.error.msg = "Upload Pay Off Updated Report.";
      await this.callLoader(false, null);
      return;
    }
    if(this.isComplete)
      this.isOrderPlacedOnce = true
    const response = await Axios.post(
      `${BASE_API_URL}processor/savePayOffReceived`,
      {
        payOffReceived: this.payOffReceived,
        taskId: this.taskId,
        loanId: this.$route.query.id,
        isComplete: this.isComplete,
      },
      {
        headers: authHeader(),
      }
      );
      // if (!this.isComplete) {
      //   this.$snotify.success("Pay Off Received Information has been saved");
      // }

      if (response.status === 201 && this.isComplete)
        this.$snotify.success(response.data.msg);
        this.error.show = false;
         this.error.msg = null;
      if (this.isComplete) this.$emit("refresh-task-list", true);
      await this.getPayOffReceived();
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async getPayOffReceived() {
    this.callLoader(true, null);
    try {
      const response = await Axios.get(
        BASE_API_URL +
        "processor/getPayOffReceived/" +
        this.$route.query.id +
        "/" +
        this.taskId,
        {
          headers: authHeader(),
        }
        );

      if (response.status === 201) {
        this.payOffReceived = response.data.payOffReceived
        ? JSON.parse(JSON.stringify(response.data.payOffReceived))
        : new PayoffReceivedChecklist();
        this.dsData = JSON.parse(JSON.stringify(response.data.payOffReceived));
        this.payOffReceived.loanChecklistData = response.data.loanChecklistData;
        this.isTaskCompleted = response.data.isCompleted;
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async viewDocument(document) {
    try {
      document.loanId = this.$route.query.id;
      this.$refs.ref["showFile"](document);
    } catch (error) {
      console.log(error);
    }
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }
  public showUploadRevisedReportDiv() {
    let show = false;
    let value;
    let key;
    for ([key, value] of Object.entries(this.payOffReceived)) {
      if (
        value &&
        Object.keys(value).length > 0 &&
        key != "createdBy" &&
        key != "createdOn" &&
        key != "loanChecklistData" &&
        key != "updatedBy" &&
        key != "updatedOn" &&
        key != "isPayOffUpdateOn1003" &&
        key != "areThereLateFees" &&
        key != "isSecondUnpaidPrincipalBalanceReflectedOnPayOff"
        ) {
        if (value.mode == "No" && value.revisionRequestedOn) {
          show = true;
          this.payOffReceived.loanChecklistData.revisedDoc = true;
        }
      }
    }
    return show;
  }

  public mounted() {
    this.getPayOffReceived();
  }
}
/* eslint-disable */
