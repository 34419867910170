
import Axios from "axios";
import { Component, Vue, Prop } from "vue-property-decorator";
import { BASE_API_URL, EventBus } from "../../../config";
import { authHeader } from "../../../services/auth";
import {
  PRIOR_PROPERTY_USAGE_TYPE,
  PRIOR_PROPERTY_TITLE_TYPE,
} from "../../../constants/constants";
@Component
export default class Declarations extends Vue {
  /* eslint-disable */
  public borrowerInfoList: any = [];
  public borrowerDataList: any = [];
  public priorPropertyUsageType = PRIOR_PROPERTY_USAGE_TYPE;
  public priorPropertyTitleType = PRIOR_PROPERTY_TITLE_TYPE;
  public borrowerInfo = [];
  public count = 0;
  public changedValues: any = [];
  @Prop()
  public validationData: any;
  @Prop()
  public isLoanInactive: any;

  public hasAnychangedValues() {
    return this.changedValues;
  }

  public async getDeclarationInfo() {
    try {
      this.changedValues = [];
      this.callLoader(true, null);
      const res = await Axios.get(
        BASE_API_URL + "urla/getDeclarationInfo/" + this.$route.query.id,
        {
          headers: authHeader(),
        }
      );
      this.count = 0;
      if (res.data.declarationInfo.length > 0) {
        for (let i = 0; i < res.data.declarationInfo.length; i++) {
          res.data.declarationInfo[i].closingCost = res.data.declarationInfo[i]
            .closingCost
            ? Number(res.data.declarationInfo[i].closingCost)
            : 0;
        }
      }
      this.borrowerInfoList =
        res.data.declarationInfo.length > 0 ? res.data.declarationInfo : [];
      this.borrowerInfo = res.data.borrowerInfo;
      this.borrowerDataList = JSON.parse(JSON.stringify(this.borrowerInfoList));
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public async updateDeclarationInfo() {
    try {
      const res = await Axios.post(
        BASE_API_URL + "urla/updateDeclarationInfo",
        {
          borrowerInfo: this.borrowerInfoList,
          loanId: this.$route.query.id,
          changedValues: this.changedValues,
        },
        {
          headers: authHeader(),
        }
      );
      if (res.status == 201 && this.count > 0) {
        this.$snotify.success("Declaration Information has been Updated.");
      }
      await this.getDeclarationInfo();
      this.$emit("referesh-tab", true);
      this.validationData ? this.refreshBorrowerTabs() : "";
    } catch (error) {
      console.log(error);
    }
  }

  public async handleChange(currentVal, previousVal, fieldName) {
    if (previousVal !== undefined) {
      const index = this.changedValues.findIndex((c) => c.name === fieldName);
      if (index !== -1) {
        if (JSON.stringify(currentVal) === JSON.stringify(previousVal)) {
          this.changedValues.splice(index, 1);
        } else {
          let data = this.changedValues.find((v) => v.name === fieldName);
          data.from = previousVal;
          data.to = currentVal;
        }
      } else {
        if (JSON.stringify(currentVal) !== JSON.stringify(previousVal))
          this.changedValues.push({
            name: fieldName,
            from: previousVal,
            to: currentVal,
          });
      }
    }
  }

  public async refreshBorrowerTabs() {
    try {
      const response = await Axios.post(
        BASE_API_URL + "urla/refreshBorrowerTabs",
        {
          loanId: this.$route.query.id,
          identifier: "Declaration",
        },
        {
          headers: authHeader(),
        }
      );
      const borIndex = response.data;
      this.borrowerInfoList.forEach((b, i) => {
        const hasExist =
          borIndex.length > 0 ? borIndex.find((index) => index == i) : null;
        Vue.set(b, "showValidation", hasExist != null ? true : false);
      });
    } catch (error) {
      console.log(error);
    }
  }

  public returnName(id) {
    const bor: any = this.borrowerInfo.find((b: any) => b._id == id);
    return bor ? bor.name.firstName + " " + bor.name.lastName : "";
  }

  public checkValidation() {
    this.borrowerInfoList.forEach((d, i) => {
      let hasExist =
        this.validationData.borIndex.length > 0
          ? this.validationData.borIndex.find((index) => index == i)
          : null;
      Vue.set(d, "showValidation", hasExist != null ? true : false);
    });
    this.$emit("call-mount", true);
  }

  public checkValidationForMobile() {
    EventBus.$emit("check-validation", true);
  }

  async mounted() {
    await this.getDeclarationInfo();
    this.validationData ? await this.checkValidation() : "";
  }
}
/* eslint-disable */
