
import { Component, Prop, Vue } from "vue-property-decorator";
import Assets from "./assets/Assets.vue";
import Liabilities from "./liabilities/Liability.vue";
import Declarations from "./declarations/Declarations.vue";
import DemographicInfo from "./demographic-info/DemographicInfo.vue";
import Borrower from "./borrower/Borrower.vue";
import LoanAndProperty from "./loan-and-property/LoanAndProperty.vue";
import RealEstate from "./real-estate/RealEstate.vue";
import LenderInfo from "./lenderInfo/LenderInfo.vue";
import EmploymentAndIncome from "./employment-and-income/EmploymentAndIncome.vue";
import Axios from "axios";
import { BASE_API_URL, EventBus } from "../../config";
import { authHeader } from "../../services/auth";

@Component({
  components: {
    LoanAndProperty,
    Borrower,
    LenderInfo,
    Assets,
    Liabilities,
    Declarations,
    DemographicInfo,
    RealEstate,
    EmploymentAndIncome,
  },
})
export default class Urla extends Vue {
  public tabs = [
    { name: "Loan & Property", componentName: LoanAndProperty },
    { name: "Lender Info", componentName: LenderInfo },
    { name: "Borrower", componentName: Borrower },
    { name: "Employment & Income", componentName: EmploymentAndIncome },
    { name: "Assets", componentName: Assets },
    { name: "Liabilities", componentName: Liabilities },
    { name: "Real Estate", componentName: RealEstate },
    { name: "Declarations", componentName: Declarations },
    { name: "Demographic Info", componentName: DemographicInfo },
  ];
  @Prop()
  public isLoanInactive: any;
  public activeTabIndex = 0;
  /* eslint-disable */
  public tabIndex = 0;
  public urlaTabs: any = [];
  public borIndex = false;
  public hasSubmit = false;

  public async checkUrlaTabsValidations(isProceed) {
    try {
      let response = await Axios.get(
        BASE_API_URL + "urla/checkUrlaTabsValidations/" + this.$route.query.id,
        {
          headers: authHeader(),
        }
      );
      this.urlaTabs = response.data.urlaTabs;
      this.tabs.forEach((t, i) => {
        let index = this.urlaTabs.find((u) => u.tabIndex == i);
        Vue.set(t, "showValidation", index ? true : false);
        Vue.set(t, "validationData", index ? index.validationData : null);
      });
      let hasError = this.tabs.find((t: any) => t.showValidation == true);
      this.hasSubmit = true;
      // !isProceed ? EventBus.$emit("refreshBorTab", true) : "";
      if (isProceed) {
        if (hasError) return;
        else await this.pushDocuments();
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async pushDocuments() {
    try {
      let response = await Axios.get(
        BASE_API_URL +
          "loanChecklist/pushDocumentInDocumentCollection/" +
          this.$route.query.id,
        {
          headers: authHeader(),
        }
      );
      this.tabIndex = 1;
      this.$emit("next-tab", this.tabIndex);
    } catch (error) {
      console.log(error);
    }
  }

  public async callComponent() {
    let tab = this.tabs[this.activeTabIndex];
    const valid: any = this.$refs[tab.name];
    let validate = valid && valid.length > 0 ? await valid[0].validate() : null;
  }

  public showValidation() {
    this.tabs.forEach((t, i) => {
      let index = this.urlaTabs.find((u) => u.tabIndex == i);
      Vue.set(t, "showValidation", index ? true : false);
      Vue.set(t, "validationData", index ? index.validationData : null);
    });
  }

  public async updateUrla(index) {
    if (index != this.activeTabIndex) {
      if (this.tabs[this.activeTabIndex].name == "Loan & Property") {
        await this.$refs.urla[0].compareData();
        let changedValues = await this.$refs.urla[0].hasAnychangedValues();
        if (changedValues.length > 0)
          await this.$refs.urla[0].updateLoanAndProperty();
      } else if (this.tabs[this.activeTabIndex].name == "Lender Info") {
        let changedValues = await this.$refs.urla[0].hasAnychangedValues();
        if (changedValues.length > 0) this.$refs.urla[0].updateLenderInfo();
      } else if (this.tabs[this.activeTabIndex].name == "Borrower") {
        let changedValues = await this.$refs.urla[0].hasAnychangedValues();
        if (changedValues.length > 0) this.$refs.urla[0].updateBorrowerInfo();
      } else if (this.tabs[this.activeTabIndex].name == "Employment & Income") {
        let changedValues = await this.$refs.urla[0].hasAnychangedValues();
        if (changedValues.length > 0) this.$refs.urla[0].saveEmploymentInfo();
      } else if (this.tabs[this.activeTabIndex].name == "Assets") {
        let changedValues = await this.$refs.urla[0].hasAnychangedValues();
        if (changedValues.length > 0) this.$refs.urla[0].saveAndUpdateAssets();
      } else if (this.tabs[this.activeTabIndex].name == "Liabilities") {
        let changedValues = await this.$refs.urla[0].hasAnychangedValues();
        if (changedValues.length > 0)
          this.$refs.urla[0].saveAndUpdateLiabilities();
      } else if (this.tabs[this.activeTabIndex].name == "Real Estate") {
        let changedValues = await this.$refs.urla[0].hasAnychangedValues();
        if (changedValues.length > 0) this.$refs.urla[0].saveRealEstateData();
      } else if (this.tabs[this.activeTabIndex].name == "Declarations") {
        let changedValues = await this.$refs.urla[0].hasAnychangedValues();
        if (changedValues.length > 0)
          this.$refs.urla[0].updateDeclarationInfo();
      } else if (this.tabs[this.activeTabIndex].name == "Demographic Info") {
        let changedValues = await this.$refs.urla[0].hasAnychangedValues();
        if (changedValues.length > 0)
          this.$refs.urla[0].updateDemographicInfo();
      }
    }
    this.activeTabIndex = index;
    if (this.hasSubmit) this.checkUrlaTabsValidations(false);
  }

  public async saveData() {
    if (this.tabs[this.activeTabIndex].name == "Loan & Property") {
      let changedValues = await this.$refs.urla[0].hasAnychangedValues();
      if (changedValues.length > 0)
        await this.$refs.urla[0].updateLoanAndProperty();
    } else if (this.tabs[this.activeTabIndex].name == "Lender Info") {
      let changedValues = await this.$refs.urla[0].hasAnychangedValues();
      if (changedValues.length > 0) this.$refs.urla[0].updateLenderInfo();
    } else if (this.tabs[this.activeTabIndex].name == "Borrower") {
      let changedValues = await this.$refs.urla[0].hasAnychangedValues();
      if (changedValues.length > 0) this.$refs.urla[0].updateBorrowerInfo();
    } else if (this.tabs[this.activeTabIndex].name == "Employment & Income") {
      let changedValues = await this.$refs.urla[0].hasAnychangedValues();
      if (changedValues.length > 0) this.$refs.urla[0].saveEmploymentInfo();
    } else if (this.tabs[this.activeTabIndex].name == "Assets") {
      let changedValues = await this.$refs.urla[0].hasAnychangedValues();
      if (changedValues.length > 0) this.$refs.urla[0].saveAndUpdateAssets();
    } else if (this.tabs[this.activeTabIndex].name == "Liabilities") {
      let changedValues = await this.$refs.urla[0].hasAnychangedValues();
      if (changedValues.length > 0)
        this.$refs.urla[0].saveAndUpdateLiabilities();
    } else if (this.tabs[this.activeTabIndex].name == "Real Estate") {
      let changedValues = await this.$refs.urla[0].hasAnychangedValues();
      if (changedValues.length > 0) this.$refs.urla[0].saveRealEstateData();
    } else if (this.tabs[this.activeTabIndex].name == "Declarations") {
      let changedValues = await this.$refs.urla[0].hasAnychangedValues();
      if (changedValues.length > 0) this.$refs.urla[0].updateDeclarationInfo();
    } else if (this.tabs[this.activeTabIndex].name == "Demographic Info") {
      let changedValues = await this.$refs.urla[0].hasAnychangedValues();
      if (changedValues.length > 0) this.$refs.urla[0].updateDemographicInfo();
    }
  }

  public mounted() {
    EventBus.$on("checkValidationForProcessor", async (data) => {
      this.urlaTabs = data;
      this.hasSubmit = true;
      await this.showValidation();
    });
    EventBus.$on("check-validation", async () => {
      await this.checkUrlaTabsValidations(true);
    });
  }
  /* eslint-disable */
}
