
import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL, EventBus } from "../../../config";
import { authHeader } from "../../../services/auth";
import ReOrderTask from "../../../common-components/ReOrderTask.vue";
import CommonVendorModal from "../../../common-components/CommonVendorModal.vue";
import PdfPreview from "../../../common-components/PDFPreview.vue";
import {
  VerificationOfAgent,
  MasterVendorData,
} from "../../../models/workflow/masterInsurance.model";
@Component({
  components: {
    ReOrderTask,
    // FollowUpTask,
    CommonVendorModal,
    PdfPreview,
  },
})
export default class MasterInsuranceCertificateOrder extends Vue {
  /* eslint-disable */
  @Prop()
  public taskId: any;
  public masterInsuranceCertificateOrder: any = new VerificationOfAgent();
  public identifier = "masterInsuranceCertificate";
  public isEmail = false;
  public error = false;
  public masterVendorList: any = [];
  public vendorDetails: any = new MasterVendorData();
  public msg = "";
  public msg1 = "";
  public agent: any = {};
  // @Prop()
  // public isLoanInactive: any;
  public isLoanCompleted = false;
  public mortgageClause: any = {};
  // public taskCompleted = false;
  public loanId = this.$route.query.id;
  public masterInsuranceAgents: any = [];
  public selectedAgent: any = null;
  public isCompleted = false;
  @Prop()
  public reOrderCount: any;
  public isCredential = false;
  public selectedCompany: any = null;
  public countToSendOnServer: any = null;
  public showHistoryBtn = false;
  public loanNumber: any = {};
  public showReOrderBtn = false;

  public masterOrder: any = {};
  public pathForSaving: any = `./uploads/${this.$route.query.id}/requestforPudInsurance.pdf`;
  public isOrderPlacedOnce = false;
  /**
   * Taking dsData variable for checking form data is changed or not only for comaprison
   */
  public dsData: any = {};
  public tempSelectedAgent: any = null;
  /**************************************************************** */
  @Prop()
  public isTaskDisabled: any;

  public async checkSaveValidation() {
    try {
      let returnV = false;
      if (
        !this.masterInsuranceCertificateOrder
          .isMasterInsuranceCertificateOrder &&
        this.masterInsuranceCertificateOrder
          .isMasterInsuranceCertificateOrder ==
          this.dsData.isMasterInsuranceCertificateOrder &&
        !this.masterInsuranceCertificateOrder.agentInfo.option &&
        this.dsData.agentInfo.option == this.dsData.agentInfo.option
      ) {
        if (!this.selectedAgent && this.selectedAgent == this.tempSelectedAgent)
          returnV = true;
        if (this.masterInsuranceAgents.length == 1 && this.selectedAgent)
          returnV = true;
      }
      return returnV;
    } catch (error) {
      console.log(error);
    }
  }

  public async checkSaveValidationForOther() {
    try {
      let returnV = false;
      if (
        this.masterInsuranceCertificateOrder.agentInfo.option ==
          "otherContact" &&
        this.masterInsuranceCertificateOrder.agentInfo.otherContact.option ==
          null
      ) {
        returnV = true;
      } else if (
        this.masterInsuranceCertificateOrder.agentInfo.option ==
          "otherContact" &&
        this.masterInsuranceCertificateOrder.agentInfo.otherContact.option ==
          "contactEmail" &&
        (this.masterInsuranceCertificateOrder.agentInfo.otherContact
          .contactEmail == null ||
          this.masterInsuranceCertificateOrder.agentInfo.otherContact
            .contactEmail == "")
      ) {
        returnV = true;
      } else if (
        this.masterInsuranceCertificateOrder.agentInfo.option ==
          "otherContact" &&
        this.masterInsuranceCertificateOrder.agentInfo.otherContact.option ==
          "contactEmail" &&
        (this.masterInsuranceCertificateOrder.agentInfo.otherContact
          .firstName == null ||
          this.masterInsuranceCertificateOrder.agentInfo.otherContact
            .lastName == null ||
          this.masterInsuranceCertificateOrder.agentInfo.otherContact
            .companyName == null)
      ) {
        returnV = true;
      }

      return returnV;
    } catch (error) {
      console.log(error);
    }
  }

  public async saveMasterInsuranceCertificateOrder() {
    if (this.isOrderPlacedOnce) return;
    this.callLoader(true, null);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getMasterInsuranceCertificateOrder();
        return;
      }
      if (!this.isCompleted) {
        let notValid1 = await this.checkSaveValidation();
        if (notValid1) {
          this.callLoader(false, null);
          this.$snotify.info("No Changes");
          return;
        }
      }
      if (
        !this.masterInsuranceCertificateOrder
          .isMasterInsuranceCertificateOrder &&
        this.isCompleted
      ) {
        this.error = true;
        this.msg = "All fields are Required.";
        this.callLoader(false, null);
        return;
      }
      if (
        !this.masterInsuranceCertificateOrder.agentInfo.option &&
        this.isCompleted
      ) {
        this.error = true;
        this.msg =
          "Please select atleast one option to send Master Insurance Certificate.";
        this.callLoader(false, null);
        return;
      }

      if (
        (!this.agent.agentContactData || !this.agent.agentData) &&
        this.isCompleted
      ) {
        this.error = true;
        this.msg = "Agent Not Added Yet!";
        this.callLoader(false, null);
        return;
      }
      if (
        (this.isCompleted &&
          this.masterInsuranceCertificateOrder.agentInfo.option == null) ||
        (this.masterInsuranceCertificateOrder.agentInfo.option == "email" &&
          (this.masterInsuranceCertificateOrder.agentInfo.email == null ||
            this.masterInsuranceCertificateOrder.agentInfo.email == "")) ||
        (this.masterInsuranceCertificateOrder.agentInfo.option == "fax" &&
          (this.masterInsuranceCertificateOrder.agentInfo.fax == null ||
            this.masterInsuranceCertificateOrder.agentInfo.fax == ""))
      ) {
        this.error = true;
        this.msg = "All fields are Required.";
        this.callLoader(false, null);
        return;
      }
      if (
        this.isCompleted &&
        this.masterInsuranceCertificateOrder.agentInfo.option == "thirdParty" &&
        !this.masterInsuranceCertificateOrder.agentInfo
          .masterInsuranceCertificateVendorId
      ) {
        this.error = true;
        this.msg = "Choose third Party Vendor";
        this.callLoader(false, null);
        return;
      }

      if (
        this.isCompleted &&
        this.masterInsuranceCertificateOrder.agentInfo.option ==
          "otherContact" &&
        !this.masterInsuranceCertificateOrder.agentInfo.otherContact.option ==
          null
      ) {
        this.error = true;
        this.msg = "Please Fill all required fields";
        this.callLoader(false, null);
        return;
      }
      let notValid2 = await this.checkSaveValidationForOther();
      if (this.isCompleted && notValid2) {
        this.error = true;
        this.msg = "Please Fill all required fields";
        this.callLoader(false, null);
        return;
      }

      let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      let checkvalidEmailData =
        this.masterInsuranceCertificateOrder.agentInfo.option == "email" &&
        !this.masterInsuranceCertificateOrder.agentInfo.email.match(mailformat);
      let checkvalidEmailDataForother =
        this.masterInsuranceCertificateOrder.agentInfo.otherContact
          .contactEmail &&
        !this.masterInsuranceCertificateOrder.agentInfo.otherContact.contactEmail.match(
          mailformat
        );

      let faxformat = /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
      let checkvalidFaxData =
        this.masterInsuranceCertificateOrder.agentInfo.option == "fax" &&
        !this.masterInsuranceCertificateOrder.agentInfo.fax.match(faxformat);
      let checkvalidFaxDataForother =
        this.masterInsuranceCertificateOrder.agentInfo.otherContact
          .contactFax &&
        !this.masterInsuranceCertificateOrder.agentInfo.otherContact.contactFax.match(
          faxformat
        );

      if (
        this.isCompleted &&
        (checkvalidFaxData || checkvalidFaxDataForother)
      ) {
        this.error = true;
        this.msg = "Enter a valid fax.";
        this.callLoader(false, null);
        return;
      }
      if (
        this.isCompleted &&
        (checkvalidEmailData || checkvalidEmailDataForother)
      ) {
        this.error = true;
        this.msg = "Please Enter Valid Email ";

        this.callLoader(false, null);
        return;
      }
      if (
        this.isCompleted &&
        this.masterInsuranceCertificateOrder.agentInfo
          .masterInsuranceCertificateVendorId == "AddOther"
      ) {
        this.error = true;
        this.msg = "Please select a third party.";
        this.callLoader(false, null);
        return;
      }

      if (this.isCompleted) this.isOrderPlacedOnce = true;
      let response = await Axios.post(
        BASE_API_URL + "processor/saveMasterInsuranceCertificateOrder",
        {
          taskId: this.taskId,
          loanId: this.$route.query.id,
          masterInsuranceCertificateOrder: this.masterInsuranceCertificateOrder,
          reOrderCount: this.countToSendOnServer,
          isComplete: this.isCompleted,
          agentType: 11,
          selectedAgent: this.selectedAgent,
          pathForSaving: this.pathForSaving,
        },
        { headers: authHeader() }
      );
      if (!this.isCompleted) {
        this.$snotify.success(
          "Master Insurance Certificate Order Information has been saved"
        );
      }

      if (response.status === 201 && this.isCompleted) {
        this.$snotify.success(
          "Master Insurance Certificate Order Completed successfully."
        );
      }
      if (this.isCompleted) this.$emit("refresh-task-list", true);
      await this.getMasterInsuranceCertificateOrder();
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async getMasterInsuranceCertificateOrder() {
    this.callLoader(true, null);
    try {
      const response = await Axios.get(
        BASE_API_URL +
          "processor/getMasterInsuranceCertificateOrder/" +
          this.$route.query.id +
          "/" +
          this.taskId +
          "/" +
          this.countToSendOnServer,
        {
          headers: authHeader(),
        }
      );

      if (response.status === 201) {
        this.isLoanCompleted = response.data.isCompleted;
        this.masterInsuranceCertificateOrder =
          response.data.masterInsuranceCertificateOrder;
        this.masterInsuranceCertificateOrder = JSON.parse(
          JSON.stringify(response.data.masterInsuranceCertificateOrder)
        );
        this.dsData = JSON.parse(
          JSON.stringify(response.data.masterInsuranceCertificateOrder)
        );
        this.showReOrderBtn = response.data.showReOrderBtn;
        this.showHistoryBtn = response.data.showHistoryBtn;
        this.loanNumber = response.data.loanNumber;

        if (
          this.masterInsuranceCertificateOrder.agentInfo
            .masterInsuranceCertificateVendorId
        )
          this.selectedCompany = this.masterVendorList.find(
            (e) =>
              e._id ==
              this.masterInsuranceCertificateOrder.agentInfo
                .masterInsuranceCertificateVendorId
          );
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public async getListOfAgents() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "processor/getListOfAgents",
        {
          loanId: this.$route.query.id,
          agentType: 11,
        },
        {
          headers: authHeader(),
        }
      );

      if (response.status === 201) {
        this.masterInsuranceAgents = response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async getMaterInsuranceVendorList() {
    this.callLoader(true, null);
    try {
      const response = await Axios.get(
        BASE_API_URL + "processor/getMaterInsuranceVendorList/" + this.loanId,
        {
          headers: authHeader(),
        }
      );

      if (response.status === 201) {
        this.masterVendorList = response.data.materInsuranceVendor;
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async getSelectedAgent() {
    try {
      this.agent = {};
      this.selectedAgent = null;
      let response = await Axios.post(
        BASE_API_URL + "processor/getSelectedAgent",
        {
          loanId: this.$route.query.id,
          // reOrderCount: this.countToSendOnServer,
          taskId: this.taskId,
          agentType: 11,
          agentId: this.masterInsuranceCertificateOrder.agentContactId,
          companyId: this.masterInsuranceCertificateOrder.companyId,
          lenderId: this.masterInsuranceCertificateOrder.lenderId,
          isLoanCompleted: this.isLoanCompleted,
        },
        {
          headers: authHeader(),
        }
      );
      if (response.status == 201) {
        if (
          response.data.agentData &&
          response.data.agentData.agentContactData &&
          response.data.agentData.agentData
        ) {
          this.agent = response.data.agentData;
          this.selectedAgent = JSON.parse(
            JSON.stringify(this.agent.agentContactData._id)
          );
          this.tempSelectedAgent = JSON.parse(
            JSON.stringify(this.agent.agentContactData._id)
          );
        }
        this.masterInsuranceCertificateOrder.lenderId = response.data.lenderId;
        this.masterInsuranceCertificateOrder.agentContactId =
          this.agent.agentContactData._id;
        this.masterInsuranceCertificateOrder.companyId =
          this.agent.agentData._id;
        this.mortgageClause = response.data.mortgageClause;
        this.masterInsuranceCertificateOrder.agentInfo.email =
          this.agent &&
          this.agent.agentContactData &&
          this.agent.agentContactData.email;
        this.isEmail = this.masterInsuranceCertificateOrder.agentInfo.email
          ? true
          : false;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async reOrderMaster() {
    this.callLoader(true, 1);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getMasterInsuranceCertificateOrder();
        return;
      }
      this.$refs.refReOrder["confitmationBox"]({
        message:
          "Are you sure you want to RE-Order Master Insurance Certificate?",
        apiPath: `processor/reOrderMasterCondo`,
        data: {
          loanId: this.$route.query.id,
          taskId: this.taskId,
          selectedAgent: this.selectedAgent,
        },
      });
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async masterDownloadPDF() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "processor/masterDownloadPDF",
        {
          loanId: this.$route.query.id,
          taskId: this.taskId,
          masterOrder: this.masterOrder,
          selectedAgent: this.selectedAgent,
          pathForSaving: this.pathForSaving,
        },
        {
          headers: authHeader(),
        }
      );
      if (response.status == 201) {
        this.$refs.PDFRef["showFile"]({
          content: response.data,
          name: "MasterInsurance",
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  public onChangeOfChooseMethod(event) {
    if (event.target.value == "email" || event.target.value == "otherContact") {
      this.masterInsuranceCertificateOrder.agentInfo.masterInsuranceCertificateVendorId =
        null;
      this.masterInsuranceCertificateOrder.agentInfo.fax = null;
      this.masterInsuranceCertificateOrder.agentInfo.email =
        this.agent && this.agent.agentContactData
          ? this.agent.agentContactData.email
          : null;
      this.masterInsuranceCertificateOrder.agentInfo.otherContact.option = null;
      this.masterInsuranceCertificateOrder.agentInfo.otherContact.firstName =
        null;
      this.masterInsuranceCertificateOrder.agentInfo.otherContact.lastName =
        null;
      this.masterInsuranceCertificateOrder.agentInfo.otherContact.companyName =
        null;
      this.masterInsuranceCertificateOrder.agentInfo.otherContact.option = null;
      this.masterInsuranceCertificateOrder.agentInfo.otherContact.contactEmail =
        null;
      this.masterInsuranceCertificateOrder.agentInfo.otherContact.contactFax =
        null;

      this.isEmail = true;
    } else if (event.target.value == "fax") {
      this.masterInsuranceCertificateOrder.agentInfo.masterInsuranceCertificateVendorId =
        null;
      this.masterInsuranceCertificateOrder.agentInfo.email = null;
      this.masterInsuranceCertificateOrder.agentInfo.otherContact.firstName =
        null;
      this.masterInsuranceCertificateOrder.agentInfo.otherContact.lastName =
        null;
      this.masterInsuranceCertificateOrder.agentInfo.otherContact.companyName =
        null;
      this.masterInsuranceCertificateOrder.agentInfo.otherContact.option = null;
      this.masterInsuranceCertificateOrder.agentInfo.otherContact.contactEmail =
        null;
      this.masterInsuranceCertificateOrder.agentInfo.otherContact.contactFax =
        null;
      this.isEmail = false;
    } else if (event.target.value == "thirdParty") {
      this.masterInsuranceCertificateOrder.agentInfo.email = null;
      this.masterInsuranceCertificateOrder.agentInfo.fax = null;
      this.masterInsuranceCertificateOrder.agentInfo.otherContact.option = null;
      this.masterInsuranceCertificateOrder.agentInfo.otherContact.firstName =
        null;
      this.masterInsuranceCertificateOrder.agentInfo.otherContact.lastName =
        null;
      this.masterInsuranceCertificateOrder.agentInfo.otherContact.companyName =
        null;
      this.masterInsuranceCertificateOrder.agentInfo.otherContact.contactEmail =
        null;
      this.masterInsuranceCertificateOrder.agentInfo.otherContact.contactFax =
        null;

      this.isEmail = false;
    } else if (event.target.value == "contactEmail") {
      this.masterInsuranceCertificateOrder.agentInfo.otherContact.contactEmail =
        null;
      this.masterInsuranceCertificateOrder.agentInfo.otherContact.contactFax =
        null;
    } else if (event.target.value == "contactFax") {
      this.masterInsuranceCertificateOrder.agentInfo.otherContact.contactEmail =
        null;
      this.masterInsuranceCertificateOrder.agentInfo.otherContact.contactFax =
        null;
    } else {
      this.masterInsuranceCertificateOrder.agentInfo.email = null;
      this.masterInsuranceCertificateOrder.agentInfo.fax = null;
      this.isEmail = false;
    }
  }

  public onChangingAgent() {
    this.agent = this.masterInsuranceAgents.find(
      (e) => e.agentContactId == this.selectedAgent
    ).agentData;

    this.masterInsuranceCertificateOrder.agentContactId =
      this.agent.agentContactData._id;
    this.masterInsuranceCertificateOrder.companyId = this.agent.agentData._id;
    this.masterInsuranceCertificateOrder.agentInfo.email = this.agent
      ? this.agent.agentContactData.email
      : "";
  }

  public async showHistory() {
    this.callLoader(true, 1);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getMasterInsuranceCertificateOrder();
        return;
      }
      this.$refs.refReOrder["showHistory"](
        this.taskId,
        "Master Insurance Certificate"
      );
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public openModalOnChange(event) {
    if (this.isTaskDisabled) {
      this.callLoader(false, null);
      this.$snotify.info("Task is not assigned to you.");
      this.getMasterInsuranceCertificateOrder();
      return;
    }
    if (event.target.value == "AddOther") {
      this.$modal.show("AddOtherVendor");
      this.isCredential = false;
      this.selectedCompany = null;
    } else {
      this.selectedCompany = this.masterVendorList.find(
        (e) =>
          e._id ==
          this.masterInsuranceCertificateOrder.agentInfo
            .masterInsuranceCertificateVendorId
      );
      this.isCredential = this.selectedCompany.Credential ? true : false;
    }
  }

  public async allMountFunction(count) {
    this.countToSendOnServer = count;
    await this.getMasterInsuranceCertificateOrder();
    await this.getListOfAgents();
    await this.getSelectedAgent();
    await this.getMaterInsuranceVendorList();
  }

  public async refreshAfterAddingVendor() {
    this.getMaterInsuranceVendorList();
    this.masterInsuranceCertificateOrder.agentInfo.masterInsuranceCertificateVendorId =
      null;
  }

  public mounted() {
    this.allMountFunction(this.reOrderCount);
    EventBus.$on(
      "getMaterInsuranceVendorList",
      async () => await this.getMaterInsuranceVendorList()
    );
  }
}
