
import { Component, Vue } from "vue-property-decorator";
import Urla from "../../../common-components/urla/Urla.vue";
import Workflow from "../workflow/Workflow.vue";
import Documents from "../../../common-components/documents/Documents.vue";
import Communication from "../../../common-components/CommunicationComponent.vue";
import Contacts from "../../../common-components/ContactComponent.vue";
import loanInfo from "../../../common-components/LoanInfoComponent.vue";
import TaskAllocation from "../../../common-components/assign-task/ShowWorkFlow.vue";
import Axios from "axios";
import { BASE_API_URL, EventBus } from "../../../config";
import { authHeader } from "../../../services/auth";
@Component({
  components: {
    Urla,
    Workflow,
    Communication,
    Documents,
    Contacts,
    loanInfo,
    TaskAllocation,
  },
})
export default class LoanProcess extends Vue {
  /* eslint-disable */
  public step =
    this.$store.state.sessionObj.userType === 3 ||
    this.$store.state.sessionObj.userType === 1
      ? 1
      : this.$store.state.sessionObj.userType === 4 ||
        this.$store.state.sessionObj.userType === 2
      ? 3
      : 2;
  public milestoneList: any = [];
  public statusDetails: any = {};
  public onHoldDate: any = null;
  public roleId: any = [];
  public isValid = true;
  public isLoanInactive = false;
  public hasWorkflow = false;
  public tabIndex = 0;
  public async getLoanStatus() {
    let res = await Axios.get(
      BASE_API_URL + "common/getLoanStatus/" + this.$route.query.id,
      {
        headers: authHeader(),
      }
    );
    this.statusDetails = res.data.statusDetails;
    if (this.statusDetails.status == 1 || this.statusDetails.status == 3) {
      this.isLoanInactive = true;
    }
  }

  public async refreshMileStone() {
    this.$refs.loanInfo["getProgressBarList"]();
    this.$refs.loanInfo["getLoanDetails"]();
    await this.getLoanStatus();
  }

  public async getProcessorType() {
    let res = await Axios.get(
      BASE_API_URL + "processor/dashboard/getProcessorType",
      {
        headers: authHeader(),
      }
    );
    this.roleId = res.data.roleId;
  }

  public async checkLoanHasWorkflow() {
    let res = await Axios.post(
      BASE_API_URL + "processor/dashboard/checkLoanHasWorkflow",
      {
        loanId: this.$route.query.id,
      },
      {
        headers: authHeader(),
      }
    );
    this.hasWorkflow = res.data.hasWorkflow;
  }

  public async checkUrlaTabsValidations(step) {
    try {
      if (this.$store.state.sessionObj.userType == 3) {
        let response = await Axios.get(
          BASE_API_URL +
            "urla/checkUrlaTabsValidations/" +
            this.$route.query.id,
          {
            headers: authHeader(),
          }
        );
        let urlaTabs = response.data.urlaTabs;
        if (urlaTabs.length > 0) {
          this.step = 1;
          this.tabIndex = 0;
          await EventBus.$emit("checkValidationForProcessor", urlaTabs);
        } else this.step = step;
      } else this.step = step;
    } catch (error) {
      console.log(error);
    }
  }

  cancel() {
    this.$bvModal.hide("on-hold-modal");
  }

  public logOut() {
    try {
      this.$store.dispatch("clearSessionData");
      this.$router.push({ name: "Login" });
    } catch (error) {
      console.log(error);
    }
  }

  async mounted() {
    if (this.$store.state.sessionObj.userType == 3)
      await this.getProcessorType();
    await this.getLoanStatus();
    await this.checkLoanHasWorkflow();
  }
  /* eslint-disable */
}
