
import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL, EventBus } from "../../../config";
import { authHeader } from "../../../services/auth";
import ViewDocument from "../../../common-components/ViewDocument.vue";
import PdfPreview from "../../../common-components/PDFPreview.vue";
import CommonNotifyOnRejection from "../../../common-components/CommonNotifyOnRejection.vue";
import ReOrderTask from "../../../common-components/ReOrderTask.vue";
@Component({
  components: {
    ViewDocument,
    CommonNotifyOnRejection,
    ReOrderTask,
    PdfPreview,
  },
})
export default class TitleOrder extends Vue {
  /* eslint-disable */
  public titleOrder: any = {};
  public agent: any = {};
  @Prop()
  public taskId: any;
  @Prop()
  public reOrderCount: any;
  public doc = false;
  public error: any = {
    msg: null,
    show: false,
  };
  @Prop()
  public isTaskDisabled: any;

  public agentNotAdded = false;
  public uploading = false;
  @Prop()
  public isLoanInactive: any;
  public taskCompleted = false;
  public allDocs: any = [];
  public mortgageClause: any = {};
  public notifyBroker = false;
  public allAgents: any = [];
  public selectedAgent: any = null;
  public countToSendOnServer: any = null;
  public showHistoryBtn = false;
  public showReOrderBtn = false;
  public addNewAgent = false;
  public showErrorInModeCommun = false;
  public faxNumber = null;
  public communicationOption = "null";
  public emailToDelete = "";
  public isOrderPlacedOnce = false;

  /**
   * Taking dsData variable for checking form data is changed or not only for comaprison
   */
  public dsData: any = {};
  /**************************************************************** */
  public tempSelectedAgent: any = null;
  public resendMail: any = false;
  public docsDetails: any = [];
  public docStatus1 = false;
  public docStatus2 = false;
  public loanNumber: any = {};
  public pathForSaving: any = `./uploads/${this.$route.query.id}/Title_Request_Form.pdf`;

  //**************************************************************************************
  /**
   * Variables for addMoreAgents
   */
  public otherTitleAgents: any = [];
  public moreAgentsAvailable = false;
  public agentsSuggestion = [];
  public agentCompanyId = "";
  public agentFName = "";
  public agentLName = "";
  public agentEmail = "";
  public agentContact = "";
  public agentNmlsId: any = "";
  public agentLicenseId: any = "";
  public isFNameError = false;
  public isEmailError = false;
  public isContactError = false;
  public isLNameError = false;
  public selectedAgentOption = null;
  public selectedExistingAgent = false;
  public emailErrorMessage = "";
  public isPrimaryAgentActive = true;
  @Prop()
  public totalTaskCount: any = 0;
  public notifyMessage = "Title Order Information has been saved.";
  public isActive: any = null;
  public userId: any = null;
  public activeStatus = "";
  public sendLinkTo: any = null;
  public resendingTitleOrder = false;
   public isRejected = false;
  public isAccepted = false;
  public isChecklist = true;

  public addAgentsChecked() {
    this.moreAgentsAvailable
      ? (this.moreAgentsAvailable = false)
      : (this.moreAgentsAvailable = true);
  }
  public ConfirmationPopup() {
    this.$modal.show("titleConfirmationModal");
  }
  // CANCEL FUNCTION FOR ADD-AGENT-MODAL
  public async cancel() {
    this.$modal.hide("addAgents");
    this.$modal.hide("deleteAgent");
    this.$modal.hide("changeCompany");
    this.$modal.hide("DeActivateAgent");
    this.$modal.hide("reSendInvitation");
    this.$modal.hide("resendLinkconfirmationLink");
    this.sendLinkTo = "";
    this.addNewAgent = false;
    this.selectedAgentOption = null;
    this.selectedAgent = this.agent.agentContactData._id;
  }

  // AGENT-DROP-DOWN-SELECTED.
  public agentDropDownSelected() {
    this.resetAddAgenFormErrors();
    this.selectedAgentOption == 0
      ? (this.addNewAgent = false)
      : (this.addNewAgent = true);
    this.selectedAgentOption == 1
      ? (this.selectedExistingAgent = false)
      : (this.selectedExistingAgent = true);

    if (this.selectedAgentOption != 0 && this.selectedAgentOption != 1) {
      this.fillFormData(this.selectedAgentOption);
    }
  }

  //CHANGE THE AGENTS COMPANY
  public async changeCompanyOfAgents() {
    this.agent = this.allAgents.find(
      (e) => e.agentContactId == this.selectedAgent
    ).agentData;
    this.titleOrder.agentContactId = this.agent.agentContactData._id;
    this.titleOrder.companyId = this.agent.agentData._id;
    this.otherTitleAgents = [];
    this.$modal.hide("changeCompany");
    this.notifyMessage = "Title Order company changed.";
    await this.saveTitleOrderData(false);
  }

  // ADD NEW AGENT TO THE LIST i.e. CREATE NEW AGENTCONTACT IN DB.
  public async addAgentToList() {
    if (this.selectedAgentOption == 1) {
      if (!this.agentFName) this.isFNameError = true;
      if (!this.agentLName) this.isLNameError = true;
      if (!this.agentEmail) this.isEmailError = true;
      if (!this.agentContact) this.isContactError = true;
      if (
        this.isEmailError ||
        this.isContactError ||
        this.isLNameError ||
        this.isFNameError
      )
        return;

      let response = await Axios.post(
        BASE_API_URL +
          `processor/addMoreAgentsTitleOrder/${this.$route.query.id}`,
        {
          fullName: {
            firstName: this.agentFName,
            lastName: this.agentLName,
          },
          phone: this.agentContact,
          email: this.agentEmail.toLowerCase(),
          contactNMLSId: this.agentNmlsId,
          contactlicenseId: this.agentLicenseId,
          agentId: this.titleOrder.companyId,
          agentType: 3,
        },
        { headers: authHeader() }
      );

      await this.suggestionClicked(response.data.data);
    }

    if (this.selectedAgentOption != 1 && this.selectedAgentOption != 0)
      this.suggestionClicked(this.selectedAgentOption);

    this.cancel();
    this.showSuggestion();
  }

  // ADD THE CURRENT SELECTED AGENT DETAILS FROM DROP-DOWN.
  public fillFormData(agent) {
    this.agentFName = agent.fullName.firstName;
    this.agentLName = agent.fullName.lastName;
    this.agentEmail = agent.email;
    this.agentContact = agent.phone;
  }

  public validateFname() {
    this.isFNameError = false;
    if (!this.agentFName) return (this.isFNameError = true);
    if (this.agentFName.trim() === "") return (this.isFNameError = true);
  }
  public validateLname() {
    this.isLNameError = false;
    if (!this.agentLName) return (this.isLNameError = true);
    if (this.agentLName.trim() === "") return (this.isLNameError = true);
  }
  public async validateEmail() {
    this.isEmailError = false;
    this.emailErrorMessage = "This field is required!";
    if (!this.agentEmail) return (this.isEmailError = true);
    if (this.agentEmail.trim() === "") return (this.isEmailError = true);
    let email =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let validEmail = email.test(this.agentEmail);
    if (!validEmail) {
      this.emailErrorMessage = "Please provide valid Email!";
      return (this.isEmailError = true);
    }
    let checkDuplicacy = await this.checkDuplicateEmail(this.agentEmail);
    if (checkDuplicacy) {
      this.emailErrorMessage = "Email Already Exists!";
      this.isEmailError = true;
    }
  }
  public validateContact() {
    this.isContactError = false;
    if (!this.agentContact) return (this.isContactError = true);
    if (this.agentContact.trim() === "") return (this.isContactError = true);
  }

  // get other agents for the loan
  public async getOtherTitleAgents() {
    let response = await Axios.get(
      BASE_API_URL +
        `processor/getOtherTitleOrderAgents/${this.$route.query.id}/${5}/${
          this.countToSendOnServer
        }/null`,
      { headers: authHeader() }
    );
    this.otherTitleAgents = response.data.data;
  }

  // REMOVE THE ADDITIONAL AGENT FROM CURRENT ARRAY.
  public async removeOtherTitleAgent(email) {
    this.otherTitleAgents = this.otherTitleAgents.filter((item) => {
      return item.email !== email;
    });
    this.$modal.hide("deleteAgent");
    this.$snotify.success("Agent removed successfully!");
    await this.updateOtherAgentsList();
  }

  // RESET THE ADD-AGENT-MODAL-FORM ERRORS AND FIELDS.
  public resetAddAgenFormErrors() {
    this.agentNmlsId = "";
    this.agentLicenseId = "";
    this.agentFName = "";
    this.agentLName = "";
    this.agentEmail = "";
    this.agentContact = "";
    this.isFNameError = false;
    this.isEmailError = false;
    this.isContactError = false;
    this.isLNameError = false;
    
  }

  public async suggestionClicked(id) {
    if (this.otherTitleAgents) {
      this.otherTitleAgents.push(id);
    } else {
      this.otherTitleAgents = [id];
    }
    this.notifyMessage = "Agent added successfully!";
    await this.saveTitleOrderData(false);
  }

  // SHOW SUGGESSTION FOR AGENTS TO ADD.
  public async showSuggestion() {
    let response = await Axios.get(
      BASE_API_URL +
        `processor/getOtherAgentToAdd/${this.titleOrder.companyId}?loanNo=${this.$route.query.id}`,
      { headers: authHeader() }
    );
    this.agentsSuggestion = response.data.data.filter((item) => {
      if (item._id == this.agent.agentContactData._id) return false;
      for (let i in this.otherTitleAgents) {
        if (this.otherTitleAgents[i].email == item.email) return false;
      }

      return true;
    });
  }

  public async gethistoy() {
    let response2 = await Axios.get(
      BASE_API_URL +
        "processor/getReOrderHistoryData/" +
        this.$route.query.id +
        "/" +
        this.taskId,
      {
        headers: authHeader(),
      }
    );
    if (response2.status === 201) {
      console.log(response2.data);
    }
  }

  // GET COMPANY DETAILS
  public async getAgentCompany() {
    try {
      let response = await Axios.get(
        BASE_API_URL + `processor/getAgentCompany/${this.$route.query.id}`,
        {
          headers: authHeader(),
        }
      );
      this.agentCompanyId = response.data.data;
    } catch (error) {
      console.log("error", error);
    }
  }

  // UPDATE THE AGENT LIST IN TASKMODEL :
  public async updateOtherAgentsList() {
    try {
      let response = await Axios.put(
        BASE_API_URL +
          `processor/updateOtherAgentsList/${this.$route.query.id}`,
        { list: this.otherTitleAgents },
        { headers: authHeader() }
      );
      await this.getOtherTitleAgents();
    } catch (error) {
      console.log(error);
    }
  }

  // CHECK DUPLICATE EMAIL...
  public async checkDuplicateEmail(email) {
    try {
      //return false when email is unique
      for (let i = 0; i < this.otherTitleAgents.length; i++) {
        if (email == this.otherTitleAgents[i].email) return true;
      }
      let response = await Axios.get(
        BASE_API_URL + `processor/agentDuplicateEmail/${email}?agentTypeId=7`,
        { headers: authHeader() }
      );

      if (response.data.code === 200) {
        return false;
      }

      return true;
    } catch (error) {
      console.log("agentDuplicateEmail", error);
    }
  }

  public async activationLink(id, sendMail) {
    this.callLoader(true, 1);
    try {
      let response = await Axios.get(
        BASE_API_URL +
          `processor/getTokenForAgentToCopy/${id}?loanId=${this.$route.query.id}&&title=isTitleOrder&&sendMail=${sendMail}&&count=${this.reOrderCount}`,
        { headers: authHeader() }
      );
      if (response.status === 201 && response.data.isLatest) {
        this.$modal.hide("resendLinkconfirmationLink");

        this.resendingTitleOrder = true;
        await this.saveTitleOrderData(true);
        this.sendLinkTo = "";
        if (sendMail === false) {
          navigator.clipboard.writeText(response.data.data);
          this.$snotify.success("Link copied successfully!");
        } else {
          this.$snotify.success("Link send successfully!");
        }
      } else {
        this.$modal.hide("resendLinkconfirmationLink");

        if (sendMail === false) {
          navigator.clipboard.writeText(response.data.data);
          this.$snotify.warning("Link is not active for this order");
        } else {
          this.$snotify.warning("Can't send mail for this order.");
        }
      }

      this.$modal.hide("resendLinkconfirmationLink");
      this.resendingTitleOrder = false;
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  // ***************************************************************************

  public async uploadFile(event, selectedDoc) {
    this.callLoader(true, 1);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        return;
      }
      if (event.target.files.length <= 0) {
        this.callLoader(false, 1);
        return;
      }

      const formData: any = new FormData();
      let loanId: any = this.$route.query.id;

      Array.from(event.target.files).forEach((element) => {
        formData.append("file", element);
      });

      formData.append("loanId", loanId);
      formData.append("taskId", this.taskId);
      formData.append("reOrderCount", this.countToSendOnServer);
      formData.append("docId", selectedDoc._id);
      const response = await Axios.post(
        BASE_API_URL + "processor/uploadTitleDocs",
        formData,
        { headers: authHeader() }
      );
      if (response.status === 201) this.$snotify.success(response.data.message);
      await this.isBrokerNotifiedForRejectedDocs();

      await this.getListOfDocs();
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async downloadPDF() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "processor/downloadPDF",
        {
          loanId: this.$route.query.id,
          taskId: this.taskId,
          titleOrder: this.titleOrder,
          selectedAgent: this.selectedAgent,
          pathForSaving: this.pathForSaving,
        },
        {
          headers: authHeader(),
        }
      );
      if (response.status == 201) {
        this.$refs.PDFRef["showFile"]({
          content: response.data,
          name: "TitleRequest",
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async viewDocument(document) {
    try {
      let obj = {
        documentId: document._id,
        docName: document.name,
        docCategory: document.category,
        loanId: this.$route.query.id,
         isRejected: this.isRejected,
        isAccepted: this.isAccepted,
        isChecklist: this.isChecklist,
      };
      this.$refs.ref["showFile"](obj);
    } catch (error) {
      console.log(error);
    }
  }

  public async getTitleOrderData() {
    try {
      this.callLoader(true, 1);
      let response = await Axios.get(
        BASE_API_URL +
          "processor/getTitleOrderData/" +
          this.$route.query.id +
          "/" +
          this.taskId +
          "/" +
          this.countToSendOnServer,
        {
          headers: authHeader(),
        }
      );
      if (response.status === 201) {
        console.log("DATA...", response.data);
        this.taskCompleted = response.data.isCompleted;
        this.dsData = JSON.parse(JSON.stringify(response.data.titleOrder));
        this.titleOrder = JSON.parse(JSON.stringify(response.data.titleOrder));
        this.showReOrderBtn = response.data.showReOrderBtn;
        this.showHistoryBtn = response.data.showHistoryBtn;
        this.resendMail = this.taskCompleted ? true : false;
        this.loanNumber = response.data.loanNumber;
        // faxNumber
        if (response.data && response.data.titleOrder.selectedFax) {
          this.communicationOption = "fax";
          this.faxNumber = response.data.titleOrder.faxNumber;
        } else {
          this.communicationOption = "email";
        }
        // if(response.data && response.data.titleOrder.otherTitleAgents &&
        //  response.data.titleOrder.otherTitleAgents.length){
        // }
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async checkSaveValidation() {
    try {
      let returnV = false;
      if (
        !this.titleOrder.isTitleOrdered &&
        this.titleOrder.isTitleOrdered == this.dsData.isTitleOrdered
      ) {
        if (!this.selectedAgent && this.selectedAgent == this.tempSelectedAgent)
          returnV = true;
        if (this.allAgents.length == 1 && this.selectedAgent) returnV = true;

        returnV = false;
      }

      return returnV;
    } catch (error) {
      console.log(error);
    }
  }

  public async saveTitleOrderData(isComplete) {
    if (this.isOrderPlacedOnce) return;
    try {
      if (!this.communicationOption) {
        this.showErrorInModeCommun = true;
        return;
      }

      this.callLoader(true, 2);
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        await this.getTitleOrderData();
        return;
      }
      if (!isComplete) {
        let notValid1 = await this.checkSaveValidation();
        if (notValid1) {
          this.callLoader(false, null);
          this.$snotify.info("No Changes");
          return;
        }
      }
      if (isComplete) {
        let requireDoc = this.allDocs.find(
          (e) =>
            e.docStatus == "Required" &&
            e.name != "Borrower Authorization" &&
            e.name != "Survey (Prior)" &&
            e.name != "Title Policy (Prior)"
        );
        let uploadedDoc = this.allDocs.find(
          (e) =>
            e.docStatus == "Uploaded" &&
            e.name != "Borrower Authorization" &&
            e.name != "Survey (Prior)" &&
            e.name != "Title Policy (Prior)"
        );

        if (!this.selectedAgent) {
          this.error.show = true;
          this.error.msg = "Agent Not added Yet.";
          this.callLoader(false, null);
          return;
        } else if (!this.titleOrder.isTitleOrdered) {
          this.error.show = true;
          this.error.msg = "All Fields are Required.";
          this.callLoader(false, null);
          return;
        } else if (requireDoc && !this.titleOrder.hasPropertyDoc) {
          this.error.show = true;
          this.error.msg = `${requireDoc.name} is required`;
          this.callLoader(false, null);
          return;
        } else if (
          uploadedDoc &&
          uploadedDoc.docFiles[uploadedDoc.docFiles.length - 1].status ==
            "Reject" &&
          !this.titleOrder.hasPropertyDoc
        ) {
          this.error.show = true;
          this.error.msg = `${uploadedDoc.name} is required`;
          this.callLoader(false, null);
          return;
        } else if (
          uploadedDoc &&
          uploadedDoc.docFiles[uploadedDoc.docFiles.length - 1].status ==
            null &&
          !this.titleOrder.hasPropertyDoc
        ) {
          this.error.show = true;
          this.error.msg = `${uploadedDoc.name} is required`;
          this.callLoader(false, null);
          return;
        } else if (!this.titleOrder.lenderId) {
          this.error.show = true;
          this.error.msg = "Lender Is Required.";
          this.callLoader(false, null);
          return;
        }
      }

      if (
        isComplete &&
        this.communicationOption == "fax" &&
        (this.faxNumber == null || this.faxNumber == "")
      ) {
        this.error.show = true;
        this.error.msg = "All fields are Required.";
        this.callLoader(false, null);
        return;
      }

      let faxformat = /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
      let checkvalidFaxData =
        this.communicationOption == "fax" && !this.faxNumber.match(faxformat);
      if (isComplete && checkvalidFaxData) {
        this.error.show = true;
        this.error.msg = "Enter a valid fax.";
        this.callLoader(false, null);
        return;
      }

      let list = [] as any;
      this.otherTitleAgents.map((item) => {
        list.push(item._id);
      });

      this.titleOrder["otherTitleAgents"] = list;

      if (isComplete) this.isOrderPlacedOnce = true;
      let response = await Axios.post(
        BASE_API_URL + "processor/saveTitleOrderData",
        {
          loanId: this.$route.query.id,
          taskId: this.taskId,
          reOrderCount: this.countToSendOnServer,
          titleOrder: this.titleOrder,
          isComplete: isComplete,
          selectedAgent: this.selectedAgent,
          agentType: 3,
          formData: this.dsData,
          pathForSaving: this.pathForSaving,
          selectedFax: this.communicationOption == "fax" ? true : false,
          faxNumber: this.faxNumber,
          communicationOption: this.communicationOption,
          resendingTitleOrder: this.resendingTitleOrder,
        },
        {
          headers: authHeader(),
        }
      );

      if (response.status === 201)
        if (!this.resendingTitleOrder) {
          this.$snotify.success(
            isComplete
              ? "Title Order Completed successfully."
              : this.notifyMessage
          );
        }

      if (isComplete) {
        this.$emit("refresh-task-list", true);
      }
      this.notifyMessage = "Title Order Information has been saved.";
      await this.getTitleOrderData();
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public async getListOfDocs() {
    this.callLoader(true, 1);
    try {
      let response = await Axios.get(
        BASE_API_URL +
          "processor/getListOfDocsForTitleOrder/" +
          this.$route.query.id +
          "/" +
          this.countToSendOnServer,
        {
          headers: authHeader(),
        }
      );

      if (response.status === 201) {
        this.allDocs = response.data.allDocs;
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, 1);
  }

  public async isBrokerNotifiedForRejectedDocs() {
    try {
      let res = await Axios.get(
        BASE_API_URL +
          "loanChecklist/isBrokerNotifiedForRejectedDocs/" +
          this.$route.query.id +
          "/" +
          this.taskId +
          "/" +
          null,
        {
          headers: authHeader(),
        }
      );
      if (res.status == 201) {
        this.notifyBroker = res.data.notifyBorker;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async reOrderTitle() {
    this.callLoader(true, 1);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        return;
      }
      this.$refs.refReOrder["confitmationBox"]({
        message: "Are you sure you want to RE-Order Title Order?",
        apiPath: `processor/reOrderTitle`,
        data: {
          loanId: this.$route.query.id,
          taskId: this.taskId,
          selectedAgent: this.selectedAgent,
        },
      });
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async resendMailToTitleCompany() {
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        return;
      }
      let response = await Axios.post(
        BASE_API_URL + "processor/sendMailToTitleCompany",
        {
          loanId: this.$route.query.id,
          taskId: this.taskId,
        },
        {
          headers: authHeader(),
        }
      );
      if (response) {
        this.$snotify.success("Request Email Sent Successfully.");
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async getListOfAgents() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "processor/getListOfAgents",
        {
          loanId: this.$route.query.id,
          agentType: 3,
        },
        {
          headers: authHeader(),
        }
      );
      if (response.status === 201) this.allAgents = response.data;
    } catch (error) {
      console.log(error);
    }
  }

  public async getSelectedAgent() {
    try {
      this.agent = {};
      let response = await Axios.post(
        BASE_API_URL + "processor/getSelectedAgent",
        {
          loanId: this.$route.query.id,
          agentType: 3,
          taskId: this.taskId,
          agentId: this.titleOrder.agentContactId,
          companyId: this.titleOrder.companyId,
          lenderId: this.titleOrder.lenderId,
          isLoanCompleted: this.taskCompleted,
        },
        {
          headers: authHeader(),
        }
      );

      if (response.status == 201) {
        if (
          response.data &&
          response.data.agentData &&
          response.data.agentData.agentContactData &&
          response.data.agentData.agentData
        ) {
          this.agent = response.data.agentData;
          this.selectedAgent = JSON.parse(
            JSON.stringify(this.agent.agentContactData._id)
          );
          this.tempSelectedAgent = JSON.parse(
            JSON.stringify(this.agent.agentContactData._id)
          );
          this.titleOrder.agentContactId = this.agent.agentContactData._id;
          this.titleOrder.companyId = this.agent.agentData._id;
        } else {
          this.agentNotAdded = true;
        }
        this.mortgageClause = response.data.mortgageClause;
        this.titleOrder.lenderId = response.data.lenderId;
      }
    } catch (error) {
      console.log(error);
    }
  }
  public async deActivateAgent(userId) {
    try {
      const response = await Axios.post(
        BASE_API_URL + "processor/deActivateAgent/",
        {
          loanId: this.$route.query.id,
          taskId: this.taskId,
          // reOrderCount: this.countToSendOnServer,
          userId: userId,
        },
        {
          headers: authHeader(),
        }
      );
      if (response.status === 201) this.$snotify.success(response.data.message);
      this.$modal.hide("DeActivateAgent");
      this.getOtherTitleAgents();
      // this.getTitleOrderData();
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async onChangingAgent() {
    //   this.agent = this.allAgents.find(
    //     (e) => e.agentContactId == this.selectedAgent
    //   ).agentData;
    // this.titleOrder.agentContactId = this.agent.agentContactData._id;
    // this.titleOrder.companyId = this.agent.agentData._id;
    this.$modal.show("changeCompany");
  }

  public async showHistory() {
    this.callLoader(true, 1);
    try {
      this.$refs.refReOrder["showHistory"](this.taskId, "Title");
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async allMountFunction(count) {
    this.countToSendOnServer = count;
    await this.getTitleOrderData();
    await this.getListOfDocs();
    this.selectedAgent = null;
    await this.getAgentCompany();
    await this.isBrokerNotifiedForRejectedDocs();
    await this.getListOfAgents();
    await this.getSelectedAgent();
    await this.getOtherTitleAgents();
  }

  public mounted() {
    this.allMountFunction(this.reOrderCount);
  }
}
