var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"p-3"},[(
        _vm.documents.length > 0 &&
        _vm.$store.state.sessionObj.userType != 2 &&
        _vm.$store.state.sessionObj.userType != 4
      )?_c('div',{staticClass:"row mx-0"},_vm._l((_vm.documents),function(document,i){return _c('div',{key:i,staticClass:"mb-2 col-lg-6 px-2"},[_c('div',{staticClass:"card-content-border-dark"},[_c('div',{staticClass:"p-3 checklist-doc"},[_c('div',{staticClass:"d-flex align-content-center",staticStyle:{"flex-direction":"column"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"custom-checkbox mb-0 mr-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(document.isComplete),expression:"document.isComplete"}],staticClass:"custom-checkbox-input mr-1",attrs:{"type":"checkbox","disabled":!_vm.isProcessor()
                        ? true
                        : document.isDocument
                        ? true
                        : false},domProps:{"checked":Array.isArray(document.isComplete)?_vm._i(document.isComplete,null)>-1:(document.isComplete)},on:{"change":[function($event){var $$a=document.isComplete,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(document, "isComplete", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(document, "isComplete", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(document, "isComplete", $$c)}},function($event){return _vm.updateLoanChecklist(document._id, document.isComplete)}]}}),_c('div',{staticClass:"indicator"})]),_c('h6',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
                    title: document.count
                      ? `ReOrder Count ${document.count + 1}`
                      : '',
                  }),expression:"{\n                    title: document.count\n                      ? `ReOrder Count ${document.count + 1}`\n                      : '',\n                  }"}],staticClass:"card-content-title mb-2 mt-0 mb-0 top_2 pr-2",domProps:{"innerHTML":_vm._s(_vm.getDocName(document))}})]),(
                  document &&
                  document.docFiles.length > 0 &&
                  document.docFiles[document.docFiles.length - 1].files
                    .length > 0 &&
                  _vm.getDate(document)
                )?_c('div',{staticClass:"d-flex align-items-center ml-4"},[_c('h6',{staticClass:"card-content-title mb-0 mt-0 top_2"},[_vm._v(" Uploaded On: "+_vm._s(_vm.$moment(document.lastUpdatedOn).format( "MM/DD/YYYY, h:mm:ss a" ))+" - "+_vm._s(document.docUploadedBy ? document.docUploadedBy : "Title Agent")+" ")])]):_vm._e()]),_c('div',[_c('div',{staticClass:"d-flex align-items-center"},[(
                    _vm.loanDetails.idsHandledByTMPCO &&
                    document.category == 'DisclosureDocs' &&
                    document.docStatus == 'Required'
                  )?_c('i',{staticClass:"fa fa-exclamation-circle color-red mr-1"}):(
                    (_vm.loanDetails.disclosureSigned ||
                      _vm.$route.query.name == 'disclosure') &&
                    document.docStatus == 'Required'
                  )?_c('i',{staticClass:"fa fa-exclamation-circle color-red mr-1"}):(
                    (document.toBeUploadedBy.includes(2) ||
                      document.toBeUploadedBy.includes(4)) &&
                    document.docStatus == 'Required'
                  )?_c('i',{staticClass:"fa fa-exclamation-circle color-red mr-1",attrs:{"aria-hidden":"true"}}):_vm._e(),(document.docFiles.length > 0)?_c('div',[(
                      document.docFiles[document.docFiles.length - 1]
                        .status == 'Reject'
                    )?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"text__red mr-2 tmpco-btn tmpco-btn__default ml-1",staticStyle:{"font-size":"12px","color":"red !important"},attrs:{"title":document.docFiles[document.docFiles.length - 1].comment}},[_vm._v(" REJECTED ")]):_vm._e()]):_vm._e(),(document.isDocument)?_c('div',[(document.docStatus == 'Uploaded')?_c('button',{staticClass:"tmpco-btn tmpco-btn__default",on:{"click":function($event){$event.preventDefault();return _vm.viewDocument(document)}}},[_c('i',{staticClass:"fa fa-file-text-o mr-2",attrs:{"aria-hidden":"true"}}),_vm._v(" VIEW ")]):_c('div',{staticClass:"upload-btn-wrapper"},[_c('button',{class:[
                        document.isUploading
                          ? 'btn-upload cursor-pointer cursor-not-allow'
                          : 'btn-upload cursor-pointer',
                      ],attrs:{"disabled":document.isUploading}},[(document.isUploading)?_c('div',{staticClass:"loader"},[_vm._v(" Loading... ")]):_vm._e(),_c('i',{staticClass:"fa fa-upload mr-1",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(document.isUploading ? "UPLOADING..." : "UPLOAD")+" ")]),_c('input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],attrs:{"id":"'upload'+i","disabled":_vm.isUploadedByProcessor(document),"type":"file","name":"file-upload","title":"document.isUploading?'Uploading file':'Upload file'","multiple":""},on:{"change":function($event){return _vm.uploadDocument($event, document)}}}),(document.showValidation)?_c('div',{staticClass:"form-group mb-0 text-right"},[_c('span',{staticClass:"login-error show mb-0"},[_vm._v("Please upload the file")])]):_vm._e()])]):_vm._e()])])])])])}),0):_c('div',{staticClass:"d-flex justify-content-center align-items-center height__100"},[_c('p',{staticClass:"content-text"},[_vm._v("No Data")])])]),_c('ViewDocument',{ref:"ref",on:{"refresh-docs":function($event){return _vm.getUploadedDoucments()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }