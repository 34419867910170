
import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL, EventBus } from "../../config";
import { authHeader } from "../../services/auth";
import ViewDocument from "../ViewDocument.vue";
import { DOCUMENT_CATEGORY } from "../../constants/constants";
@Component({
  components: {
    ViewDocument,
  },
})
export default class UploadedDocs extends Vue {
  /* eslint-disable */
  @Prop()
  public documentValidation;
  public documents = [];
  public loanId: any = this.$route.query.id;
  public files = [];
  public isValid: any = false;
  public loanDetails: any = null;
  public hasSubmit = false;
  public isUploading = false;
  public docId = null;
  public documentCategory = DOCUMENT_CATEGORY;
  public document: any = {};

  public async getUploadedDoucments() {
    try {
      let response = await Axios.get(
        BASE_API_URL +
          "loanChecklist/getUploadedDoucments/" +
          this.$route.query.id,
        { headers: authHeader() }
      );
      this.documents = response.data.documents;
      this.loanDetails = response.data.loanDetails;
      // if (this.$store.state.sessionObj.userType === 3)
      // EventBus.$emit("brokerNotifed");
    } catch (error) {
      console.log(error);
    }
  }

  public async viewDocument(document) {
    try {
      console.log("DOCUMENT -->", document);
      await this.$refs.ref["showFile"]({
        documentId: document._id,
        loanId: this.$route.query.id,
        docName: document.name,
        docCategory: document.category,
        isComplete: document.isComplete,
        borrowerName: document.borrowerName,
      });
    } catch (error) {
      console.log(error);
    }
  }

  public getDate(document) {
    let files = document.docFiles[document.docFiles.length - 1].files;
    document.lastUpdatedOn = files[files.length - 1].uploadedOn
      ? files[files.length - 1].uploadedOn
      : null;
    return document;
  }

  public async uploadDocument(event, document) {
    try {
      document.isUploading = true;
      let files = event.target.files;
      let formData: any = new FormData();
      this.docId = document._id;
      formData.append("loanId", this.loanId);
      formData.append("documentId", document._id);
      if (document.borrowerUserId)
        formData.append("borrowerUserId", document.borrowerUserId);
      Array.from(files).forEach((f) => {
        formData.append("file", f);
      });
      let response = await Axios.post(
        BASE_API_URL + "loanChecklist/uploadDocument",
        formData,
        {
          headers: authHeader(),
        }
      );
      if (response.status == 201) {
        this.$snotify.success("Document has been uploaded");
        let doc = response.data.checklist.find((c) => c._id == document._id);
        document["docStatus"] = doc.docStatus;
        document["docFiles"] = doc.docFiles;
        document["isUploading"] = false;
        document["isComplete"] = doc.isComplete;
        // await this.getUploadedDoucments();
        if (this.$route.query.name == "disclosure" && this.hasSubmit) {
          await this.validateDocuments();
          EventBus.$emit("validation");
        }
      }
    } catch (error) {
      console.log(error);
      document["isUploading"] = false;
    }
  }

  public async addDocument() {
    try {
      let res = await Axios.post(
        BASE_API_URL + "loanChecklist/addDocument",
        {
          loanId: this.loanId,
          document: this.document,
        },
        { headers: authHeader() }
      );

      if ((res.status = 201)) {
        this.$snotify.success("Document has been Added.");
        await this.getUploadedDoucments();
        this.cancel();
      }
    } catch (error) {
      console.log(error);
    }
  }

  public cancel() {
    this.$modal.hide("addDocument");
    this.document = {};
  }

  public validateDocuments() {
    let hasDocToUpload: any;
    if (this.loanDetails.idsHandledByTMPCO) {
      this.documents.forEach((d: any) => {
        Vue.set(
          d,
          "showValidation",
          d.source == "Broker Disclosure" ? true : false
        );
      });
      hasDocToUpload = this.documents.find(
        (d: any) =>
          d.source == "Broker Disclosure" && d.docStatus === "Required"
      );
      if (this.$route.query.name === "disclosure") {
        this.documents.forEach((d: any) => {
          Vue.set(
            d,
            "showValidation",
            d.docStatus == "Required" ? true : false
          );
        });
        hasDocToUpload = this.documents.find(
          (d: any) => d.docStatus === "Required"
        );
      }
    } else {
      this.documents.forEach((d: any) => {
        Vue.set(d, "showValidation", d.docStatus == "Required" ? true : false);
      });
      hasDocToUpload = this.documents.find(
        (d: any) => d.docStatus == "Required"
      );
    }
    return hasDocToUpload ? false : true;
  }

  public async updateLoanChecklist(documentId, isComplete) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "loanChecklist/updateLoanChecklist",
        {
          loanId: this.loanId,
          documentId: documentId,
          isComplete: isComplete,
        },
        {
          headers: authHeader(),
        }
      );
      if (response.data == 200) {
        this.$snotify.success("Checklist has been Updated");
      }
    } catch (error) {
      console.log(error);
    }
  }

  public isProcessor() {
    return this.loanDetails.userType == 3 ? true : false;
  }

  public getDocName(document) {
    if (
      document.category == "Income" ||
      document.category == "Assets" ||
      (document.category == "Identification" &&
        document.name == "Photo ID (Driving licence/passport)")
    ) {
      return (
        document.name.replace(`<p>\g`, "").replace(`</p>\g`, "") +
        ` <strong>${
          document.borrowerName ? "" + document.borrowerName + "" : ""
        }</strong>` +
        " " +
        `${document.employerName ? ":-" + document.employerName : ""}`
      );
    } else {
      return (
        document.name.replace(`<p>\g`, "").replace(`</p>\g`, "") +
        `${document.employerName ? ":-" + document.employerName : ""}`
      );
    }
  }

  public isUploadedByProcessor(document) {
    let isUploadedByProcessor = false;
    if (document.hasOwnProperty("toBeUploadedBy"))
      isUploadedByProcessor = document.toBeUploadedBy.find((u: any) => u == 3);
    return this.$store.state.sessionObj.userType != 3 && isUploadedByProcessor
      ? true
      : false;
  }

  public async mounted() {
    await this.getUploadedDoucments();
    console.log(
      "this.$route.name, this.$store.state.sessionObj.userType",
      this.$route.name,
      this.$store.state.sessionObj.userType
    );
    if (this.documentValidation) {
      this.validateDocuments();
    }
  }

  /* eslint-disable */
}
