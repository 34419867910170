
import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL, EventBus } from "../../../config";
import { authHeader } from "../../../services/auth";
import CommonNotifyOnRejection from "../../../common-components/CommonNotifyOnRejection.vue";
import ViewDocument from "../../../common-components/ViewDocument.vue";
import ReOrderTask from "../../../common-components/ReOrderTask.vue";
import CommonVendorModal from "../../../common-components/CommonVendorModal.vue";
import PdfPreview from "../../../common-components/PDFPreview.vue";

@Component({
  components: {
    ViewDocument,
    CommonNotifyOnRejection,
    CommonVendorModal,
    PdfPreview,
    ReOrderTask,
  },
})
export default class VoeOrder extends Vue {
  /* eslint-disable */
  public voeDetail: any = {};
  public loanId: any = this.$route.query.id;
  public bImgLoader = false;
  public cCImgLoader = false;
  public allDocs: any = {};
  public docsDetail: any = [];
  public selectedCompany: any = null;
  public showToasty = true;
  public selectedEmployer = null;
  public showReOrderBtn = false;
  public showHistoryBtn = false;
  public isOrderPlacedOnce = false;
  @Prop()
  public taskId: any;

  public errorObj: any = {
    msg: null,
    show: true,
  };
  public isComplete = false;
  public notifyBroker = false;
  public isRejected = false;
  public isAccepted = false;
  public isChecklist = true;

  @Prop()
  public isLoanInactive: any;

  public isLoanCompleted = false;
  public voeVendorList: any = [];
  public verbalAuthNeeded = true;
  public empId = "";
  @Prop()
  public reOrderCount: any;
  public countToSendOnServer: any = null;
  public isVoeOrderPlacedOnce = false;
  public sentEmailsOnceTo: any = [];

  /**
   * Taking dsData variable for checking form data is changed or not only for comaprison
   */
  public dsData: any = {};
  /**************************************************************** */
  @Prop()
  public isTaskDisabled: any;
  public selectedVoeData: any = null;
  // public selectedRefId: any = null

  public openConfirmationPopup(data) {
    this.isComplete = false;
    this.selectedVoeData = data;
    this.$modal.show("OrderConfirmation");
  }

  public openSendMailToBorrowerPopup(data) {
    this.selectedVoeData = data;
    this.$modal.show("SendMailToBorrowerConfirmation");
  }

  public cancel() {
    try {
      this.$modal.hide("SendMailToBorrowerConfirmation");
      this.getEmployerInfo();
    } catch (error) {
      console.log(error);
    }
  }

  public async performSomeTask() {
    if (this.isVoeOrderPlacedOnce) return;
    await this.$modal.hide("OrderConfirmation");
    let data = await this.voeDetail.employmentInfo.find(
      (e) => e.refId.toString() === this.selectedVoeData.refId
    );
    if (data.voeVendorId) await this.saveVoeInfo(this.selectedVoeData.refId);
    else await this.sendMailOrFaxToEmployerOrBorrower(this.selectedVoeData);
    this.selectedVoeData = null;
  }

  public async resetVerificationOrderData() {
    let index = await this.voeDetail.employmentInfo.findIndex(
      (e) => e.refId.toString() === this.selectedVoeData.refId
    );
    this.voeDetail.employmentInfo[index].orderPlaced = false;
    await this.$modal.hide("OrderConfirmation");
    this.selectedVoeData = null;
  }

  public async checkSaveValidation() {
    try {
      let hasNoData = true;
      let valueIsNotSame = false;
      for (let i = 0; i < this.voeDetail.employmentInfo.length; i++) {
        const e = this.voeDetail.employmentInfo[i];
        if (e.option != this.dsData.employmentInfo[i].option)
          valueIsNotSame = true;
        else if (e.option) hasNoData = false;
      }
      if (
        !this.voeDetail.orderPlaced &&
        this.voeDetail.orderPlaced == this.dsData.orderPlaced &&
        !valueIsNotSame &&
        hasNoData
      )
        return true;
      else return false;
    } catch (error) {
      console.log(error);
    }
  }

  public async getEmployerInfo() {
    this.callLoader(true, null);
    try {
      const response = await Axios.get(
        BASE_API_URL +
          "processor/getEmployerInfo/" +
          this.loanId +
          "/" +
          this.taskId +
          "/" +
          this.countToSendOnServer,
        {
          headers: authHeader(),
        }
      );

      if (response.status === 201) {
        this.isLoanCompleted = response.data.isCompleted;
        this.showHistoryBtn = response.data.showHistoryBtn;
        this.showReOrderBtn = response.data.showReOrderBtn;
        this.voeDetail = JSON.parse(JSON.stringify(response.data.voeOrder));
        this.dsData = JSON.parse(JSON.stringify(response.data.voeOrder));
        await this.fetchVoeThirdPartyData();
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public fetchVoeThirdPartyData() {
    if (this.voeDetail.employmentInfo.length > 0) {
      this.voeDetail.employmentInfo.forEach((k) => {
        if (k.voeVendorId) {
          k.selectedCompany = this.voeVendorList.find(
            (e) => e._id == k.voeVendorId
          );
        }
      });
    }
  }

  public async saveVoeInfo(refId) {
    //  if (this.isOrderPlacedOnce) return;
    this.callLoader(true, null);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getVoeVendorList();
        return;
      }
      if (this.isComplete) {
        // let orederInfo = this.voeDetail.employmentInfo.find(
        //   (e) => !e.orderPlaced
        // );
        // console.log(orederInfo,'orederInfo');

        let noInfo = this.voeDetail.employmentInfo.filter((e) => e.option);

        if (noInfo.length == 0) {
          this.errorObj.show = true;
          this.errorObj.msg =
            "Please Provide atleast one Employment Information";
          this.callLoader(false, null);
          return;
        }
        if (noInfo.length > 0) {
          if (noInfo.find((e) => !e.orderPlaced)) {
            this.errorObj.show = true;
            this.errorObj.msg = "Order is not placed.";
            this.callLoader(false, null);
            return;
          }
        }
        let halfInfo = this.voeDetail.employmentInfo.find(
          (e) =>
            e.option == "thirdParty" &&
            e.typeOfVerification == "VerbalAuth" &&
            !e.verbalAuthReceived
        );
        if (halfInfo) {
          this.errorObj.show = true;
          this.errorObj.msg = `Please provide all information about ${halfInfo.name}.`;
          this.callLoader(false, null);
          return;
        } else if (!this.allDocs.borrowerAuthDoc) {
          this.errorObj.show = true;
          this.errorObj.msg = "Please Upload Borrower Authorization.";
          this.callLoader(false, null);
          return;
        } else if (
          this.allDocs.borrowerAuthDoc &&
          !this.allDocs.borrowerAuthDoc.docApproved
        ) {
          this.errorObj.show = true;
          this.errorObj.msg = "Borrower Authorization is not approved.";
          this.callLoader(false, null);
          return;
        }
      }
      if (!this.isComplete) {
        let notValid1 = await this.checkSaveValidation();
        if (notValid1) {
          this.callLoader(false, null);
          this.$snotify.info("No Changes");
          return;
        }
      }

      let emptyEmailData = this.voeDetail.employmentInfo.filter(
        (e) => e.option == "email" && (e.email == null || e.email == "")
      );
      let emptyFaxData = this.voeDetail.employmentInfo.filter(
        (e) => e.option == "fax" && (e.fax == null || e.fax == "")
      );
      let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      let checkvalidEmailData = this.voeDetail.employmentInfo.filter(
        (e) => e.option == "email" && !e.email.match(mailformat)
      );
      console.log("checkvalidEmailData1", checkvalidEmailData);

      if (
        this.isComplete &&
        (emptyFaxData.length > 0 || emptyEmailData.length > 0)
      ) {
        this.errorObj.show = true;
        this.errorObj.msg = "Please fill empty field";
        this.callLoader(false, null);
        return;
      }
      // if (this.isComplete && checkvalidEmailData.length > 0) {
      //   this.errorObj.show = true;
      //   this.errorObj.msg = "Please Enter Valid Email ";
      //   this.callLoader(false, null);
      //   return;
      // }

      //           this.callLoader(false, null);
      // return;
      // if (this.isComplete) this.isOrderPlacedOnce = true;
      const response = await Axios.post(
        BASE_API_URL + "processor/saveVoeInfo/",
        {
          voeDetail: this.voeDetail,
          loanId: this.loanId,
          taskId: this.taskId,
          reOrderCount: this.countToSendOnServer,
          isComplete: this.isComplete,
          refId: refId,
        },
        {
          headers: authHeader(),
        }
      );

      if (!this.isComplete && this.showToasty) {
        this.$snotify.success("WVOE Order Information has been saved");
        this.isOrderPlacedOnce = false;
      }
      if (response.status === 201 && this.isComplete)
        this.$snotify.success(response.data.message);
      if (this.isComplete) this.$emit("refresh-task-list", true);
      await this.getEmployerInfo();
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async refreshAfterAddingVendor() {
    await this.getVoeVendorList();
    for (let i = 0; i < this.voeDetail.employmentInfo.length; i++) {
      const e = this.voeDetail.employmentInfo[i];
      if (e.voeVendorId == "AddOther") e.voeVendorId = null;
    }
  }

  public async uploadFile(event, docName) {
    if (this.isTaskDisabled) {
      this.callLoader(false, null);
      this.$snotify.info("Task is not assigned to you.");
      this.getVoeVendorList();
      return;
    }
    docName == "Borrower Authorization"
      ? (this.bImgLoader = true)
      : (this.cCImgLoader = true);
    try {
      if (event.target.files.length <= 0) return;
      const formData: any = new FormData();
      Array.from(event.target.files).forEach((element) => {
        formData.append("file", element);
      });
      let loanId: any = this.$route.query.id;
      formData.append("loanId", loanId);
      formData.append("taskId", this.taskId);
      formData.append("docName", docName);
      const response = await Axios.post(
        BASE_API_URL + "processor/uploadBorrowerAuthorization",
        formData,
        { headers: authHeader() }
      );
      if (response.status === 201) this.$snotify.success(response.data.message);
      docName == "Borrower Authorization"
        ? (this.allDocs.borrowerAuthDoc = response.data.doc)
        : (this.allDocs.creditCardDoc = response.data.doc);
    } catch (error) {
      console.log(error);
    }
    docName == "Borrower Authorization"
      ? (this.bImgLoader = false)
      : (this.cCImgLoader = false);
  }

  // public async uploadCreditCardAut(event, docName) {
  //   if (this.isTaskDisabled) {
  //     this.callLoader(false, null);
  //     this.$snotify.info("Task is not assigned to you.");
  //     this.getVoeVendorList();
  //     return;
  //   }
  //   this.cCImgLoader = true;
  //   try {
  //     if (event.target.files.length <= 0) return;
  //     const formData: any = new FormData();
  //     Array.from(event.target.files).forEach((element) => {
  //       formData.append("file", element);
  //     });
  //     let loanId: any = this.$route.query.id;
  //     formData.append("loanId", loanId);
  //     formData.append("taskId", this.taskId);
  //     formData.append("docName", docName);
  //     const response = await Axios.post(
  //       BASE_API_URL + "processor/uploadCreditCardAut",
  //       formData,
  //       { headers: authHeader() }
  //     );
  //     if (response.status === 201) this.$snotify.success(response.data.message);
  //     this.allDocs.creditCardDoc = response.data.doc;
  //     this.gettVoeOrderDocument();
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   this.cCImgLoader = false;
  // }

  public async viewDocument(document) {
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getVoeVendorList();
        return;
      }
      document.loanId = this.$route.query.id;
          document.isRejected = this.isRejected,
        document.isAccepted = this.isAccepted,
       document.isChecklist = this.isChecklist,
      
      this.$refs.ref["showFile"](document);
    } catch (error) {
      console.log(error);
    }
  }

  public async viewAttachment(attachments) {
    this.$refs.ref["viewAttachment"]({
      attachments: attachments,
      loanId: this.$route.query.id,
    });
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public async getVoeVendorList() {
    this.callLoader(true, null);
    try {
      const response = await Axios.get(
        BASE_API_URL + "processor/getVoeVendorList/" + this.loanId,
        {
          headers: authHeader(),
        }
      );
      if (response.status === 201) {
        this.voeVendorList = response.data;
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async voeDownloadPDF(refId, borrowerId) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "processor/voeDownloadPDF",
        {
          loanId: this.$route.query.id,
          taskId: this.taskId,
          voeOrder: this.voeDetail,
          refId: refId,
          borrowerId: borrowerId,
        },
        {
          headers: authHeader(),
        }
      );
      if (response.status == 201) {
        this.$refs.PDFRef["showFile"]({
          content: response.data.pdfUrl,
          name: "WVOE",
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  public resetOrderPlace() {
    for (let i = 0; i < this.voeDetail.employmentInfo.length; i++) {
      const e = this.voeDetail.employmentInfo[i];
      if (e.refId == this.empId) e.orderPlaced = null;
      this.isOrderPlacedOnce = false;
    }
  }

  public openModalOnChange(event, eDetail) {
    if (this.isTaskDisabled) {
      this.callLoader(false, null);
      this.$snotify.info("Task is not assigned to you.");
      this.getVoeVendorList();
      return;
    }
    if (event.target.value == "AddOther") {
      this.$modal.show("AddOtherVendor");
      eDetail.selectedCompany = null;
    } else {
      eDetail.selectedCompany = this.voeVendorList.find(
        (e) => e._id.toString() == eDetail.voeVendorId.toString()
      );
    }
  }

  public async onchangeOfVerificationRequired(event, i) {
    if (event.target.value == "thirdParty") {
      this.voeDetail.employmentInfo[i].fax = null;
      this.voeDetail.employmentInfo[i].email = null;
    } else if (event.target.value == "email" || event.target.value == "fax") {
      this.voeDetail.employmentInfo[i].selectedCompany = null;
      this.voeDetail.employmentInfo[i].voeVendorId = null;
      this.voeDetail.employmentInfo[i].typeOfVerification = null;
      this.voeDetail.employmentInfo[i].CreditCardAuthNeeded = false;
      this.voeDetail.employmentInfo[i].mailSentToBorrowerForCredit = false;
      this.voeDetail.employmentInfo[i].mailSentToBorrowerForverbalAuth = false;

      this.voeDetail.employmentInfo[i].verbalAuthReceived = false;
      event.target.value == "email"
        ? (this.voeDetail.employmentInfo[i].fax = null)
        : (this.voeDetail.employmentInfo[i].email = null);
    }
  }

  public async isBrokerNotifiedForRejectedDocs() {
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getVoeVendorList();
        return;
      }
      let res = await Axios.get(
        BASE_API_URL +
          "loanChecklist/isBrokerNotifiedForRejectedDocs/" +
          this.$route.query.id +
          "/" +
          this.taskId +
          "/" +
          null,
        {
          headers: authHeader(),
        }
      );
      if (res.status == 201) {
        this.notifyBroker = res.data.notifyBorker;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async checkValidationWhileOrdering(empInfo) {
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getVoeVendorList();
        return;
      }
      if (empInfo) {
        if (empInfo.option == "email") {
          if (empInfo.email) {
            let emailRegex =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!empInfo.email.match(emailRegex)) {
              empInfo.hideOrderPlaced = false;
            } else {
              empInfo.hideOrderPlaced = true;
            }
          } else empInfo.hideOrderPlaced = false;
        } else if (empInfo.option == "fax") {
          if (empInfo.fax) {
            let faxRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
            if (!empInfo.fax.match(faxRegex)) {
              empInfo.hideOrderPlaced = false;
            } else {
              empInfo.hideOrderPlaced = true;
            }
          } else empInfo.hideOrderPlaced = false;
        } else empInfo.hideOrderPlaced = false;
      }

      return true;
    } catch (error) {
      console.log(error);
    }
  }

  public async sendMailOrFaxToEmployerOrBorrower(edata) {
    try {
      this.isComplete = false;
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getVoeVendorList();
        return;
      }

      let emptyEmailData = this.voeDetail.employmentInfo.filter(
        (e) => e.option == "email" && (e.email == null || e.email == "")
      );
      let emptyFaxData = this.voeDetail.employmentInfo.filter(
        (e) => e.option == "fax" && (e.fax == null || e.fax == "")
      );
      let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      let checkvalidEmailData = this.voeDetail.employmentInfo.filter(
        (e) => e.option == "email" && !e.email.match(mailformat)
      );
      let faxformat =/^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;

      let checkvalidFaxData = this.voeDetail.employmentInfo.filter(
        (e) => e.option == "fax" && !e.fax.match(faxformat)
      );

      if (
        !this.isComplete &&
        (emptyFaxData.length > 0 || emptyEmailData.length > 0)
      ) {
        this.errorObj.show = true;
        this.errorObj.msg = "Please fill empty field";
        this.callLoader(false, null);
        edata.orderPlaced = false;
        return;
      }
      else if (!this.isComplete && checkvalidEmailData.length > 0) {
        this.errorObj.show = true;
        this.errorObj.msg = "Please Enter Valid Email ";
                edata.orderPlaced = false;

        this.callLoader(false, null);
        return;
      } else if (!this.isComplete && checkvalidFaxData.length > 0) {
        this.errorObj.show = true;
        this.errorObj.msg = "Please Enter Valid Fax ";
        edata.orderPlaced = false;

        this.callLoader(false, null);
        return;
      }
      else if (
        (edata.option == "thirdParty" &&
          (edata.mailSentToBorrowerForCredit ||
            edata.mailSentToBorrowerForverbalAuth)) ||
        edata.option == "email" ||
        edata.option == "fax"
      ) {
        this.isComplete = false;
        await this.saveVoeInfo(edata.refId);

        const response = await Axios.post(
          BASE_API_URL + "processor/sendMailOrFaxToEmployerOrBorrower/",
          {
            refId: edata.refId,
            loanId: this.loanId,
            taskId: this.taskId,
            borrowerId: edata.borrowerId,
            reOrderCount: this.countToSendOnServer,
          },
          {
            headers: authHeader(),
          }
        );
        if (response.status == 201)
          this.$snotify.success(response.data.message);
        this.showToasty = true;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async gettVoeOrderDocument() {
    this.callLoader(true, 1);
    try {
      let response = await Axios.get(
        BASE_API_URL + "processor/gettVoeOrderDocument/" + this.$route.query.id,
        {
          headers: authHeader(),
        }
      );

      if (response.status === 201) {
        this.allDocs = response.data;
        console.log(this.allDocs, "this.allDocs");
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, 1);
  }

  public async resetEmployerData() {
    this.callLoader(true, 1);
    try {
      if (!this.selectedEmployer) {
        this.callLoader(false, null);
        return;
      }
      let response = await Axios.post(
        BASE_API_URL + "processor/resetEmployerData/",
        {
          loanId: this.loanId,
          refId: this.selectedEmployer,
          taskId: this.taskId,
          voeDetail: this.voeDetail,
          isComplete: false,
        },
        {
          headers: authHeader(),
        }
      );
      if (response.status === 201)
        this.$snotify.success("Data Reset successfully.");
      await this.getEmployerInfo();
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async reOrderWVOE() {
    this.callLoader(true, 1);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getEmployerInfo();
        return;
      }
      this.$refs.refReOrder["confitmationBox"]({
        message: "Are you sure you want to RE-Order WVOE?",
        apiPath: `processor/reOrderWVOE`,
        data: {
          loanId: this.$route.query.id,
          taskId: this.taskId,
          voeDetail: this.voeDetail,
        },
      });
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async showHistory() {
    this.callLoader(true, 1);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getEmployerInfo();
        return;
      }
      this.$refs.refReOrder["showHistoryOfVerificationTask"](
        this.taskId,
        "WVOE Order"
      );
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async allMountFunction(count) {
    this.countToSendOnServer = count;
    await this.gettVoeOrderDocument();
    await this.getVoeVendorList();
    await this.isBrokerNotifiedForRejectedDocs();
    await this.getEmployerInfo();
  }

  public async mounted() {
    this.allMountFunction(this.reOrderCount);
  }
}
