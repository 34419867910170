
import { Component, Vue, Prop } from "vue-property-decorator";
import {
  Liabilities,
  OtherLiabilities,
} from "../../../models/urla/financial-info.model";
import Multiselect from "vue-multiselect";
import Axios from "axios";
import { BASE_API_URL, EventBus } from "../../../config";
import { authHeader } from "../../../services/auth";
import {
  LIABILITY_ACCOUNT_TYPE,
  OTHER_LIABILITIES_AND_EXPENSES,
} from "../../../constants/constants";
@Component({ components: { Multiselect } })
export default class Liability extends Vue {
  /* eslint-disable */
  public liabilities = [new Liabilities()];
  public otherLiabilityAndExpenses = [new OtherLiabilities()];
  public liabilitiesData = [new Liabilities()];
  public otherLiabilityAndExpensesData = [new OtherLiabilities()];
  public loanId = this.$route.query.id;
  public accountTypes = LIABILITY_ACCOUNT_TYPE;
  public otherLiabilitiesAndExpenses = OTHER_LIABILITIES_AND_EXPENSES;
  public borrowers = [];
  public total: any = {};
  public doesNotApplyForLiability: any = null;
  public doesNotApplyForOtherLiability: any = null;
  public doesNotApplyForLiabilityData: any = null;
  public doesNotApplyForOtherLiabilityData: any = null;
  public count = 0;
  public subtract = false;
  public changedValues: any = [];
  @Prop()
  public validationData: any;
  @Prop()
  public isLoanInactive: any;

  public hasAnychangedValues() {
    return this.changedValues;
  }

  public async getLiabilitiesData() {
    try {
      this.changedValues = [];
      this.callLoader(true, null);
      let response = await Axios.post(
        BASE_API_URL + "urla/getLiabilitiesData",
        {
          loanId: this.loanId,
        },
        {
          headers: authHeader(),
        }
      );
      this.count = 0;
      this.liabilities = response.data.liabilities
        ? response.data.liabilities
        : [new Liabilities()];
      this.otherLiabilityAndExpenses = response.data.otherLiabilityAndExpenses
        ? response.data.otherLiabilityAndExpenses
        : [new OtherLiabilities()];
      this.borrowers = response.data.borrowers;
      this.doesNotApplyForLiability = response.data.doesNotApplyForLiability;
      this.doesNotApplyForOtherLiability =
        response.data.doesNotApplyForOtherLiabilityAndExpenses;
      this.liabilitiesData = JSON.parse(JSON.stringify(this.liabilities));
      this.otherLiabilityAndExpensesData = JSON.parse(
        JSON.stringify(this.otherLiabilityAndExpenses)
      );
      this.doesNotApplyForLiabilityData = this.doesNotApplyForLiability;
      this.doesNotApplyForOtherLiabilityData =
        this.doesNotApplyForOtherLiability;
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public addAndRemoveLiability(isAdd, index) {
    isAdd
      ? this.liabilities.push(new Liabilities())
      : this.liabilities.splice(index, 1);
    if (!isAdd) {

       if (index == 0 && this.liabilities.length == 0)  {
        this.doesNotApplyForLiability = true;
    this.liabilities = [new Liabilities()];
      }
      this.calculateUnpaidBalance();
      this.calculateMonthlyPayment();
      this.calculatePaidOff();
    }
  }

  public addAndRemoveOtherLiabilities(isAdd, index) {
    isAdd
      ? this.otherLiabilityAndExpenses.push(new OtherLiabilities())
      : this.otherLiabilityAndExpenses.splice(index, 1);
    if (!isAdd) {
       if (index == 0 && this.otherLiabilityAndExpenses.length == 0)  {
        this.doesNotApplyForOtherLiability = true;
        this.otherLiabilityAndExpenses = [new OtherLiabilities()];
      }
      this.calculateOtherExpensesAndLiabilities();
    }
  }

  public async saveAndUpdateLiabilities() {
    try {
      this.liabilities.forEach((a) => {
        a.borrowerId.map((b: any) => {
          b.id = b.borrowerId;
        });
      });
      this.otherLiabilityAndExpenses.forEach((o) => {
        o.borrowerId.map((b: any) => {
          b.id = b.borrowerId;
        });
      });
      let response = await Axios.post(
        BASE_API_URL + "urla/saveAndUpdateLiabilities",
        {
          loanId: this.loanId,
          liabilities: this.liabilities,
          otherLiabilityAndExpenses: this.otherLiabilityAndExpenses,
          doesNotApplyForLiability: this.doesNotApplyForLiability,
          doesNotApplyForOtherLiability: this.doesNotApplyForOtherLiability,
          changedValues: this.changedValues,
        },
        {
          headers: authHeader(),
        }
      );
      if ((response.status = 200) && this.count > 0)
        this.$snotify.success("Liabilities Information has been Updated");
      await this.getLiabilitiesData();
      this.$emit("referesh-tab", true);
      EventBus.$emit("refesh-loaninfo");
    } catch (error) {
      console.log(error);
    }
  }

  public async handleChange(currentVal, previousVal, fieldName) {
    if (previousVal !== undefined) {
      const index = this.changedValues.findIndex((c) => c.name === fieldName);
      if (index !== -1) {
        if (JSON.stringify(currentVal) === JSON.stringify(previousVal)) {
          this.changedValues.splice(index, 1);
        } else {
          let data = this.changedValues.find((v) => v.name === fieldName);
          data.from = previousVal;
          data.to = currentVal;
        }
      } else {
        this.changedValues.push({
          name: fieldName,
          from: previousVal,
          to: currentVal,
        });
      }
    }
  }

  public calculateOtherExpensesAndLiabilities() {
    this.total.totalOtherExpenses = 0;
    if (this.otherLiabilityAndExpenses.length > 0) {
      this.otherLiabilityAndExpenses.forEach((ol: any) => {
        this.total.totalOtherExpenses =
          this.total.totalOtherExpenses + ol.monthlyPayment;
      });
    }
    this.total = JSON.parse(JSON.stringify(this.total));
  }

  public calculateUnpaidBalance() {
    this.total.totalUnpaidBalance = 0;
    if (this.liabilities.length > 0) {
      this.liabilities.forEach((l) => {
        this.total.totalUnpaidBalance =
          this.total.totalUnpaidBalance + l.unpaidBalance;
      });
    }
    this.total = JSON.parse(JSON.stringify(this.total));
  }

  public calculateMonthlyPayment() {
    this.total.totalMonthlyPayment = 0;
    if (this.liabilities.length > 0) {
      this.liabilities.forEach((l) => {
        this.total.totalMonthlyPayment =
          this.total.totalMonthlyPayment + l.monthlyPayment;
        if (l.toBePaidOff || l.toBePaidOffBeforeClosing || l.isOmit) {
          this.total.totalMonthlyPayment =
            this.total.totalMonthlyPayment - l.monthlyPayment;
        }
      });
    }
    this.total = JSON.parse(JSON.stringify(this.total));
  }

  public calculatePaidOff() {
    this.total.totalPaidOff = 0;
    this.liabilities.forEach((l) => {
      if (l.toBePaidOff) {
        this.total.totalPaidOff = this.total.totalPaidOff + l.unpaidBalance;
        if (this.total.totalMonthlyPayment > 0)
          this.total.totalMonthlyPayment =
            this.total.totalMonthlyPayment - l.monthlyPayment;
      }
    });
    this.total = JSON.parse(JSON.stringify(this.total));
    this.total.totalMonthlyPayment =
      Math.round(this.total.totalMonthlyPayment * 100) / 100;
  }

  public calMonthlypayment(index) {
    let l = this.liabilities[index];
    console.log(l,'test');
    
   if (
      (l.toBePaidOff && l.toBePaidOffBeforeClosing && !l.isOmit) ||
      (!l.toBePaidOff && l.toBePaidOffBeforeClosing && l.isOmit) ||
      (l.toBePaidOff && !l.toBePaidOffBeforeClosing && l.isOmit)
    ){
    this.total.totalMonthlyPayment = this.total.totalMonthlyPayment;

    }
    else  if (
      (l.toBePaidOff && !l.toBePaidOffBeforeClosing && !l.isOmit) ||
      (!l.toBePaidOff && l.toBePaidOffBeforeClosing && !l.isOmit) ||
      (!l.toBePaidOff && !l.toBePaidOffBeforeClosing && l.isOmit)
    ) {
      this.total.totalMonthlyPayment =
        this.total.totalMonthlyPayment - l.monthlyPayment;
    }
    else if (!l.toBePaidOff && !l.toBePaidOffBeforeClosing && !l.isOmit) {
      this.total.totalMonthlyPayment =
        this.total.totalMonthlyPayment + l.monthlyPayment;

    }
  }

  public calcPaidOff(index) {
    let l = this.liabilities[index];
    if (l.toBePaidOff) {
      this.total.totalPaidOff = this.total.totalPaidOff + l.unpaidBalance;

      // if (this.total.totalMonthlyPayment > 0) {
      //   this.total.totalMonthlyPayment =
      //     this.total.totalMonthlyPayment - l.monthlyPayment;
      // }
    } else {
      if (this.total.totalPaidOff > 0) {
        this.total.totalPaidOff = this.total.totalPaidOff - l.unpaidBalance;
        // this.total.totalMonthlyPayment =
        //   this.total.totalMonthlyPayment + l.monthlyPayment;
      }
    }
    this.total = JSON.parse(JSON.stringify(this.total));
  }

  public cashValue(total) {
    const totals = total.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return totals;
  }

  public checkAccountType(liability) {
    if (this.validationData) {
      if (
        !liability.accountType.name &&
        (liability.unpaidBalance ||
          liability.monthlyPayment ||
          liability.accountNumber ||
          liability.companyName ||
          liability.borrowerId.length != 0)
      ) {
        return true;
      }
    } else {
      return false;
    }
  }

  public checkAccountOtherType(liability) {
    if (this.validationData) {
      if ((liability.accountType.name = "Other"))
        if (
          (!liability.accountType.description && liability.unpaidBalance) ||
          liability.monthlyPayment ||
          liability.accountNumber ||
          liability.companyName ||
          liability.borrowerId.length != 0
        )
          return true;
    } else {
      return false;
    }
  }

  public checkAccountNumber(liability) {
    if (this.validationData) {
      if (
        !liability.accountNumber &&
        (liability.unpaidBalance ||
          liability.monthlyPayment ||
          liability.accountType.name ||
          liability.companyName ||
          liability.borrowerId.length != 0)
      )
        return true;
    } else {
      return false;
    }
  }

  public checkCompanyName(liability) {
    if (this.validationData) {
      if (
        !liability.companyName &&
        (liability.unpaidBalance ||
          liability.monthlyPayment ||
          liability.accountNumber ||
          liability.accountType.name ||
          liability.borrowerId.length != 0)
      )
        return true;
    } else {
      return false;
    }
  }

  public checkUnpaidBalance(liability) {
    if (this.validationData) {
      if (
        !liability.unpaidBalance &&
        (liability.companyName ||
          liability.monthlyPayment ||
          liability.accountNumber ||
          liability.accountType.name ||
          liability.borrowerId.length != 0)
      )
        return true;
    } else {
      return false;
    }
  }

  public checkMonthlyPayment(liability) {
    if (this.validationData) {
      if (
        !liability.monthlyPayment &&
        (liability.companyName ||
          liability.unpaidBalance ||
          liability.accountNumber ||
          (liability.accountType.name && liability.accountType.name) ||
          liability.borrowerId.length != 0)
      )
        return true;
    } else {
      return false;
    }
  }

  public initializeAccountTypeValue(liability) {
    if (liability.accountType && liability.accountType.name != "Other") {
      liability.accountType.description = null;
    }
  }

  public initializeOtherLiabilityValue(otherLiability) {
    if (
      otherLiability.expenseType &&
      otherLiability.expenseType.name != "Other"
    ) {
      otherLiability.expenseType.description = null;
    }
  }

  public initializeOtherLiabilityData() {
   // this.otherLiabilityAndExpenses = [new OtherLiabilities()];
  }

  public checkBorrower(liability) {
    if (this.validationData) {
      if (
        liability.borrowerId.length == 0 &&
        (liability.companyName ||
          liability.unpaidBalance ||
          liability.accountNumber ||
          (liability.accountType.name && liability.accountType.name) ||
          liability.monthlyPayment)
      )
        return true;
    } else {
      return false;
    }
  }

  public initializeliabilityData() {
    //this.liabilities = [new Liabilities()];
  }

  public checkForValidation() {
    this.$emit("call-mount", true);
  }

  public checkValidationForMobile() {
    EventBus.$emit("check-validation", true);
  }

  public async mounted() {
    await this.getLiabilitiesData();
    await this.calculateUnpaidBalance();
    await this.calculateMonthlyPayment();
    await this.calculatePaidOff();
    await this.calculateOtherExpensesAndLiabilities();
    this.validationData ? this.checkForValidation() : "";
  }
}
