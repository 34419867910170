
import { Component, Prop, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL, EventBus } from "../config";
import { authHeader } from "../services/auth";
import AgentComponent from "./AgentComponent.vue";

@Component({
  components: {
    AgentComponent,
  },
})
export default class LoanContact extends Vue {
  /* eslint-disable */
  public loanContacts: any = [];
  public agentTypes: any = [];
  public selectedAgent: any = "0";
  public key: any = null;
  public isFloodInsuranceRequired: any = null;
  public sameAsHazardInsurance: any = null;
  @Prop()
  public isLoanInactive: any;
  public isEdit = false;

  public async getLoanContacts() {
    this.$store.state.tmpcoLoader = true;
    try {
      const response = await Axios.get(
        `${BASE_API_URL}borrower/dashboard/getLoanContacts/${this.$route.query.id}`,
        { headers: authHeader() }
      );
      if (response.status === 201) {
        this.loanContacts = response.data.loanContacts;
        this.isFloodInsuranceRequired = response.data.isFloodInsuranceRequired;
        this.sameAsHazardInsurance = response.data.sameAsHazardInsurance;
      }
    } catch (error) {
      console.log(error);
    }
    this.$store.state.tmpcoLoader = false;
  }

  public async getAgentTypes() {
    let res = await Axios.get(
      BASE_API_URL + "agent/getAgentTypes/" + this.$route.query.id,
      {
        headers: authHeader(),
      }
    );
    this.agentTypes = res.data.agentTypes ? res.data.agentTypes : [];
  }

  public cancel() {
    this.selectedAgent = "0";
    this.$modal.hide("addContact");
  }

  public async refreshList() {
    this.$modal.hide("addContact");
    await this.getLoanContacts();
    this.getAgentTypes();
    this.selectedAgent = "0";
  }

  public getSelectedAgent() {
    this.key = this.selectedAgent.id;
  }

  public editFloodInsuraceAgent(agent) {
    this.selectedAgent = agent;
    this.$modal.show("addContact");
  }

  public async editRefreshList() {
    this.$modal.hide("editContact");
    await this.getLoanContacts();
    this.getAgentTypes();
    this.selectedAgent = "0";
  }
  public editContact(agent) {
    this.selectedAgent = agent;
    this.isEdit = true;
    this.$modal.show("editContact");
  }
  public editCancel() {
    this.$modal.hide("editContact");
    this.selectedAgent = "0";
  }

  public deleteContacts(agent) {
    this.selectedAgent = this.loanContacts.find(
      (l) => l.contactData && l.contactData._id === agent.contactData._id
    );
    this.$modal.show("deleteConfirmationModal");
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public async deleteAgentContact() {
    this.callLoader(true, 2);
    try {
      const res = await Axios.post(
        BASE_API_URL + "agent/deleteAgentContact/",
        {
          loanId: this.$route.query.id,
          agentId: this.selectedAgent.contactData._id,
        },
        {
          headers: authHeader(),
        }
      );
      if (res.status === 201) {
        this.$modal.hide("deleteConfirmationModal");
        await this.getLoanContacts();
    this.selectedAgent = "0";
        this.$snotify.success("Agent Contact Deleted Successfully");
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  async mounted() {
    await this.getLoanContacts();
    await this.getAgentTypes();

    EventBus.$on("loanContacts", () => {
      this.getLoanContacts();
    });
  }
  /* eslint-disable */
}
