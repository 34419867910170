
  import { Component, Vue, Prop } from "vue-property-decorator";
  import Axios from "axios";
  import { BASE_API_URL } from "../../../config";
  import { authHeader } from "../../../services/auth";
  import ViewDocument from "../../../common-components/ViewDocument.vue";
  @Component({
    components: {
      ViewDocument,
    // CommonNotifyOnRejection,
    // FollowUpTask,
  },
})
  export default class HazardBinderReceived extends Vue {
    /* eslint-disable */
    @Prop()
    public taskId: any;
  // @Prop()
  // public isLoanInactive: any;
  public loanId: any = this.$route.query.id;
  public ibtsRecieved: any = {};
  public isCompleted = false;
  public docLoader = false;
  public error: any = {
    show: false,
    msg: null,
  };
  // @Prop()
  // public reOrderCount: any;
  // public countToSendOnServer: any = null;
  public showHistoryBtn = false;
  @Prop()
  public secondAppraisalCount: any;
  public secondAppraisalCountForServer: any = null;
  @Prop()
  public totalTaskCount: any;
  public totalTaskCountForServer: any = null;
  public dsData: any = {};
  @Prop()
  public isTaskDisabled: any;
  public isOrderPlacedOnce = false;

  public saveValidation() {
    if (this.dsData.isIbtsRecieved == this.ibtsRecieved.isIbtsRecieved)
      return true;
    else return false;
  }

  public async uploadReport(event) {
    this.docLoader = true;
    try {
      if (this.isTaskDisabled) {
        this.docLoader = false;
        this.$snotify.info("Task is not assigned to you.");
        return;
      }
      if (event.target.files.length <= 0) return;
      const formData: any = new FormData();
      Array.from(event.target.files).forEach((element) => {
        formData.append("file", element);
      });
      formData.append("loanId", this.loanId);
      formData.append("totalTaskCount", this.totalTaskCountForServer);
      // formData.append("reOrderCount", this.countToSendOnServer);
      formData.append(
        "secondAppraisalCount",
        this.secondAppraisalCountForServer
        );
      formData.append("taskId", this.taskId);
      const response = await Axios.post(
        BASE_API_URL + "processor/uploadIbtsReport",
        formData,
        { headers: authHeader() }
        );
      if (response.status === 201) this.$snotify.success(response.data.message);
      this.ibtsRecieved.loanChecklistData = response.data.loanChecklistData;
    } catch (error) {
      console.log(error);
    }
    this.docLoader = false;
  }

  public async saveIbtsRecievedTask(isComplete) {
    if(this.isOrderPlacedOnce) return
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        await this.getIbtsReceived();
        return;
      }
      if (!isComplete) {
        let notValid1 = await this.saveValidation();
        if (notValid1) {
          this.$snotify.info("No Changes");
          this.callLoader(false, null);
          return;
        }
      }

      if (isComplete && !this.ibtsRecieved.isIbtsRecieved) {
        this.error.show = true;
        this.error.msg = "All fields are Required.";
        this.callLoader(false, null);
        return;
      }
      if (isComplete && !this.ibtsRecieved.loanChecklistData) {
        this.error.show = true;
        this.error.msg = "Upload IBTS Report";
        this.callLoader(false, null);
        return;
      }
 if(isComplete) this.isOrderPlacedOnce=true;
      const response = await Axios.post(
        `${BASE_API_URL}processor/saveIbtsRecievedTask`,
        {
          ibtsRecieved: this.ibtsRecieved,
          taskId: this.taskId,
          loanId: this.$route.query.id,
          totalTaskCount: this.totalTaskCountForServer,
          // reOrderCount: this.countToSendOnServer,
          secondAppraisalCount: this.secondAppraisalCountForServer,
          isComplete: isComplete,
          formData: this.dsData,
        },
        {
          headers: authHeader(),
        }
        );

      if (response.status === 201) {
        if (isComplete)
          this.$snotify.success("IBTS Received details completed successfully");
        if (!isComplete && this.ibtsRecieved.isIbtsRecieved)
          this.$snotify.success("IBTS Received details saved successfully");

        if (isComplete) this.$emit("refresh-task-list", true);
        this.getIbtsReceived();
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async getIbtsReceived() {
    this.callLoader(true, null);
    try {
      const response = await Axios.post(
        BASE_API_URL + "processor/getIbtsReceived/",
        {
          loanId: this.$route.query.id,
          taskId: this.taskId,
          totalTaskCount: this.totalTaskCountForServer,
          // reOrderCount: this.countToSendOnServer,
          secondAppraisalCount: this.secondAppraisalCountForServer,
        },
        {
          headers: authHeader(),
        }
        );
      if (response.status === 201) {
        this.ibtsRecieved = response.data.taskData
        ? JSON.parse(JSON.stringify(response.data.taskData))
        : {};
        this.dsData = response.data.taskData
        ? JSON.parse(JSON.stringify(response.data.taskData))
        : {};
        this.ibtsRecieved.loanChecklistData = response.data.loanChecklistData;
        this.isCompleted = response.data.isCompleted;
        this.showHistoryBtn = response.data.showHistoryBtn;
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public async viewDocument(document) {
    try {
      document.loanId = this.$route.query.id;
      this.$refs.ref["showFile"](document);
    } catch (error) {
      console.log(error);
    }
  }

  public async showHistory() {
    this.callLoader(true, 1);
    try {
      this.$refs.refReOrder["showHistory"](this.taskId);
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async allMountFunction(count, secondAppraisalCount) {
    this.secondAppraisalCountForServer = secondAppraisalCount;
    this.totalTaskCountForServer = count;
    // this.countToSendOnServer = count;
    this.getIbtsReceived();
  }

  public async mounted() {
    await this.allMountFunction(this.totalTaskCount, this.secondAppraisalCount);
    // await this.allMountFunction(this.reOrderCount, this.secondAppraisalCount);
  }
}
