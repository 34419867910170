
import Axios from "axios";
import { BASE_API_URL } from "../../config";
import { Component, Vue, Prop } from "vue-property-decorator";
import { UNIT_TYPE } from "../../constants/constants";

@Component({
  components: {}
})
export default class UrlaAddress extends Vue {
  /* eslint-disable */
  @Prop()
  public address: any;
  @Prop()
  public addressData: any;
  @Prop()
  public propertyType: any;
  @Prop()
  public hasUnit: any;

  @Prop()
  public projectType: any;
  @Prop()
  public handleChange;
  @Prop()
  public fieldNamePrefix: any;
  @Prop()
  public subType: any
  @Prop()
  public validationData: any;

  public unit_types = UNIT_TYPE;

  public county = [];

  public stateAndCounty = [];

  public async getStatesAndCounty() {
    try {
      const response = await Axios.get(
        BASE_API_URL + "common/getStatesAndCounty"
      );
      this.stateAndCounty = response.data;
    } catch (error) {
      console.log(error);
    }
  }

  getCounty() {
    try {
      const county: any = this.stateAndCounty.find(
        (a: any) => a.code === this.address.state
      );
      return county && county.counties ? county.counties : null;
    } catch (error) {
      console.log(error);
    }
  }

 public checkForValidation() {
    this.$emit("call-mount", true);
  }


  mounted() {
    this.getStatesAndCounty();
        this.validationData ? this.checkForValidation() : "";

    // this.address.unit = this.address.unit ? Number(this.address.unit) : 0
    //  this.address.numberOfUnits = this.address.numberOfUnits ? Number(this.address.unit) : 0
    
  }
}
