var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"p-3"},[(_vm.documents.length > 0)?_c('div',{staticClass:"row mx-0"},_vm._l((_vm.documents),function(document,i){return _c('div',{key:i,staticClass:"mb-2 col-lg-6 col-md-6 px-2"},[_c('div',{staticClass:"card-content-border-dark"},[_c('div',{staticClass:"p-3 checklist-doc"},[_c('div',{staticClass:"d-flex align-content-center",staticStyle:{"flex-direction":"column"}},[_c('div',{staticClass:"d-flex align-content-center"},[(_vm.loanDetails.submissionDate)?_c('div',{staticClass:"custom-checkbox mb-0 mr-2"}):_vm._e(),_c('h6',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
                    title: document.count
                      ? `ReOrder Count ${document.count + 1}`
                      : '',
                  }),expression:"{\n                    title: document.count\n                      ? `ReOrder Count ${document.count + 1}`\n                      : '',\n                  }"}],staticClass:"card-content-title mb-2 mt-0 mb-0 top_2",domProps:{"innerHTML":_vm._s(_vm.getDocName(document))}})]),(
                  document &&
                  document.docFiles.length > 0 &&
                  document.docFiles[document.docFiles.length - 1].files
                    .length > 0 &&
                  document.isUploadedDocNotified &&
                  _vm.getDate(document)
                )?_c('div',{staticClass:"d-flex align-items-center"},[_c('h6',{staticClass:"card-content-title mb-2 mt-0 mb-0 top_2"},[_vm._v(" Uploaded On: "+_vm._s(_vm.$moment(document.lastUpdatedOn).format( "MM/DD/YYYY, h:mm:ss a" ))+" - "+_vm._s(document.docUploadedBy ? document.docUploadedBy : "Title Agent")+" ")])]):_vm._e(),(
                  (document.allRejectedComments &&
                    document.allRejectedComments.length > 0 &&
                    document.isRejectedDocNotified &&
                    _vm.$store.state.sessionObj.userType === 2) ||
                  (document.allRejectedComments &&
                    document.allRejectedComments.length > 0 &&
                    _vm.$store.state.sessionObj.userType === 3)
                )?_c('div',{staticClass:"d-flex align-items-flex"},[_c('div',{staticClass:"d-flex align-items-flex"},[_c('p',{staticClass:"rejected"},[_vm._v("Rejected")]),(
                      document.allRejectedComments &&
                      document.allRejectedComments.length > 0
                    )?_c('div',[_c('i',{staticClass:"fa fa-paperclip clip-size-color mr-2 ml-2",on:{"click":function($event){$event.preventDefault();_vm.isRejected = true;
                        _vm.isAccepted = false;
                        _vm.viewDocument(document);}}})]):_vm._e(),_c('p',{staticClass:"rejected-text mb-2"},[_vm._v(" "+_vm._s(document.msgString)+" ")])])]):_vm._e(),(document.docFiles.find((l) => l.status == 'Accept'))?_c('div',[_c('div',{staticClass:"d-flex align-items-center"},[_c('p',{staticClass:"accepted"},[_vm._v("Accepted")]),_c('i',{staticClass:"fa fa-paperclip clip-size-color1 mr-2 ml-2",on:{"click":function($event){$event.preventDefault();_vm.isRejected = false;
                      _vm.isAccepted = true;
                      _vm.viewDocument(document);}}}),_c('p',{staticClass:"accepted-text ml-2"},[_vm._v("-"+_vm._s(document.msgString1))])])]):_vm._e()]),_c('div',[_c('div',{staticClass:"d-flex align-items-center"},[(
                    _vm.loanDetails.idsHandledByTMPCO &&
                    document.category == 'DisclosureDocs' &&
                    document.docStatus == 'Required'
                  )?_c('i',{staticClass:"fa fa-exclamation-circle color-red mr-1"}):(
                    (_vm.loanDetails.disclosureSigned ||
                      _vm.$route.query.name == 'disclosure') &&
                    document.docStatus == 'Required'
                  )?_c('i',{staticClass:"fa fa-exclamation-circle color-red mr-1"}):(
                    (document.toBeUploadedBy.includes(2) ||
                      document.toBeUploadedBy.includes(4)) &&
                    document.docStatus == 'Required'
                  )?_c('i',{staticClass:"fa fa-exclamation-circle color-red mr-1",attrs:{"aria-hidden":"true"}}):_vm._e(),(document.isDocument)?_c('div',[(
                      document.docFiles &&
                      document.docFiles.length > 0 &&
                      ((!document.isUploadedDocNotified &&
                        _vm.$store.state.sessionObj.userType === 3) ||
                        (!document.isUploadedDocNotified &&
                          _vm.$store.state.sessionObj.userType === 2))
                    )?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"fa fa-clock-o clip-size orange_dark mr-2",attrs:{"title":"User has uploaded but not notified yet!"}}):_vm._e(),(
                      (document.isUploadedDocNotified &&
                        document.docFiles.length > 0 &&
                        document.docFiles[document.docFiles.length - 1]
                          .status == null &&
                        _vm.$store.state.sessionObj.userType === 3) ||
                      ((_vm.$store.state.sessionObj.userType === 1 ||
                        _vm.$store.state.sessionObj.userType === 2 ||
                        _vm.$store.state.sessionObj.userType === 4) &&
                        document.docFiles.length > 0 &&
                        document.docFiles[document.docFiles.length - 1]
                          .status == null)
                    )?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"fa fa-paperclip clip-size mr-2 ml-2",attrs:{"aria-hidden":"true"},on:{"click":function($event){$event.preventDefault();_vm.isRejected = false;
                      _vm.isAccepted = false;
                      _vm.isChecklist = true;
                      _vm.viewDocument(document);}}}):_vm._e(),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"upload_btn_wrapper"},[_c('button',{class:[
                        document.isUploading
                          ? 'btn-upload cursor-pointer cursor-not-allow'
                          : 'btn-upload cursor-pointer',
                      ],attrs:{"disabled":document.isUploading}},[(document.isUploading)?_c('div',{staticClass:"loader"},[_vm._v(" Loading... ")]):_vm._e(),_c('i',{staticClass:"fa fa-upload mr-1",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(document.isUploading ? "UPLOADING..." : "UPLOAD")+" ")]),_c('input',{class:[
                        _vm.isUploadedByProcessor(document) &&
                        document.name != 'Borrower Authorization'
                          ? 'btn-upload cursor-pointer cursor-not-allow'
                          : 'btn-upload cursor-pointer',
                      ],attrs:{"id":"'upload'+i","disabled":_vm.isUploadedByProcessor(document) &&
                        document.name != 'Borrower Authorization',"type":"file","name":"file-upload","multiple":""},on:{"change":function($event){return _vm.uploadDocument($event, document)}}}),(document.showValidation)?_c('div',{staticClass:"form-group mb-0 text-right"},[_c('span',{staticClass:"login-error show mb-0"},[_vm._v("Please upload the file")])]):_vm._e()])]):_vm._e()])])])])])}),0):_c('div',{staticClass:"d-flex justify-content-center align-items-center height__100"},[_c('p',{staticClass:"content-text"},[_vm._v("No Data")])])]),_c('ViewDocument',{ref:"ref",on:{"refresh-docs":function($event){return _vm.getThirdPartyDocuments()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }