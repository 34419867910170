
  import { Component, Vue, Prop } from "vue-property-decorator";
  import Axios from "axios";
  import { BASE_API_URL, EventBus } from "../../../config";
  import { authHeader } from "../../../services/auth";
  import { ChangOfCircumstances } from "../../../models/workflow/change-of-circumstance.model";
  @Component
  export default class ChangeOfCircumstance extends Vue {
    /* eslint-disable */
    public dataObj: any = new ChangOfCircumstances();

    @Prop()
    public taskId: any;
    @Prop()
    public isLoanInactive: any;
    public isTaskCompleted = false;
    public error: any = { show: false, msg: null };
    public valid = false;
    @Prop()
    public isTaskDisabled: any;
    public isOrderPlacedOnce = false;
   /**
   * Taking dsData variable for checking form data is changed or not only for comaprison
   */
   public dsData: any = {};
   /**************************************************************** */


   public async checkSaveValidation() {
    try {
      let mainDataHasData = await this.formHasData(JSON.parse(JSON.stringify(this.dataObj)))
      let valueIsNotSame = false
      for (const [key, value] of Object.entries(this.dataObj)) {
        for (const [key1, value1] of Object.entries(this.dsData)) {
          if (key != 'createdBy' && key != 'createdOn' && key != 'updatedBy' && key != 'updatedOn' && key != 'isCompleted') {
            if (key === key1 && value != value1) {
              valueIsNotSame = true
            }
          }
        }
      }
      if (!mainDataHasData && !valueIsNotSame) return true
        else return false;
    } catch (error) {
      console.log(error);
    }
  }

  public async saveChangeOfCircumstance(isComplete) {
        if(this.isOrderPlacedOnce) return

    this.callLoader(true, 1);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getChangeOfCircumstanceData();
        return;
      }
      if (!isComplete)  {
        let notValid1 = await this.checkSaveValidation();
        if (notValid1) {
          this.callLoader(false, null);
          this.$snotify.info('No Changes')
          return;
        }
      }

      if (isComplete && this.checkValForFields()) {
        console.log('coming')
        this.valid= true;
        this.error.msg = "Please fill at least one Information.";
        this.callLoader(false, null);
        return;
      }

      if(isComplete) this.isOrderPlacedOnce=true
      let response = await Axios.post(
        BASE_API_URL + "processor/saveChangeOfCircumstance",
        {
          loanId: this.$route.query.id,
          changeOfCircumstance: this.dataObj,
          taskId: this.taskId,
          isComplete: isComplete,
          formData: this.dsData
        },
        { headers: authHeader() }
        );
      if (!isComplete) {
        this.$snotify.success(
          "COC Information has been saved"
          );
      }
      if (response.status === 201 && isComplete) {
        this.$snotify.success(response.data.message);
      }
      this.error.msg = null;
      this.valid= false;
      await this.getChangeOfCircumstanceData();
      if (isComplete) this.$emit("refresh-task-list", true);
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async getChangeOfCircumstanceData() {
    this.callLoader(true, 1);
    try {
      let response = await Axios.post(
        BASE_API_URL +
        "processor/getChangeOfCircumstanceData" ,
        {
          loanId: this.$route.query.id,
          taskId: this.taskId,
          reOrderCount: 0,
          secondAppraisalCount: null,
        },
        { headers: authHeader() }
        );
      if (response.status === 201) {
        this.isTaskCompleted = response.data.isCompleted;
        this.dataObj = JSON.parse(JSON.stringify(response.data));

        /******************************************************** */
        this.dsData = JSON.parse(JSON.stringify(response.data));
        /******************************************************** */

      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public formHasData(dsData) {
    for (const [key, value] of Object.entries(dsData)) {
      if (key != 'createdBy' && key != 'createdOn' && key != 'updatedBy' && key != 'updatedOn' && key != 'isCompleted' && value) {
        return true;
      }
    }
    return false
  }

  public checkValForFields() {
   if(!this.dataObj.interestRate && !this.dataObj.propertyType
    && !this.dataObj.occupancy && !this.dataObj.productProgramType
    && !this.dataObj.appraisedValue && !this.dataObj.salesPrice
    && !this.dataObj.baseLoanAmount && !this.dataObj.escrowImpounds
    && !this.dataObj.addressChange && !this.dataObj.other)
    return true
  else
    return false
}




public callLoader(loader, loaderTitleId) {
  this.$store.state.tmpcoLoader = loader;
  this.$store.state.loaderTitleId = loaderTitleId;
}

async mounted() {
  await this.getChangeOfCircumstanceData();
}
}
