
import { Component, Prop, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../../../config";
import { authHeader } from "../../../services/auth";
// import CommonNotifyOnRejection from "../../../common-components/CommonNotifyOnRejection.vue";
import ViewDocument from "../../../common-components/ViewDocument.vue";

@Component({
  components: {
    ViewDocument,
    // CommonNotifyOnRejection,
  },
})
export default class VomReceived extends Vue {
  /* eslint-disable */
  public vomRecieved: any = {};
  public doc = false;
  public error: any = {
    show: true,
    msg: null,
  };
  // public notifyBroker = false;

  @Prop()
  public taskId: any;
  public loanId: any = this.$route.query.id;
  public docLoader = false;
  @Prop()
  public isLoanInactive: any;
  public isTaskCompleted = false;
  @Prop()
  public reOrderCount: any;
  public isOrderPlacedOnce = false
  /**
   * Taking dsData variable for checking form data is changed or not only for comaprison
   */
  public dsData: any = {};
  /**************************************************************** */
  @Prop()
  public isTaskDisabled: any;


  public async checkSaveValidation() {
    try {
      if (!this.vomRecieved.isVomRecieved && this.vomRecieved.isVomRecieved == this.dsData.isVomRecieved 
      )
        return true;
      else return false;
    } catch (error) {
      console.log(error);
    }
  }

    


  public async uploadReport(event) {
     if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getVomRecieved();
        return;
      }
    this.docLoader = true;
    try {
      let files = event.target.files;
      let formData: any = new FormData();
      formData.append("loanId", this.loanId);
      formData.append("taskId", this.taskId);
      formData.append("reOrderCount", this.reOrderCount);
      Array.from(files).forEach((f) => {
        formData.append("file", f);
      });
      let response = await Axios.post(
        BASE_API_URL + "processor/uploadVom",
        formData,
        { headers: authHeader() }
      );
      if (response.status === 201) {
        this.$snotify.success(response.data.message);
        this.doc = response.data.doc;
      }
    } catch (error) {
      console.log(error);
    }
    this.docLoader = false;
  }

  public async saveVomRecieved(isComplete) {
      if(this.isOrderPlacedOnce) return
    this.callLoader(true, null);
    try {
       if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getVomRecieved();
        return;
      }
      if (!isComplete)  {
        let notValid1 = await this.checkSaveValidation();
        if (notValid1) {
          this.callLoader(false, null);
          this.$snotify.info('No Changes')
          return;
        }
      }
      if (isComplete && !this.vomRecieved.isVomRecieved) {
        this.error.show = true;
        this.error.msg = "All Fields are Required.";
        this.callLoader(false, null);
        return;
      }
      if (isComplete && !this.doc) {
        this.error.show = true;
        this.error.msg = "Please Upload VOM Report.";
        this.callLoader(false, null);
        return;
      }
      
          if(isComplete) this.isOrderPlacedOnce = true;
      let response = await Axios.post(
        BASE_API_URL + "processor/saveVomRecieved",
        {
          loanId: this.loanId,
          taskId: this.taskId,
          vomRecieved: this.vomRecieved,
          isComplete: isComplete,
        },
        { headers: authHeader() }
      );
       if (!isComplete) {
        this.$snotify.success("VOM Recieved Information has been saved");
      }
      if (response.status === 201 && isComplete)
        this.$snotify.success(response.data.msg);
      if (isComplete) this.$emit("refresh-task-list", true);
     await this.getVomRecieved();
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async getVomRecieved() {
    this.callLoader(true, null);
    try {
      const response = await Axios.get(
        BASE_API_URL +
          "processor/getVomRecieved/" +
          this.$route.query.id +
          "/" +
          this.taskId,
        {
          headers: authHeader(),
        }
      );

      if (response.status === 201) {
        this.vomRecieved = JSON.parse(JSON.stringify(response.data.vomRecieved));
        this.doc = response.data.doc;
        this.dsData = JSON.parse(JSON.stringify(response.data.vomRecieved));
        this.isTaskCompleted = response.data.isCompleted;
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async viewDocument(document) {
    try {
       if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getVomRecieved();
        return;
      }
      document.loanId = this.$route.query.id;
      this.$refs.ref["showFile"](document);
    } catch (error) {
      console.log(error);
    }
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  mounted() {
    this.getVomRecieved();
  }
}
