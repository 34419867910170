
import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../config";
import { authHeader } from "../services/auth";
import CommonDeleteModal from "../common-components/CommonDeleteModal.vue";
import suneditor from "suneditor";
import plugins from "suneditor/src/plugins";

@Component({
  components: { CommonDeleteModal },
})
export default class CommunicationNotes extends Vue {
  /* eslint-disable */

  public communicationNotes: any = [];
  public processorInfo: any = {};
  public userData: any = {};
  public notesdata: any = {};
  public notes: any = {};
  public editor: any = null;
  public tabIndex = 0;
  public step = 0;
  public isEdit = false;
  public errorMessage = "";
  public errorTitle = "";
  public title = "";

  public async getCommunicationNotes() {
    this.callLoader(true, null);
    try {
      let response = await Axios.get(
        BASE_API_URL +
          "communication/getCommunicationNotes/" +
          this.$route.query.id,
        {
          headers: authHeader(),
        }
      );

      this.communicationNotes = response.data;
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public async userValidate() {
    const res = await Axios.get(
      BASE_API_URL + "common/my-account/userValidate",
      {
        headers: authHeader(),
      }
    );
    if (res.status === 201) {
      this.userData = res.data ? res.data : null;
    }
  }
  public async getInfo(data) {
    // this.notesdata = data;
    this.notesdata = data ? data : { message: "" };
    this.$modal.show("editNotes");
    setTimeout(async () => {
      this.editor = await suneditor.create("pageEditor", {
        plugins: {
          ...plugins,
        },
        width: "auto",
        height: "100px",
        buttonList: [["bold"], ["list"]],
      });
    }, 1);
  }

  public async deleteNotesConfirmation(data) {
    this.notes = data;
    this.$modal.show("deleteConfirmationModal");
  }

  public async deleteNotes() {
    const res = await Axios.post(
      BASE_API_URL + "communication/deleteNotes",
      this.notes,
      {
        headers: authHeader(),
      }
    );
    if (res.status === 201) {
      await this.$snotify.info(res.data.message);
      this.$modal.hide("deleteConfirmationModal");
      await this.getCommunicationNotes();
    }
  }

  public async EditNotes() {
    try {
      this.notesdata.message = this.editor.getContents();
      this.notesdata.loanId = this.$route.query.id;

      if (
        this.notesdata.message
          .replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, "")
          .trim() != ""
      ) {
        this.errorMessage = "";
      } else {
        this.errorMessage = "Please add a Message";
        this.errorTitle = "Please add a Title";
      }
      if (
        this.notesdata.message
          .replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, "")
          .trim() != "" &&
        this.notesdata.title
      ) {
        const response = await Axios.post(
          BASE_API_URL + "communication/saveProcessorNotes",
          this.notesdata,
          {
            headers: authHeader(),
          }
        );
        if (response.status === 201) {
          this.tabIndex = 0;
          this.step = 0;
          this.$snotify.success("Updated Sucessfully");
          this.notesdata = {
            message: "",
            title: "",
          };
          this.$modal.hide("editNotes");
          await this.getCommunicationNotes();
          // this.$refs.notes['getCommunicationNotes']();
        }
      }
      // }
    } catch (error) {
      console.log(error);
    }
  }

  public async cancel() {
    this.notesdata.title = this.title;
    this.$modal.hide("editNotes");

    //await this.getCommunicationNotes();
  }

  public async checkTitle(data) {
    this.title = data.title;
  }

  async mounted() {
    await this.getCommunicationNotes();
    await this.userValidate();
  }
  /* eslint-disable */
}
