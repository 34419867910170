
  import { Component, Vue, Prop } from "vue-property-decorator";
  import Axios from "axios";
  import { BASE_API_URL } from "../../../config";
  import ReOrderTask from "../../../common-components/ReOrderTask.vue";
  import { authHeader } from "../../../services/auth";
  import {
    VerificationOfAgent,
  //VendorData,
} from "../../../models/workflow/pud.model";
@Component({
  components: {
    ReOrderTask,
  },
})
export default class PudQuestionnaireOrder extends Vue {
  /* eslint-disable */
  @Prop()
  public taskId: any;
  public pudQuestionnaireOrder: any = new VerificationOfAgent();

  public error = false;
  public msg = "";
  public agent: any = {};
  // @Prop()
  // public isLoanInactive: any;
  public agentNotAdded = false;
  public isEmail = false;
  public isLoanCompleted = false;
  public mortgageClause: any = {};
  public isComplete = false;
  public allAgents: any = [];
  public selectedAgent: any = null;
  @Prop()
  public reOrderCount: any;

  public countToSendOnServer: any = null;
  public showHistoryBtn = false;
  public showReOrderBtn = false;
  public isOrderPlacedOnce = false;
  /**
   * Taking dsData variable for checking form data is changed or not only for comaprison
   */
   public dsData: any = {};
   public tempSelectedAgent: any = null;
   /**************************************************************** */
   @Prop()
   public isTaskDisabled: any;

   public async checkSaveValidation() {
    try {
      let returnV = false;
      if (
        !this.pudQuestionnaireOrder.isPudQuestionnaireOrder &&
        this.pudQuestionnaireOrder.isPudQuestionnaireOrder ==
        this.dsData.isPudQuestionnaireOrder &&
        !this.pudQuestionnaireOrder.agentInfo.option &&
        this.dsData.agentInfo.option == this.dsData.agentInfo.option
        ) {
        if (!this.selectedAgent && this.selectedAgent == this.tempSelectedAgent)
          returnV = true;
        if (this.allAgents.length == 1 && this.selectedAgent) returnV = true;
      }
      return returnV;
    } catch (error) {
      console.log(error);
    }
  }

  public async savePudQuestionnaireOrder() {
      if(this.isOrderPlacedOnce) return
    this.callLoader(true, null);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getPudQuestionnaireOrder();
        return;
      }
      if (!this.isComplete) {
        let notValid1 = await this.checkSaveValidation();
        if (notValid1) {
          this.callLoader(false, null);
          this.$snotify.info("No Changes");
          return;
        }
      }
      if (
        (!this.agent.agentContactData || !this.agent.agentData) &&
        this.isComplete
        ) {
        this.error = true;
      this.msg = "Agent Not Added Yet";
      this.callLoader(false, null);
      return;
    }

    if (
      !this.pudQuestionnaireOrder.isPudQuestionnaireOrder &&
      this.isComplete
      ) {
      this.error = true;
    this.msg = "All fields are Required.";
    this.callLoader(false, null);
    return;
  }
  if (
    !this. pudQuestionnaireOrder.agentInfo.option &&
    this.isComplete
    ) {
    this.error = true;
  this.msg = "Please select atleast one option to send PUD.";
  this.callLoader(false, null);
  return;
}
          if(this.isComplete) this.isOrderPlacedOnce=true;
let response = await Axios.post(
  BASE_API_URL + "processor/savePudQuestionnaireOrder",
  {
    taskId: this.taskId,
    loanId: this.$route.query.id,
    pudQuestionnaireOrder: this.pudQuestionnaireOrder,
    isComplete: this.isComplete,
    reOrderCount: this.countToSendOnServer,
    selectedAgent: this.selectedAgent,
    agentType: 9,
    formData: this.dsData
  },
  { headers: authHeader() }
  );
if (!this.isComplete) {
  this.$snotify.success(
    "PUD Questionnaire Order Information has been saved"
    );
}

if (response.status === 201 && this.isComplete) {
  this.$snotify.success(
    "PUD Questionnaire Order Completed successfully."
    );
}
if (this.isComplete) this.$emit("refresh-task-list", true);
await this.getPudQuestionnaireOrder();
} catch (error) {
  console.log(error);
}
this.callLoader(false, null);
}

public async getPudQuestionnaireOrder() {
  this.callLoader(true, null);
  try {
    const response = await Axios.get(
      BASE_API_URL +
      "processor/getPudQuestionnaireOrder/" +
      this.$route.query.id +
      "/" +
      this.taskId +
      "/" +
      this.countToSendOnServer,
      {
        headers: authHeader(),
      }
      );

    if (response.status === 201) {
      this.isLoanCompleted = response.data.isCompleted;
      this.pudQuestionnaireOrder = JSON.parse(
        JSON.stringify(response.data.pudQuestionnaireOrder)
        );
      this.dsData = JSON.parse(
        JSON.stringify(response.data.pudQuestionnaireOrder)
        );
      this.showReOrderBtn = response.data.showReOrderBtn;
      this.showHistoryBtn = response.data.showHistoryBtn;
    }
  } catch (error) {
    console.log(error);
  }
  this.callLoader(false, null);
}

public callLoader(loader, loaderTitleId) {
  this.$store.state.tmpcoLoader = loader;
  this.$store.state.loaderTitleId = loaderTitleId;
}

public async reOrderPud() {
  this.callLoader(true, 1);
  try {
    if (this.isTaskDisabled) {
      this.callLoader(false, null);
      this.$snotify.info("Task is not assigned to you.");
      this.getPudQuestionnaireOrder();
      return;
    }
    this.$refs.refReOrder["confitmationBox"]({
      message: "Are you sure you want to RE-Order PUD Questionnaire Order?",
      apiPath: `processor/reOrderPud`,
      data: { loanId: this.$route.query.id, taskId: this.taskId, selectedAgent: this.selectedAgent },
    });
  } catch (error) {
    console.log(error);
  }
  this.callLoader(false, null);
}

public async getListOfAgents() {
  try {
    let response = await Axios.post(
      BASE_API_URL + "processor/getListOfAgents",
      {
        loanId: this.$route.query.id,
        agentType: 9,
      },
      {
        headers: authHeader(),
      }
      );

    if (response.status === 201) this.allAgents = response.data;
  } catch (error) {
    console.log(error);
  }
}

public async getSelectedAgent() {
  try {
    this.agent = {}
    this.selectedAgent = null;
    let response = await Axios.post(
      BASE_API_URL + "processor/getSelectedAgent",
      {
        loanId: this.$route.query.id,
          //reOrderCount: this.countToSendOnServer,
          agentType: 9,
          taskId: this.taskId,
          agentId: this.pudQuestionnaireOrder.agentContactId,
          companyId: this.pudQuestionnaireOrder.companyId,
          lenderId: this.pudQuestionnaireOrder.lenderId,
          isLoanCompleted : this.isLoanCompleted
        },
        {
          headers: authHeader(),
        }
        );
    if (response.status == 201) {
      if (
        response.data &&
        response.data.agentData &&
        response.data.agentData.agentContactData &&
        response.data.agentData.agentData
        ) {
        this.agent = response.data.agentData;
      this.selectedAgent = JSON.parse(
        JSON.stringify(this.agent.agentContactData._id)
        );
      this.tempSelectedAgent = JSON.parse(
        JSON.stringify(this.agent.agentContactData._id)
        );
    } else {
      this.agentNotAdded = true;
    }
    this.pudQuestionnaireOrder.lenderId = response.data.lenderId
    this.pudQuestionnaireOrder.agentContactId = this.agent.agentContactData._id;
    this.pudQuestionnaireOrder.companyId = this.agent.agentData._id;
    this.mortgageClause = response.data.mortgageClause;
    this.pudQuestionnaireOrder.agentInfo.email = this.agent.agentContactData && this.agent.agentContactData.email;
    this.isEmail = this.pudQuestionnaireOrder.agentInfo.email
    ? true
    : false;
  }
} catch (error) {
  console.log(error);
}
}

public onChangingAgent() {
  this.agent = this.allAgents.find(
    (e) => e.agentContactId == this.selectedAgent
    ).agentData;
  this.pudQuestionnaireOrder.agentInfo.email = this.agent.agentContactData.email;
  this.pudQuestionnaireOrder.agentContactId = this.agent.agentContactData._id;
  this.pudQuestionnaireOrder.companyId = this.agent.agentData._id;
}

public async showHistory() {
  this.callLoader(true, 1);
  try {
    if (this.isTaskDisabled) {
      this.callLoader(false, null);
      this.$snotify.info("Task is not assigned to you.");
      this.getPudQuestionnaireOrder();
      return;
    }
    this.$refs.refReOrder["showHistory"](this.taskId,'PUD');
  } catch (error) {
    console.log(error);
  }
  this.callLoader(false, null);
}

public async allMountFunction(count) {
  this.countToSendOnServer = count;
  await this.getPudQuestionnaireOrder();
  await this.getListOfAgents();
  await this.getSelectedAgent();
}

public async mounted() {
  await this.allMountFunction(this.reOrderCount);
}
}
