
import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL, EventBus } from "../config";
import { authHeader } from "../services/auth";
import { VendorData } from "../models/workflow/voe.model";
import { CondoVendorData } from "../models/workflow/condoQuestionnair.model";
import { MasterVendorData } from "../models/workflow/masterInsurance.model";
@Component({
  components: {},
})
export default class CommonDeleteModal extends Vue {
  @Prop()
  public iden;
  public vendorDetails: any = {};

  public async saveOtherVendor() {
    try {
      const response = await Axios.post(
        BASE_API_URL + "common/master-credentials/saveMasterCredentials",
        this.vendorDetails,
        {
          headers: authHeader(),
        }
      );
      if (response.status === 201) {
        await this.$snotify.success("Vendor added successfully");
        await this.cancel();
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async cancel() {
    await this.$emit("refreshing-after-adding-vendor");
    await this.$modal.hide("AddOtherVendor");
    if (this.iden == "condo") {
      this.vendorDetails = new CondoVendorData();
    } else if (this.iden == "masterInsuranceCertificate") {
      this.vendorDetails = new MasterVendorData();
    } else {
      this.vendorDetails = new VendorData();
    }
  }
  mounted() {
    if (this.iden == "condo") {
      this.vendorDetails = new CondoVendorData();
    } else if (this.iden == "masterInsuranceCertificate") {
      this.vendorDetails = new MasterVendorData();
    } else {
      this.vendorDetails = new VendorData();
    }
  }
}
