
import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL, EventBus } from "../../../config";
import { authHeader } from "../../../services/auth";
import ViewDocument from "../../../common-components/ViewDocument.vue";
import moment from "moment";
import CommonDeleteModal from "../../../common-components/CommonDeleteModal.vue";
import { VerificationOfAgent } from "../../../models/workflow/updatedTitle.model";
import CommonNotifyOnRejection from "../../../common-components/CommonNotifyOnRejection.vue";
import FollowUpTask from "../../../common-components/FollowUpTask.vue";

@Component({
  components: {
    ViewDocument,
    CommonDeleteModal,
    CommonNotifyOnRejection,
    FollowUpTask,
  },
})
export default class UpdatedTitle extends Vue {
  /* eslint-disable */
  public docs: any = [];
  public updatedTitle: any = new VerificationOfAgent();
  // public updatedTitle: any = {}

  // public taskObj: any = {};

  @Prop()
  public taskId: any;
  @Prop()
  public isLoanInactive: any;
  // @Prop()
  // public reOrderCount: any;
  public showHistoryBtn = false;
  @Prop()
  public totalTaskCount: any;
  @Prop()
  public secondAppraisalCount: any;
  public totalTaskCountForServer: any = null;
  public countToSendOnServer: any = null;
  public secondAppraisalCountForServer: any = null;

  public isTaskCompleted = false;
  public error: any = { show: false, msg: null };
  @Prop()
  public isTaskDisabled: any;
  public titleOrder: any = {};
  public agent: any = {};
  public selectedAgent: any = null;
  public tempSelectedAgent: any = null;
  public mortgageClause: any = {};
  public agentNotAdded = false;
  public allAgents: any = [];
  public inProcess: any = false;
  public documentName = null;
  public selectedAgentOption: any = {};
  public selectedExistingAgent = false;
  public addNewAgent = false;
  public agentData: any = {
    fname: "",
    lname: "",
    email: "",
    contact: "",
    agentNmlsId: "",
    agentLicenseId: "",
  };
  public isFNameError = false;
  public isLNameError = false;
  public isEmailError = false;
  public isContactError = false;
  public emailErrorMessage = "";
  public contactErrorMessage = "";
  public additionalAgents: any = [];
  public agentsSuggestion = [];
  public agentCompanyId = [];
  public loanNumber = "";
  public notifyMessage = "Updated Title Low Information has been saved";
  public emailToDelete = "";
  public docRequested = false;
  public orderRemarks = "";
  public loanId: any = this.$route.query.id;
  public step: any = 5;
  public isAgentNotified: any = false;
  public activeStatus = "";
  public isRejected = false;
  public isAccepted = false;
  public isChecklist = true;
  public sendLinkTo: any = null;
  public isDocsRequestedOnce = false;
  public isDocsError = false;
  public errorMsg = "";
  public docErrorMsg = "";
  public isChooseTypeError = false;
  public errorChooseTypeMsg = "";
  public resendingTitleLow = false;

  // public checkValidation() {
  //   let len = this.docs.filter((e) => e.docFiles.length == 0);
  //   return len.length === this.docs.length ? true : false;
  // }
  public async checkValidation() {
    //let notDocVerify = this.docs.filter((e) => e.isComplete);
    let notDocVerify = this.docs.filter((e) => e.isComplete);
    notDocVerify = notDocVerify.length > 0 ? false : true;
    return notDocVerify;
  }

  public async openConfirmationPopup() {
    let faxformat = /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
    let checkvalidFaxData =
      this.updatedTitle.agentInfo.option == "fax" &&
      !this.updatedTitle.agentInfo.fax.match(faxformat);
    if (
      this.updatedTitle.agentInfo.option == null ||
      (this.updatedTitle.agentInfo.option == "fax" &&
        (this.updatedTitle.agentInfo.fax == null ||
          this.updatedTitle.agentInfo.fax == ""))
    ) {
      this.isChooseTypeError = true;
      this.docRequested = false;
      this.updatedTitle.isOrderPlaced = false;
      this.errorMsg = "All fields are Required.";
      this.callLoader(false, null);
      return;
    } else if (checkvalidFaxData) {
      this.isChooseTypeError = true;
      this.docRequested = false;
      this.updatedTitle.isOrderPlaced = false;
      this.errorMsg = "Enter a valid fax.";
      this.callLoader(false, null);
      return;
    } else {
      this.updatedTitle.isOrderPlaced = true;
      this.docRequested = true;
      this.$modal.show("requestDocs");
    }
  }

  public async cancel() {
    try {
      await this.getOtherTitleAgents();
      this.$modal.hide("requestDocs");
      this.orderRemarks = "";
      this.updatedTitle.isOrderPlaced = false;
      this.$modal.hide("confirmModal");
      this.docRequested = false;
      this.$modal.hide("titleConfirmationModal");
      this.$modal.hide("DeActivateAgent");
      this.$modal.hide("resendLinkconfirmationLink");
      await this.getUpdatedTitleData();
      this.sendLinkTo = "";
    } catch (error) {
      console.log(error);
    }
  }
  public ConfirmationPopup() {
    this.$modal.show("titleConfirmationModal");
  }
  public isOrderPlacedOnce = false;

  public async deActivateAgent(userId) {
    try {
      const response = await Axios.post(
        BASE_API_URL + "processor/deActivateAgent/",
        {
          loanId: this.$route.query.id,
          taskId: this.taskId,
          // reOrderCount: this.countToSendOnServer,
          userId: userId,
        },
        {
          headers: authHeader(),
        }
      );
      if (response.status === 201) this.$snotify.success(response.data.message);
      this.$modal.hide("DeActivateAgent");
      this.getOtherTitleAgents();
      // this.getTitleOrderData();
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async deleteDoc(documentId) {
    try {
      await this.$refs.refs["delConfirmationBox"]({
        msg: `Are you sure want to delete this Document ?`,
        data: {
          loanId: this.$route.query.id,
          documentId: documentId,
        },
        apiPath: "processor/deleteDoc",
      });
    } catch (error) {
      console.log(error);
    }
  }

  public async saveUpdatedTitleData(isComplete) {
    try {
      // this.errorMsg = "";
      if (!isComplete) {
        isComplete = false;
      }

      if (
        (isComplete && this.isOrderPlacedOnce) ||
        (this.docRequested && this.isDocsRequestedOnce && !isComplete)
      )
        return;

      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        await this.getUpdatedTitleData();
        await this.getOtherTitleAgents();

        return;
      }

      let isAnyDocApproved = await this.checkValidation();
      if (isComplete && isAnyDocApproved) {
        console.log(isAnyDocApproved, "isAnyDocApproved");
        this.isDocsError = true;
        this.isChooseTypeError = false;
        this.docErrorMsg = "Please Approve atleast one Document.";
        return;
      }

      if (!this.updatedTitle.isOrderPlaced && isComplete) {
        this.error.show = true;
        this.error.msg = "Please complete the request for documents";
        return;
      }
      // APPEND THE DATA LIST FOR UPDATE-TITLE. -FILTER THE IDS
      if (!this.resendingTitleLow)
        this.updatedTitle["remarks"] = this.orderRemarks;
      this.callLoader(true, null);

      if (isComplete) this.isOrderPlacedOnce = true;
      if (this.docRequested) this.isDocsRequestedOnce = true;
      if (
        (isComplete && this.updatedTitle.agentInfo.option == null) ||
        (this.updatedTitle.agentInfo.option == "fax" &&
          (this.updatedTitle.agentInfo.fax == null ||
            this.updatedTitle.agentInfo.fax == ""))
      ) {
        this.isChooseTypeError = true;
        this.isDocsError = false;
        this.updatedTitle.isOrderPlaced = false;
        this.errorMsg = "All fields are Required.";
        this.callLoader(false, null);
        return;
      }
      let response = await Axios.post(
        BASE_API_URL + "processor/saveUpdatedTitleData",
        {
          loanId: this.$route.query.id,
          taskId: this.taskId,
          updatedTitle: this.updatedTitle,
          reOrderCount: this.countToSendOnServer,
          totalTaskCount: this.totalTaskCountForServer,
          secondAppraisalCount: this.secondAppraisalCountForServer,
          isComplete: isComplete,
          selectedAgent: this.selectedAgent,
          agentType: 3,
          agentContactId: this.updatedTitle.agentContactId,
          docRequested: this.docRequested,
          orderRemarks: this.orderRemarks.trim(),
          agentCompanyId: this.agent.agentData._id,
          resendingTitleLow: isComplete ? false : this.resendingTitleLow,
        },
        { headers: authHeader() }
      );

      this.$modal.hide("requestDocs");
      this.$modal.hide("addAgents");
      if (!isComplete && !this.resendingTitleLow) {
        this.$snotify.success(this.notifyMessage);
        this.notifyMessage = "Updated Title Low Information has been saved";
        // await this.getUpdatedTitleData();
      }
      if (response.status === 201 && isComplete && !this.resendingTitleLow) {
        this.$snotify.success(response.data.message);
      }
      if (isComplete) this.$emit("refresh-task-list", true);

      await this.getUpdatedTitleData();
      await this.getOtherTitleAgents();
    } catch (error) {
      console.log(error);
      this.callLoader(false, null);
    }
  }

  public async getUpdatedTitleData() {
    try {
      this.callLoader(true, 1);
      let response = await Axios.post(
        BASE_API_URL + "processor/getUpdatedTitleData/",
        {
          loanId: this.$route.query.id,
          taskId: this.taskId,
          totalTaskCount: this.totalTaskCountForServer,
          reOrderCount: this.countToSendOnServer,
          secondAppraisalCount: this.secondAppraisalCountForServer,
          agentContactId: this.updatedTitle.agentContactId,
          companyId: this.updatedTitle.companyId,
        },
        { headers: authHeader() }
      );
      if (response.status === 201) {
        this.loanNumber = response.data.loanNumber;

        // this.docs = response.data.allDocs;

        this.docs = response.data.allDocs.map((element) => {
          let msg = "";
          let status = false;
          let notifyed = false;
          if (
            element.allRejectedComments &&
            element.allRejectedComments.length > 0
          ) {
            for (let i = 0; i < element.allRejectedComments.length; i++) {
              const e = element.allRejectedComments[i];
              msg += `${e.comment} ${
                e.acceptedOrRejectedOn
                  ? moment(e.acceptedOrRejectedOn).format(
                      "MM/DD/YYYY, h:mm:ss a"
                    )
                  : ""
              } -${e.name ? e.name : ""} *** `;
            }
          }
          if (element.docFiles && element.docFiles.length > 0) {
            for (let j = 0; j < element.docFiles.length; j++) {
              const e = element.docFiles[j];
              status = e.status == "Accept" ? true : false;

              notifyed = e.files[e.files.length - 1]
                ? e.files[e.files.length - 1].isNotified
                : false;
            }
          }
          element["msgString"] = msg;
          let msg2 = "";

          if (
            element.allAcceptedComments &&
            element.allAcceptedComments.length > 0
          ) {
            for (let j = 0; j < element.allAcceptedComments.length; j++) {
              const e = element.allAcceptedComments[j];
              msg2 += `${
                e.acceptedOrRejectedOn
                  ? moment(e.acceptedOrRejectedOn).format(
                      "MM/DD/YYYY, h:mm:ss a"
                    )
                  : ""
              } -${e.name ? e.name : ""} *** `;
            }
          }
          element["msgString1"] = msg2;

          element["Status"] = status;
          element["isNotified"] = notifyed;
          return element;
        });

        this.isTaskCompleted =
          response.data.taskData && response.data.taskData.isTitleUpdated
            ? response.data.taskData.isTitleUpdated
            : false;
        this.showHistoryBtn = response.data.showHistoryBtn;
        if (response.data && response.data.taskData) {
          this.updatedTitle = JSON.parse(
            JSON.stringify(response.data.taskData)
          );

          this.docRequested = this.updatedTitle.isOrderPlaced;
          if (this.docRequested) this.orderRemarks = this.updatedTitle.remarks;

          if (
            response.data.taskData &&
            response.data.taskData.agentInfo &&
            response.data.taskData.agentInfo.email
          ) {
            this.updatedTitle.agentInfo.email = JSON.parse(
              JSON.stringify(response.data.taskData.agentInfo.email)
            );
          }
          if (response.data.taskData.agentInfo.fax) {
            this.updatedTitle.agentInfo.fax = JSON.parse(
              JSON.stringify(response.data.taskData.agentInfo.fax)
            );
          }
          if (
            response.data.taskData &&
            response.data.taskData.agentInfo &&
            response.data.taskData.agentInfo.option &&
            response.data.taskData.agentInfo.option == "email"
          ) {
            this.updatedTitle.agentInfo.additionalAgents = JSON.parse(
              JSON.stringify(response.data.taskData.agentInfo.additionalAgents)
            );
          }
        }
      }
    } catch (error) {
      console.log(error);
    }

    this.callLoader(false, 1);
  }

  public async uploadFile(event, selectedDoc) {
    this.callLoader(true, 1);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        return;
      }
      if (event.target.files.length <= 0) {
        this.callLoader(false, null);
        return;
      }

      const formData: any = new FormData();
      let loanId: any = this.$route.query.id;
      Array.from(event.target.files).forEach((element) => {
        formData.append("file", element);
      });

      formData.append("loanId", loanId);
      formData.append("taskId", this.taskId);
      formData.append("totalTaskCount", this.totalTaskCountForServer);
      // formData.append("reOrderCount", this.countToSendOnServer);
      formData.append(
        "secondAppraisalCount",
        this.secondAppraisalCountForServer
      );
      formData.append("docId", selectedDoc._id);

      const response = await Axios.post(
        BASE_API_URL + "processor/uploadTitleDocs",
        formData,
        { headers: authHeader() }
      );
      if (response.status === 201) {
        await this.getUpdatedTitleData();
        await this.getOtherTitleAgents();
      }
      this.isDocsError = false;
      this.$snotify.success(response.data.message);
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, 1);
  }

  public async getSelectedAgent() {
    try {
      this.agent = {};
      this.selectedAgent = null;
      let response = await Axios.post(
        BASE_API_URL + "processor/getSelectedAgent",
        {
          loanId: this.$route.query.id,
          agentType: 3,
          taskId: this.taskId,
          agentId:
            this.updatedTitle && this.updatedTitle.agentContactId
              ? this.updatedTitle.agentContactId
              : null,
          companyId:
            this.updatedTitle && this.updatedTitle.companyId
              ? this.updatedTitle.companyId
              : null,
          lenderId:
            this.updatedTitle && this.updatedTitle.lenderId
              ? this.updatedTitle.lenderId
              : null,
          isLoanCompleted: true,
        },
        {
          headers: authHeader(),
        }
      );
      if (response.status == 201) {
        if (
          response.data.agentData &&
          response.data.agentData.agentContactData &&
          response.data.agentData.agentData
        ) {
          this.agent = response.data.agentData;
          this.selectedAgent = JSON.parse(
            JSON.stringify(this.agent.agentContactData._id)
          );

          this.tempSelectedAgent = JSON.parse(
            JSON.stringify(this.agent.agentContactData._id)
          );
          this.updatedTitle.agentContactId = this.agent.agentContactData._id;
          this.updatedTitle.companyId = this.agent.agentData._id;
        } else {
          this.agentNotAdded = true;
        }
        this.mortgageClause = response.data.mortgageClause;
        this.updatedTitle.lenderId = response.data.lenderId;
        this.updatedTitle.agentInfo.email =
          this.agent.agentContactData && this.agent.agentContactData.email
            ? this.agent.agentContactData.email
            : this.updatedTitle.agentInfo.email;
        this.updatedTitle.agentInfo.fax =
          this.agent.agentContactData && this.agent.agentContactData.fax
            ? this.agent.agentContactData.fax
            : this.updatedTitle.agentInfo.fax;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async getListOfAgents() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "processor/getListOfAgents",
        {
          loanId: this.$route.query.id,
          agentType: 3,
        },
        {
          headers: authHeader(),
        }
      );

      if (response.status === 201) this.allAgents = response.data;
    } catch (error) {
      console.log(error);
    }
  }

  public async onChangingAgent() {
    this.agent = this.allAgents.find(
      (e) => e.agentContactId == this.selectedAgent
    ).agentData;

    this.updatedTitle.agentContactId = this.agent.agentContactData._id;

    this.updatedTitle.companyId = this.agent.agentData._id;
    this.updatedTitle.agentInfo.additionalAgents = [];
    this.notifyMessage = "Company changed successfuly";
    await this.saveUpdatedTitleData(false);
    await this.getOtherTitleAgents();
    this.$modal.hide("changeCompany");
  }

  public async onChangeOfChooseMethod(event) {
    if (event.target.value == "email") {
      if (!this.agent.agentContactData.email) {
        this.updatedTitle.agentInfo.email = null;
        this.updatedTitle.agentInfo.option = "email";
      }
    } else if (event.target.value == "fax") {
      this.updatedTitle.agentInfo.option = "fax";
      if (!this.agent.agentContactData.fax) {
        this.updatedTitle.agentInfo.fax = null;
        this.updatedTitle.agentInfo.option = "fax";
      }
    }
    if (event.target.value.length) {
      this.updatedTitle.agentInfo.option =
        this.updatedTitle.agentInfo.option.toLowerCase();
      this.notifyMessage = "Method of communication changed successfuly";
      await this.saveUpdatedTitleData(false);
    }
  }

  public async addUpdatedTitleLowDoc() {
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        return;
      }
      this.inProcess = true;
      let res = await Axios.post(
        BASE_API_URL + "processor/addUpdatedTitleLowDoc",
        {
          loanId: this.$route.query.id,
          name: this.documentName,
          appraisalRefCount: this.totalTaskCountForServer,
          secondAppraisalCount: this.secondAppraisalCountForServer,
        },
        { headers: authHeader() }
      );
      if ((res.status = 201)) {
        this.$snotify.success("Document has been Added.");
        this.$modal.hide("addDocument");
        await this.getUpdatedTitleData();
        await this.getOtherTitleAgents();
        this.documentName = null;
      }
    } catch (error) {
      console.log(error);
    }
    this.inProcess = false;
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public async viewDocument(document) {
    try {
      let obj = {
        documentId: document._id,
        docName: document.name,
        docCategory: document.category,
        loanId: this.$route.query.id,
        isRejected: this.isRejected,
        isAccepted: this.isAccepted,
        isChecklist: this.isChecklist,
      };
      this.$refs.ref["showFile"](obj);
    } catch (error) {
      console.log(error);
    }
  }

  public async showHistory() {
    this.callLoader(true, 1);
    try {
      this.$refs.refReOrder["showHistory"](this.taskId);
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  // VALIDATE EMAIL
  public async validateEmail() {
    this.isEmailError = false;
    this.emailErrorMessage = "This field is required!";
    if (!this.agentData.email || this.agentData.email.trim() === "")
      return (this.isEmailError = true);
    let email =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let validEmail = email.test(this.agentData.email);
    if (!validEmail) {
      this.emailErrorMessage = "Please provide valid Email!";
      return (this.isEmailError = true);
    }
    let checkDuplicacy = await this.agentDuplicateEmail(this.agentData.email);
    if (checkDuplicacy) {
      this.emailErrorMessage = "Email Already Exists!";
      this.isEmailError = true;
    }
  }

  public ValidateNames(input) {
    if (input == "lastName") {
      this.isLNameError = false;
      if (!this.agentData.lname) this.isLNameError = true;
      if (this.agentData.lname.trim() === "") this.isLNameError = true;
    }
    if (input == "firstName") {
      this.isFNameError = false;
      if (!this.agentData.fname) this.isFNameError = true;
      if (this.agentData.fname.trim() === "") this.isFNameError = true;
    }
    if (input == "contact") {
      this.isContactError = false;
      this.contactErrorMessage = "This field is required!";
      if (!this.agentData.contact) this.isContactError = true;
      if (this.agentData.contact.trim() === "") this.isContactError = true;
      if (
        this.agentData.contact.split(" ").join("").length != 13 &&
        this.agentData.contact.split(" ").join("").length != 0
      ) {
        this.isContactError = true;
        this.contactErrorMessage = "Contact should be 10 digits";
      }
    }
  }

  public closeAddAgentModal() {
    this.$modal.hide("addAgents");
    this.selectedAgentOption = "";
    this.addNewAgent = false;
    this.isContactError = false;
    this.isFNameError = false;
    this.isLNameError = false;
    this.isEmailError = false;
    this.contactErrorMessage = "This field is required!";
    this.emailErrorMessage = "This field is required!";
  }

  //CHECK DUPLICATE EMAIL IN LIST
  public async agentDuplicateEmail(email) {
    try {
      //return false when email is unique
      // for(let i = 0; i < this.otherTitleAgents.length; i++){
      //   if(email == this.otherTitleAgents[i].email) return true
      // }
      let response = await Axios.get(
        BASE_API_URL + `processor/agentDuplicateEmail/${email}?agentTypeId=7`,
        { headers: authHeader() }
      );
      if (response.data.code === 200) {
        return false;
      }
      return true;
    } catch (error) {
      console.log(error);
    }
  }

  public async getOtherTitleAgents() {
    let response = await Axios.get(
      BASE_API_URL +
        `processor/getOtherTitleOrderAgents/${this.$route.query.id}/${38}/${
          this.countToSendOnServer
        }/${this.secondAppraisalCountForServer}`,
      { headers: authHeader() }
    );
    this.additionalAgents = response.data.data;
  }

  //remove the agent from the list.
  public async removeOtherTitleAgent() {
    this.additionalAgents = this.additionalAgents.filter(
      (item) => item.email != this.emailToDelete
    );
    let list = [] as any;
    if (this.additionalAgents && this.additionalAgents.length) {
      this.additionalAgents.map((item) => {
        item._id ? list.push(item._id) : list.push(item.id);
      });
    }
    this.updatedTitle.agentInfo.additionalAgents = list;
    this.notifyMessage = "Agent deleted successfuly";
    await this.saveUpdatedTitleData(false);
    this.$modal.hide("deleteModal");
  }

  //addOtherTitleAgent()
  public async addOtherTitleAgent(data) {
    this.updatedTitle.agentInfo.option = "email";
    if (data == "1") {
      if (!this.agentData.fname) this.isFNameError = true;
      if (!this.agentData.lname) this.isLNameError = true;
      if (!this.agentData.email) this.isEmailError = true;
      if (!this.agentData.contact) this.isContactError = true;
      await this.validateEmail();
      if (
        this.isEmailError ||
        this.isContactError ||
        this.isLNameError ||
        this.isFNameError
      )
        return;
      let response = await Axios.post(
        BASE_API_URL +
          `processor/addMoreAgentsTitleOrder/${this.$route.query.id}`,
        {
          fullName: {
            firstName: this.agentData.fname,
            lastName: this.agentData.lname,
          },
          phone: this.agentData.contact,
          email: this.agentData.email.toLowerCase(),
          agentId: this.agent.agentData._id,
          agentType: 3,
          contactNMLSId: this.agentData.agentNmlsId,
          contactlicenseId: this.agentData.agentLicenseId,
        },
        { headers: authHeader() }
      );

      await this.suggestionClicked(response.data.data);
    } else {
      this.agentData.fname = data.fullName.firstName;
      this.agentData.lname = data.fullName.lastName;
      this.agentData.email = data.email;
      this.agentData.contact = data.phone;
      this.selectedExistingAgent = true;
      this.addNewAgent = true;
      await this.suggestionClicked(data);
    }

    this.closeAddAgentModal();
  }

  public async suggestionClicked(id) {
    if (this.additionalAgents) {
      this.additionalAgents.push(id);
    } else {
      this.additionalAgents = [id];
    }
    this.updatedTitle.agentInfo.additionalAgents = [];
    for (let i = 0; i < this.additionalAgents.length; i++) {
      this.updatedTitle.agentInfo.additionalAgents.push(
        this.additionalAgents[i]._id
      );
    }
    this.notifyMessage = "Agent added successfully!";
    await this.saveUpdatedTitleData(false);
    await this.getOtherTitleAgents();
  }

  // FUNCTION FOR SELECTING AGENT FROM DROPDWON
  public async agentDropDownSelected() {
    this.addNewAgent = true;
    this.selectedExistingAgent = false;
    if (this.selectedAgentOption != "1") {
      this.agentData.fname = this.selectedAgentOption
        ? this.selectedAgentOption.fullName
          ? this.selectedAgentOption.fullName.firstName
          : ""
        : "";
      this.agentData.lname = this.selectedAgentOption
        ? this.selectedAgentOption.fullName
          ? this.selectedAgentOption.fullName.lastName
          : ""
        : "";
      this.agentData.email = this.selectedAgentOption
        ? this.selectedAgentOption.email
          ? this.selectedAgentOption.email
          : ""
        : "";
      this.agentData.contact = this.selectedAgentOption
        ? this.selectedAgentOption.phone
          ? this.selectedAgentOption.phone
          : ""
        : "";
      this.selectedExistingAgent = true;
    } else {
      this.agentData.fname = "";
      this.agentData.lname = "";
      this.agentData.email = "";
      this.agentData.contact = "";
      this.agentData.agentLicenseId = "";
      this.agentData.agentNmlsId = "";
    }

    // await this.addOtherTitleAgent(this.selectedAgentOption);
  }

  // await this.addOtherTitleAgent(this.selectedAgentOption);

  public async showSuggestionsForAdditionalAgents() {
    let response = await Axios.get(
      BASE_API_URL +
        `processor/getOtherAgentToAdd/${this.agent.agentData._id}?loanNo=${this.$route.query.id}`,
      { headers: authHeader() }
    );
    if (response.status == 201) {
      this.agentsSuggestion = response.data.data.filter((item) => {
        let NotExists = true;
        for (let i in this.additionalAgents) {
          if (this.additionalAgents[i].email === item.email) {
            NotExists = false;
          }
        }
        return NotExists;
      });
    }
  }

  public async activationLink(id, sendMail) {
    this.callLoader(true, 1);
    try {
      let response = await Axios.get(
        BASE_API_URL +
          `processor/getTokenForAgentToCopy/${id}?loanId=${this.$route.query.id}&&title=isTitleLow&&sendMail=${sendMail}&&count=${this.countToSendOnServer}`,
        { headers: authHeader() }
      );

      if (response.status === 201 && response.data.isLatest) {
        this.$modal.hide("resendLinkconfirmationLink");
        this.sendLinkTo = "";
        this.resendingTitleLow = true;
        if (sendMail === false) {
          navigator.clipboard.writeText(response.data.data);
          this.$snotify.success("Link copied successfully!");
        } else {
          this.$snotify.success("Link send successfully!");
          await this.saveUpdatedTitleData(false);
          this.callLoader(false, 1);
        }
      } else {
        this.$modal.hide("resendLinkconfirmationLink");
        if (sendMail === false) {
          navigator.clipboard.writeText(response.data.data);
          this.$snotify.warning("Link is not active for this order");
        } else {
          this.$snotify.warning("Can't send mail for this order.");
        }
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async isBrokerNotifiedForRejectedDocs() {
    try {
      let res = await Axios.get(
        BASE_API_URL +
          "loanChecklist/isBrokerNotifiedForRejectedDocs/" +
          this.loanId +
          "/" +
          38 +
          "/" +
          this.step,
        {
          headers: authHeader(),
        }
      );
      if (res.status == 201) {
        this.isAgentNotified = res.data.notifyBorker;
      }
    } catch (error) {
      console.log(error);
    }
  }

  //
  public leaveFaxInput() {
    //check the fax input - isChooseTypeError = false;
    this.errorMsg = "";
    let faxformat = /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
    let checkvalidFaxData = !this.updatedTitle.agentInfo.fax.match(faxformat);
    if (checkvalidFaxData) {
      this.isChooseTypeError = true;
      this.isDocsError = false;
      this.errorMsg = "Enter a valid fax.";
      this.updatedTitle.isOrderPlaced = false;
      this.callLoader(false, null);
      return;
    }
  }

  // ********************************************************************************************88
  public async sendFollowUpMailToTitleAgent() {
    this.callLoader(true, 1);
    try {
      this.$refs.refFollowUp["openFollowUpModal"]({
        label: "Follow Up Email",
        message: "Follow Up",
        apiPath: `processor/sendFollowUpMailToTitleAgent`,
        data: {
          loanId: this.$route.query.id,
          taskId: this.taskId,
          reOrderCount: this.countToSendOnServer,
          agentContactId: this.updatedTitle.agentContactId,
          secondAppraisalCount: this.secondAppraisalCountForServer,
        },
      });
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async viewFollowUpHistory() {
    try {
      this.$refs.refFollowUp["openHistoryModal"]({
        label: "Select method to send",
        message: "Follow Up",
        apiPath: `processor/getFollowUpHistoryOfTitle`,
        data: {
          loanId: this.$route.query.id,
          taskId: this.taskId,
          reOrderCount: this.countToSendOnServer,
          secondAppraisalCount: this.secondAppraisalCountForServer,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  public async allMountFunction(count, secondAppraisalCount) {
    this.secondAppraisalCountForServer = secondAppraisalCount;
    this.countToSendOnServer = count;
    this.totalTaskCountForServer = count;
    await this.getSelectedAgent();
    await this.getListOfAgents();
    await this.getUpdatedTitleData();
    await this.getOtherTitleAgents();
    await this.isBrokerNotifiedForRejectedDocs();
  }

  async mounted() {
    await this.allMountFunction(this.totalTaskCount, this.secondAppraisalCount);
  }
}
