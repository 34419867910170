
  import { Component, Vue, Prop } from "vue-property-decorator";
  import Axios from "axios";
  import { BASE_API_URL, EventBus } from "../../../config";
  import { authHeader } from "../../../services/auth";
  import ViewDocument from "../../../common-components/ViewDocument.vue";
  import CommonNotifyOnRejection from "../../../common-components/CommonNotifyOnRejection.vue";
  import ReOrderTask from "../../../common-components/ReOrderTask.vue";
  import CommonVendorModal from "../../../common-components/CommonVendorModal.vue";
  import {
    VerificationOfAgent,
    CondoVendorData,
  } from "../../../models/workflow/condoQuestionnair.model";
  @Component({
    components: {
      ViewDocument,
      CommonNotifyOnRejection,
      ReOrderTask,
      CommonVendorModal,
    },
  })
  export default class CondoQuestionnaireOrder extends Vue {
    /* eslint-disable */
    public condoQuestionnaireOrder: any = new VerificationOfAgent();
    public identifier = "condo";

    public condoVendorList: any = [];
    public agent: any = {};
    @Prop()
    public taskId: any;
    public doc = false;
    public error: any = {
      msg: null,
      show: false,
    };
    public vendorDetails: any = new CondoVendorData();
  // public isEmail = false;

  public loanId = this.$route.query.id;
  public uploading = false;
  public isData = false;
  @Prop()
  public isLoanInactive: any;
  public taskCompleted = false;
  public allDocs: any = [];
  public mortgageClause: any = {};
  // public notifyBroker = false;
  public condoAgents: any = [];
  public selectedAgent: any = null;
  @Prop()
  public reOrderCount: any;
  public selectedCompany: any = null;
  // public isComplete = false;
  public isCredential = false;
  public countToSendOnServer: any = null;
  public showHistoryBtn = false;
  public showReOrderBtn = false;
  public isComplete = false;
  public msg = "";
  /**
   * Taking dsData variable for checking form data is changed or not only for comaprison
   */
   public tempSelectedAgent: any = null;
   public dsData: any = {};
   /**************************************************************** */
   @Prop()
   public isTaskDisabled: any;
  public isOrderPlacedOnce = false;
  
   public async getCondoQuestionarie() {
    try {
      this.callLoader(true, 1);
      let response = await Axios.get(
        BASE_API_URL +
        "processor/getCondoQuestionarie/" +
        this.$route.query.id +
        "/" +
        this.taskId +
        "/" +
        this.countToSendOnServer,
        {
          headers: authHeader(),
        }
        );

      if (response.status === 201) {
        this.taskCompleted = response.data.isCompleted;
        this.condoQuestionnaireOrder = response.data.condoQuestionnaireOrder
        ? JSON.parse(JSON.stringify(response.data.condoQuestionnaireOrder))
        : new VerificationOfAgent();
        this.showReOrderBtn = response.data.showReOrderBtn;
        this.showHistoryBtn = response.data.showHistoryBtn;
        this.dsData = response.data.condoQuestionnaireOrder
        ? JSON.parse(JSON.stringify(response.data.condoQuestionnaireOrder))
        : new VerificationOfAgent();
      }
      if (this.condoQuestionnaireOrder.condoAgentInfo.condoVendorId)
        this.selectedCompany = this.condoVendorList.find(
          (e) =>
          e._id == this.condoQuestionnaireOrder.condoAgentInfo.condoVendorId
          );
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async checkSaveValidation() {
    try {
      let returnV = false;
      if (
        !this.condoQuestionnaireOrder.orderPlaced &&
        this.condoQuestionnaireOrder.orderPlaced == this.dsData.orderPlaced &&
        !this.condoQuestionnaireOrder.condoAgentInfo.option &&
        this.dsData.condoAgentInfo.option == this.dsData.condoAgentInfo.option
        ) {
        if (!this.selectedAgent && this.selectedAgent == this.tempSelectedAgent)
          returnV = true;
        if (this.condoAgents.length == 1 && this.selectedAgent) returnV = true;
      }
      return returnV;
    } catch (error) {
      console.log(error);
    }
  }

  public async checkSaveValidation2() {
    try {
      let returnV = false;
      if (
        this.condoQuestionnaireOrder.condoAgentInfo.option == "otherContact"
        ) {
        if (
          this.condoQuestionnaireOrder.condoAgentInfo.otherContact.option ==
          null
          ) {
          returnV = true;
      } else if (
        this.condoQuestionnaireOrder.condoAgentInfo.otherContact
        .contactEmail == null &&
        this.condoQuestionnaireOrder.condoAgentInfo.otherContact.contactFax ==
        null
        ) {
        returnV = true;
      } else if (
        this.condoQuestionnaireOrder.condoAgentInfo.otherContact.firstName ==
        null &&
        this.condoQuestionnaireOrder.condoAgentInfo.otherContact.lastName ==
        null &&
        this.condoQuestionnaireOrder.condoAgentInfo.otherContact
        .companyName == null
        ) {
        returnV = true;
      }
      if (
        this.condoQuestionnaireOrder.condoAgentInfo.otherContact.firstName ===
        null
        ) {
        returnV = true;
    } else if (
      this.condoQuestionnaireOrder.condoAgentInfo.otherContact.lastName ===
      null
      ) {
      returnV = true;
    } else if (
      this.condoQuestionnaireOrder.condoAgentInfo.otherContact
      .companyName === null
      ) {
      returnV = true;
    }
  } else {
    returnV = false;
  }

  return returnV;
} catch (error) {
  console.log(error);
}
}

public async checkSaveValidation3() {
  try {
    let reV = false;
    if (this.condoQuestionnaireOrder.condoAgentInfo.option == "fax") {
      if (this.condoQuestionnaireOrder.condoAgentInfo.fax == null) {
        reV = true;
      }
    } else {
      reV = false;
    }

    return reV;
  } catch (error) {
    console.log(error);
  }
}

public async checkSaveValidation4() {
  try {
    let reV = false;
    if (this.condoQuestionnaireOrder.condoAgentInfo.option == "thirdParty") {
      if (this.condoQuestionnaireOrder.condoAgentInfo.condoVendorId == null) {
        reV = true;
      }
    } else {
      reV = false;
    }

    return reV;
  } catch (error) {
    console.log(error);
  }
}

public async saveCondoQuestionnaire() {
    if(this.isOrderPlacedOnce) return
  this.callLoader(true, 2);
  try {
    if (this.isTaskDisabled) {
      this.callLoader(false, null);
      this.$snotify.info("Task is not assigned to you.");
      this.getCondoQuestionarie();
      return;
    }
    if (!this.isComplete) {
      let notValid1 = await this.checkSaveValidation();
      if (notValid1) {
        this.callLoader(false, null);
        this.$snotify.info("No Changes");
        return;
      }
    }
    if (this.isComplete) {
      let notValid2 = await this.checkSaveValidation2();
      let notValid3 = await this.checkSaveValidation3();
      let notValid4 = await this.checkSaveValidation4();

      if (!this.condoQuestionnaireOrder.orderPlaced) {
        this.error.show = true;
        this.error.msg = "All Fields are Required.";
        this.callLoader(false, null);
        return;
      }
      
      if (this.condoQuestionnaireOrder.condoAgentInfo.option === null) {
        this.error.show = true;
        this.error.msg = "Please select atleast one option to send Condo Questionnaires.";
        this.callLoader(false, null);
        return;
      }
      if (!this.selectedAgent) {
        this.error.show = true;
        this.error.msg = "Agent Not Added yet.";
        this.callLoader(false, null);
        return;
      }
      if (
        this.condoQuestionnaireOrder.condoAgentInfo.condoVendorId ==
        "AddOther"
        ) {
        this.error.show = true;
      this.error.msg = "Please select a third party.";
      this.callLoader(false, null);
      return;
    }
    if (notValid2 || notValid3 || notValid4) {
      this.error.show = true;
      this.error.msg = "All Fields are Required.";
      this.callLoader(false, null);
      return;
    }
    
  }
 if (
        (this.isComplete &&
          this.condoQuestionnaireOrder.condoAgentInfo.option == null) ||
        (this.condoQuestionnaireOrder.condoAgentInfo.option == "email" &&
          (this.condoQuestionnaireOrder.condoAgentInfo.email == null ||
            this.condoQuestionnaireOrder.condoAgentInfo.email == "")) ||
        (this.condoQuestionnaireOrder.condoAgentInfo.option == "fax" &&
          (this.condoQuestionnaireOrder.condoAgentInfo.fax == null ||
            this.condoQuestionnaireOrder.condoAgentInfo.fax == ""))
      ) {
        this.error.show = true;
        this.error.msg = "All fields are Required.";
        this.callLoader(false, null);
        return;
      }
   let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      let checkvalidEmailData =
        this.condoQuestionnaireOrder.condoAgentInfo.option == "email" &&
        !this.condoQuestionnaireOrder.condoAgentInfo.email.match(mailformat);
      let checkvalidEmailDataForother =
        this.condoQuestionnaireOrder.condoAgentInfo.otherContact
          .contactEmail &&
        !this.condoQuestionnaireOrder.condoAgentInfo.otherContact.contactEmail.match(
          mailformat
        );

      let faxformat = /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
      let checkvalidFaxData =
        this.condoQuestionnaireOrder.condoAgentInfo.option == "fax" &&
        !this.condoQuestionnaireOrder.condoAgentInfo.fax.match(faxformat);
      let checkvalidFaxDataForother =
        this.condoQuestionnaireOrder.condoAgentInfo.otherContact
          .contactFax &&
        !this.condoQuestionnaireOrder.condoAgentInfo.otherContact.contactFax.match(
          faxformat
        );

      if (
        this.isComplete &&
        (checkvalidFaxData || checkvalidFaxDataForother)
      ) {
        this.error.show = true;
        this.error.msg = "Enter a valid fax.";
        this.callLoader(false, null);
        return;
      }
      if (
        this.isComplete &&
        (checkvalidEmailData || checkvalidEmailDataForother)
      ) {
        this.error.show = true;
        this.error.msg = "Please Enter Valid Email ";

        this.callLoader(false, null);
        return;
      }
  if(this.isComplete) this.isOrderPlacedOnce=true;
  let response = await Axios.post(
    BASE_API_URL + "processor/saveCondoQuestionnaire",
    {
      loanId: this.$route.query.id,
      taskId: this.taskId,
      condoQuestionnaireOrder: this.condoQuestionnaireOrder,
      reOrderCount: this.countToSendOnServer,
      isComplete: this.isComplete,
      selectedAgent: this.selectedAgent,
      agentType: 6,
      formData: this.dsData
    },
    {
      headers: authHeader(),
    }
    );

  if (response.status === 201)
    this.$snotify.success(
      this.isComplete
      ? " Condo Questionnaire Order Completed successfully."
      : "Condo Questionnaire Order Information has been saved"
      );
  if (this.isComplete) this.$emit("refresh-task-list", true);
  await this.getCondoQuestionarie();
} catch (error) {
  console.log(error);
}
this.callLoader(false, null);
}

public callLoader(loader, loaderTitleId) {
  this.$store.state.tmpcoLoader = loader;
  this.$store.state.loaderTitleId = loaderTitleId;
}

public async getCondoVendorList() {
  this.callLoader(true, null);
  try {
    const response = await Axios.get(
      BASE_API_URL + "processor/getCondoVendorList/" + this.loanId,
      {
        headers: authHeader(),
      }
      );

    if (response.status === 201) {
      this.condoVendorList = response.data.condoVendor;
    }
  } catch (error) {
    console.log(error);
  }
  this.callLoader(false, null);
}

public openModalOnChange(event) {
  if (this.isTaskDisabled) {
    this.callLoader(false, null);
    this.$snotify.info("Task is not assigned to you.");
    this.getCondoQuestionarie();
    return;
  }
  if (event.target.value == "AddOther") {
    this.$modal.show("AddOtherVendor");
    this.isCredential = false;
    this.selectedCompany = null;
  } else {
    this.selectedCompany = this.condoVendorList.find(
      (e) =>
      e._id == this.condoQuestionnaireOrder.condoAgentInfo.condoVendorId
      );
    this.isCredential = this.selectedCompany.Credential ? true : false;
  }
}

public onChangeOfChooseMethod(event) {
  if (event.target.value == "email") {
    this.condoQuestionnaireOrder.condoAgentInfo.condoVendorId = null;
    this.condoQuestionnaireOrder.condoAgentInfo.fax = null;
    this.condoQuestionnaireOrder.condoAgentInfo.otherContact.option = null;
    this.condoQuestionnaireOrder.condoAgentInfo.otherContact.firstName = null;
    this.condoQuestionnaireOrder.condoAgentInfo.otherContact.lastName = null;
    this.condoQuestionnaireOrder.condoAgentInfo.otherContact.companyName =
    null;
    this.condoQuestionnaireOrder.condoAgentInfo.otherContact.contactEmail =
    null;
    this.condoQuestionnaireOrder.condoAgentInfo.otherContact.contactFax =
    null;
    this.condoQuestionnaireOrder.condoAgentInfo.email =
    this.agent.agentContactData.email;
      // this.isEmail = true;
    } else if (event.target.value == "fax") {
      this.condoQuestionnaireOrder.condoAgentInfo.condoVendorId = null;
      this.condoQuestionnaireOrder.condoAgentInfo.email = null;
      //this.condoQuestionnaireOrder.condoAgentInfo.fax = null;
      this.condoQuestionnaireOrder.condoAgentInfo.otherContact.firstName = null;
      this.condoQuestionnaireOrder.condoAgentInfo.otherContact.lastName = null;
      this.condoQuestionnaireOrder.condoAgentInfo.otherContact.companyName =
      null;
      this.condoQuestionnaireOrder.condoAgentInfo.otherContact.option = null;
      this.condoQuestionnaireOrder.condoAgentInfo.otherContact.contactEmail =
      null;
      this.condoQuestionnaireOrder.condoAgentInfo.otherContact.contactFax =
      null;
      // this.isEmail=false;
    } else if (event.target.value == "thirdParty") {
      this.condoQuestionnaireOrder.condoAgentInfo.email = null;
      this.condoQuestionnaireOrder.condoAgentInfo.fax = null;
      this.condoQuestionnaireOrder.condoAgentInfo.otherContact.option = null;
      this.condoQuestionnaireOrder.condoAgentInfo.otherContact.firstName = null;
      this.condoQuestionnaireOrder.condoAgentInfo.otherContact.lastName = null;
      this.condoQuestionnaireOrder.condoAgentInfo.otherContact.companyName =
      null;
      this.condoQuestionnaireOrder.condoAgentInfo.otherContact.contactEmail =
      null;
      this.condoQuestionnaireOrder.condoAgentInfo.otherContact.contactFax =
      null;
      // this.isEmail = false;
    } else if (event.target.value == "contactEmail") {
      this.condoQuestionnaireOrder.condoAgentInfo.otherContact.contactEmail =
      null;
      this.condoQuestionnaireOrder.condoAgentInfo.otherContact.contactFax =
      null;
    } else if (event.target.value == "contactFax") {
      this.condoQuestionnaireOrder.condoAgentInfo.otherContact.contactEmail =
      null;
      this.condoQuestionnaireOrder.condoAgentInfo.otherContact.contactFax =
      null;
    } else {
      this.condoQuestionnaireOrder.condoAgentInfo.email = null;
      this.condoQuestionnaireOrder.condoAgentInfo.fax = null;
      // this.isEmail = false;
    }
  }

  public async reOrderCondo() {
    this.callLoader(true, 1);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getCondoQuestionarie();
        return;
      }
      this.$refs.refReOrder["confitmationBox"]({
        message: "Are you sure you want to RE-Order Condo Questionnaire?",
        apiPath: `processor/reOrderCondo`,
        data: { loanId: this.$route.query.id, taskId: this.taskId, selectedAgent: this.selectedAgent },
      });
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async getListOfAgents() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "processor/getListOfAgents",
        {
          loanId: this.$route.query.id,
          agentType: 6,
        },
        {
          headers: authHeader(),
        }
        );

      if (response.status === 201) {
        this.condoAgents = response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async getSelectedAgent() {
    try {
      this.agent = {}
      this.selectedAgent = null;
      let response = await Axios.post(
        BASE_API_URL + "processor/getSelectedAgent",
        {
          loanId: this.$route.query.id,
          agentType: 6,
          taskId: this.taskId,
          agentId: this.condoQuestionnaireOrder.agentContactId,
          companyId: this.condoQuestionnaireOrder.companyId,
          lenderId: this.condoQuestionnaireOrder.lenderId,
          isLoanCompleted : this.taskCompleted
        },
        {
          headers: authHeader(),
        }
      );
      if (response.status == 201) {
        if (
          response.data.agentData &&
          response.data.agentData.agentContactData &&
          response.data.agentData.agentData
          ) {
          this.agent = response.data.agentData;
        this.selectedAgent = JSON.parse(
          JSON.stringify(this.agent.agentContactData._id)
          );
        this.tempSelectedAgent = JSON.parse(
          JSON.stringify(this.agent.agentContactData._id)
          );
      }
      this.condoQuestionnaireOrder.lenderId = response.data.lenderId
      this.condoQuestionnaireOrder.agentContactId = this.agent.agentContactData._id;
      this.condoQuestionnaireOrder.companyId = this.agent.agentData._id;
      this.mortgageClause = response.data.mortgageClause;
      this.condoQuestionnaireOrder.condoAgentInfo.email =
      this.agent &&
      this.agent.agentContactData &&
      this.agent.agentContactData.email;
        // this.isEmail = this.condoQuestionnaireOrder.condoAgentInfo.email
        //   ? true
        //   : false;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public onChangingAgent() {
    this.agent = this.condoAgents.find(
      (e) => e.agentContactId == this.selectedAgent
      ).agentData;
    this.condoQuestionnaireOrder.condoAgentInfo.email =
    this.agent.agentContactData.email;
    this.condoQuestionnaireOrder.agentContactId = this.agent.agentContactData._id;
    this.condoQuestionnaireOrder.companyId = this.agent.agentData._id;
  }

  public async showHistory() {
    this.callLoader(true, 1);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getCondoQuestionarie();
        return;
      }
      this.$refs.refReOrder["showHistory"](this.taskId, "Condo");
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async refreshAfterAddingVendor() {
    await this.getCondoVendorList();
    this.condoQuestionnaireOrder.condoAgentInfo.condoVendorId = null;
  }

  public async allMountFunction(count) {
    this.countToSendOnServer = count;
    await this.getCondoVendorList();
    await this.getListOfAgents();
    await this.getCondoQuestionarie();
    await this.getSelectedAgent();
  }

  public mounted() {
    this.allMountFunction(this.reOrderCount);
    EventBus.$on(
      "getCondoVendorList",
      async () => await this.getCondoVendorList()
      );
  }
}
