
  import { Component, Vue } from "vue-property-decorator";
  import Axios from "axios";
  import { BASE_API_URL } from "../config";
  import { authHeader } from "../services/auth";
  import ViewDocument from './ViewDocument.vue';

  @Component({
    components: {
      ViewDocument
    }
  })
  export default class CommunicationFax extends Vue {
    /* eslint-disable */
    public communicationFax: any = []
  // public communicationMessage = ''
  public selectedComm: any = {};



public commFaxTo(data){
  let toArray: any=[]
  data.forEach((e) => {
    toArray.push(e.fax)
  })
  return toArray.toString()

}

public commFaxCC(data){
  let ccArray: any=[]
  data.forEach((e) =>{
    ccArray.push(e.fax)
  })
  return ccArray.toString()
}

  public async getCommunicationFax() {
    this.callLoader(true, null)
    try {
      let response = await Axios.get(
        BASE_API_URL + "communication/getCommunicationFax/"+this.$route.query.id,
        {
          headers: authHeader(),
        }
        );
      this.communicationFax = response.data;
      console.log(this.communicationFax,'this.communicationFax');
      
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null)
  }

   public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public async viewAttachment(attachments) {
    this.$refs.ref["viewAttachment"]({attachments:attachments,loanId:this.$route.query.id,});
  }
  public async viewCommunication(communication) {
   try {
     this.selectedComm = communication;
            // this.communicationMessage = communication.message;
            this.$modal.show("ViewCommunicationModal");
          } catch(error) {
           console.log(error)
         }
       }

       async mounted() {
        this.getCommunicationFax();
      }
      /* eslint-disable */
    }
  