
import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL, EventBus } from "../../config";
import { authHeader } from "../../services/auth";
import ViewDocument from "../ViewDocument.vue";
import { DOCUMENT_CATEGORY } from "../../constants/constants";
import moment from "moment";

@Component({
  components: {
    ViewDocument,
  },
})
export default class ThirdPartyDocs extends Vue {
  /* eslint-disable */
  @Prop()
  public documentValidation;
  public documents: any = [];
  public loanId: any = this.$route.query.id;
  public files = [];
  public isValid: any = false;
  public loanDetails: any = null;
  public hasSubmit = false;
  public isUploading = false;
  public docId = null;
  public documentCategory = DOCUMENT_CATEGORY;
  public document: any = {};
  public isRejected = false;
  public isAccepted = false;
  public isChecklist = true;

  public async getThirdPartyDocuments() {
    try {
      let response = await Axios.get(
        BASE_API_URL +
          "loanChecklist/getThirdPartyDocuments/" +
          this.$route.query.id,
        {
          headers: authHeader(),
        }
      );
      this.documents = response.data.documents;
      this.documents = this.documents.map((ele) => {
        let msg = "";
        if (
          ele &&
          ele.allRejectedComments &&
          ele.allRejectedComments.length > 0
        ) {
          for (let j = 0; j < ele.allRejectedComments.length; j++) {
            const e = ele.allRejectedComments[j];
            msg += `${e.comment} ${
              e.acceptedOrRejectedOn
                ? moment(e.acceptedOrRejectedOn).format("MM/DD/YYYY, h:mm:ss a")
                : ""
            } -${e.name ? e.name : ""} *** `;
          }
        }
        ele["msgString"] = msg;

        let msg2 = "";
        if (ele.allAcceptedData && ele.allAcceptedData.length > 0) {
          for (let j = 0; j < ele.allAcceptedData.length; j++) {
            const e = ele.allAcceptedData[j];
            msg2 += `${
              e.acceptedOrRejectedOn
                ? moment(e.acceptedOrRejectedOn).format("MM/DD/YYYY, h:mm:ss a")
                : ""
            } -${e.name ? e.name : ""} *** `;
          }
        }
        ele["msgString1"] = msg2;
        return ele;
      });

      this.loanDetails = response.data.loanDetails;
      this.documents.forEach((ele: any) => {
        let isUploadedDocNotified: any = false;
        let docName: any = false;
        let Status = false;
        if (ele.docFiles && ele.docFiles.length > 0) {
          for (let i = 0; i < ele.docFiles.length; i++) {
            const element = ele.docFiles[i];
            // notified = element.files[i] ? element.files[i].isNotified : false
            isUploadedDocNotified = ele.isUploadedDocNotified
              ? ele.isUploadedDocNotified
              : false;
            docName =
              ele.name && ele.name == "Borrower Authorization" ? true : false;
            Status = element.status == "Accept" ? true : false;
          }
        }
        // ele["isNotified"] = notified
        ele["isUploadedDocNotified"] = isUploadedDocNotified;
        ele["docName"] = docName;
        ele["Status"] = Status;
      });

      console.log("documents--", this.documents);

      if (this.$store.state.sessionObj.userType === 3)
        EventBus.$emit("brokerNotifed");
      if (this.$store.state.sessionObj.userType === 2)
        EventBus.$emit("processorNotifed");
    } catch (error) {
      console.log(error);
    }
  }

  public async viewDocument(document) {
    try {
      await this.$refs.ref["showFile"]({
        documentId: document._id,
        loanId: this.$route.query.id,
        docName: document.name,
        docCategory: document.category,
        isComplete: document.isComplete,
        borrowerName: document.borrowerName,
        isRejected: this.isRejected,
        isAccepted: this.isAccepted,
        isChecklist: this.isChecklist,
        // uploadedBy: document.uploadedBy
      });
    } catch (error) {
      console.log(error);
    }
  }

  public async uploadDocument(event, document) {
    try {
      document.isUploading = true;
      let files = event.target.files;
      let formData: any = new FormData();
      this.docId = document._id;
      formData.append("loanId", this.loanId);
      formData.append("documentId", document._id);
      if (document.borrowerUserId)
        formData.append("borrowerUserId", document.borrowerUserId);
      Array.from(files).forEach((f) => {
        formData.append("file", f);
      });
      let response = await Axios.post(
        BASE_API_URL + "loanChecklist/uploadDocument",
        formData,
        {
          headers: authHeader(),
        }
      );
      if (response.status == 201) {
        this.$snotify.success("Document has been uploaded");
        let doc = response.data.checklist.find((c) => c._id == document._id);
        document["docStatus"] = doc.docStatus;
        document["docFiles"] = doc.docFiles;
        document["isUploading"] = false;
        document["isComplete"] = doc.isComplete;
        // EventBus.$emit("processorNotifed");
        await this.getThirdPartyDocuments();
        if (this.$route.query.name == "disclosure" && this.hasSubmit) {
          await this.validateDocuments();
          EventBus.$emit("validation");
        }
      }
    } catch (error) {
      console.log(error);
      document["isUploading"] = false;
    }
  }

  public validateDocuments() {
    let hasDocToUpload: any;
    if (this.loanDetails.idsHandledByTMPCO) {
      this.documents.forEach((d: any) => {
        Vue.set(
          d,
          "showValidation",
          d.source == "Broker Disclosure" ? true : false
        );
      });
      hasDocToUpload = this.documents.find(
        (d: any) =>
          d.source == "Broker Disclosure" && d.docStatus === "Required"
      );
      if (this.$route.query.name === "disclosure") {
        this.documents.forEach((d: any) => {
          Vue.set(
            d,
            "showValidation",
            d.docStatus == "Required" ? true : false
          );
        });
        hasDocToUpload = this.documents.find(
          (d: any) => d.docStatus === "Required"
        );
      }
    } else {
      this.documents.forEach((d: any) => {
        Vue.set(d, "showValidation", d.docStatus == "Required" ? true : false);
      });
      hasDocToUpload = this.documents.find(
        (d: any) => d.docStatus == "Required"
      );
    }
    return hasDocToUpload ? false : true;
  }

  public async updateLoanChecklist(documentId, isComplete) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "loanChecklist/updateLoanChecklist",
        {
          loanId: this.loanId,
          documentId: documentId,
          isComplete: isComplete,
        },
        {
          headers: authHeader(),
        }
      );
      if (response.data == 200) {
        this.$snotify.success("Checklist has been Updated");
      }
    } catch (error) {
      console.log(error);
    }
  }

  public getDate(document) {
    let files = document.docFiles[document.docFiles.length - 1].files;
    document.lastUpdatedOn = files[files.length - 1].uploadedOn
      ? files[files.length - 1].uploadedOn
      : null;
    return document;
  }

  public isProcessor() {
    return this.loanDetails.userType == 3 || this.loanDetails.userType == 1
      ? true
      : false;
  }

  public getDocName(document) {
    if (document.name && document.name != null) {
      return (
        document.name.replace(`<p>\g`, "").replace(`</p>\g`, "") +
        `  <strong>${
          document.borrowerName ? "" + document.borrowerName + "" : ""
        }</strong>`
      );
    }
  }

  public isUploadedByProcessor(document) {
    // let isUploadedByProcessor = false;
    // if (document.hasOwnProperty("toBeUploadedBy"))
    //   isUploadedByProcessor = document.toBeUploadedBy.find((u: any) => u == 3);
    //   console.log(isUploadedByProcessor,this.$store.state.sessionObj.userType);

    return this.$store.state.sessionObj.userType == 2 ||
      this.$store.state.sessionObj.userType == 4
      ? true
      : false;
  }

  public async mounted() {
    await this.getThirdPartyDocuments();
    if (this.documentValidation) {
      this.validateDocuments();
    }

    EventBus.$on("refreshDocs", async () => {
      await this.getThirdPartyDocuments();
    });
  }

  /* eslint-disable */
}
