
import { Component, Vue, Prop } from "vue-property-decorator";
import { BASE_API_URL } from "../../../config";
import { authHeader } from "../../../services/auth";
import ReOrderTask from "../../../common-components/ReOrderTask.vue";
import Axios from "axios";
@Component({
  components: {
    ReOrderTask,
  },
})
export default class ReInspectionSubjectToCompletionOrder extends Vue {
  /* eslint-disable */

  @Prop()
  public taskId: any;
  @Prop()
  public isLoanInactive: any;
  public reInspectionOrderSubjectToCompletion: any = {};
  public loanId = this.$route.query.id;
  public error: any = false;
  public isOrderPlacedOnce = false;
  // @Prop()
  // public reOrderCount: any;
  @Prop()
  public secondAppraisalCount: any;
  public isTaskCompleted = false;
  // public countToSendOnServer: any = null;
  public secondAppraisalCountForServer: any = null;
  public showReOrderBtn = false;
  public showHistoryBtn = false;
  @Prop()
  public totalTaskCount: any;
  public totalTaskCountForServer = null;
  @Prop()
  public isTaskDisabled: any;
  /**
   * Taking dsData variable for checking form data is changed or not only for comaprison
   */
  public dsData: any = {};
  /**************************************************************** */

  public async checkSaveValidation() {
    try {
      if (
        !this.reInspectionOrderSubjectToCompletion.orderPlaced &&
        this.reInspectionOrderSubjectToCompletion.orderPlaced ==
          this.dsData.orderPlaced
      )
        return true;
      else return false;
    } catch (error) {
      console.log(error);
    }
  }

  public async saveReInspectionSubjectToCompletionOrderInfo(isComplete) {
     if(this.isOrderPlacedOnce) return
    this.callLoader(true, null);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        await this.getReInspectionSubjectToCompletionOrderInfo();
        return;
      }
      if (
        isComplete &&
        !this.reInspectionOrderSubjectToCompletion.orderPlaced
      ) {
        this.error = true;
        this.callLoader(false, null);
        return;
      }
      if (!isComplete) {
        let notValid1 = await this.checkSaveValidation();
        if (notValid1) {
          this.callLoader(false, null);
          this.$snotify.info("No Changes");
          return;
        }
      }
   if(isComplete) this.isOrderPlacedOnce=true;

      let response = await Axios.post(
        BASE_API_URL + "processor/saveReInspectionSubjectToCompletionOrderInfo",
        {
          loanId: this.loanId,
          reInspectionOrderSubjectToCompletion:
            this.reInspectionOrderSubjectToCompletion,
          taskId: this.taskId,
          isComplete: isComplete,
          totalTaskCount: this.totalTaskCountForServer,
          // reOrderCount: this.countToSendOnServer,
          secondAppraisalCount: this.secondAppraisalCountForServer,
          formData: this.dsData,
        },
        { headers: authHeader() }
      );

      if (response.status === 201)
        this.$snotify.success(
          isComplete
            ? "Re Inspection Completed successfully."
            : "Re Inspection Order Information has been saved"
        );
      if (isComplete) this.$emit("refresh-task-list", true);
      await this.getReInspectionSubjectToCompletionOrderInfo();
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async getReInspectionSubjectToCompletionOrderInfo() {
    this.callLoader(true, null);
    try {
      const response = await Axios.post(
        BASE_API_URL + "processor/getReInspectionSubjectToCompletionOrderInfo",
        {
          loanId: this.$route.query.id,
          taskId: this.taskId,
          totalTaskCount: this.totalTaskCountForServer,
          // reOrderCount: this.countToSendOnServer,
          secondAppraisalCount: this.secondAppraisalCountForServer,
        },
        { headers: authHeader() }
      );
      if (response.status === 201) {
        this.reInspectionOrderSubjectToCompletion = response.data
          .reInspectionOrderSubjectToCompletion
          ? JSON.parse(
              JSON.stringify(response.data.reInspectionOrderSubjectToCompletion)
            )
          : {};
        this.dsData = response.data.reInspectionOrderSubjectToCompletion
          ? JSON.parse(
              JSON.stringify(response.data.reInspectionOrderSubjectToCompletion)
            )
          : {};
        this.isTaskCompleted = response.data.isComplete;
        this.showReOrderBtn = response.data.showReOrderBtn;
        this.showHistoryBtn = response.data.showHistoryBtn;
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public async reOrderReInspectionSubjectToCompletion() {
    this.callLoader(true, 1);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        return;
      }
      this.$refs.refReOrder["confitmationBox"]({
        message:
          "Are you sure you want to RE-Order Re-Inspection Subject To Completion?",
        apiPath: `processor/reOrderReInspectionSubjectToCompletion`,
        data: { loanId: this.$route.query.id, taskId: this.taskId },
      });
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async showHistory() {
    this.callLoader(true, 1);
    try {
      this.$refs.refReOrder["showHistory"](this.taskId);
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async allMountFunction(count, secondAppraisalCount) {
    this.totalTaskCountForServer = count;
    // this.countToSendOnServer = count;
    this.secondAppraisalCountForServer = secondAppraisalCount;
    await this.getReInspectionSubjectToCompletionOrderInfo();
  }

  async mounted() {
    await this.allMountFunction(this.totalTaskCount, this.secondAppraisalCount);
    //  this.allMountFunction(this.reOrderCount, this.secondAppraisalCount);
  }
}

/* eslint-disable */
