
import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../config";
import { authHeader } from "../services/auth";

@Component
export default class ReOrderTask extends Vue {
  /* eslint-disable */
  public message = null;
  public reasonOfReordering = null;
  public aPIPath = null;
  public aPIData: any = {};
  public reOrderHistoryData: any = [];
  public taskName = "";

  public cancel() {
    try {
      this.$modal.hide("reOrderHistory");
      this.$modal.hide("reOrderHistoryOfVerificationTask");
      this.$modal.hide("reasonOfReordering");
      // this.$modal.hide("reOrder");
      this.message = null;
      this.aPIPath = null;
      this.aPIData = null;
      this.reasonOfReordering = null;
    } catch (error) {
      console.log(error);
    }
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public async confitmationBox(body) {
    try {
      this.message = body.message;
      this.aPIPath = body.apiPath;
      this.aPIData = body.data;
      this.$modal.show("reasonOfReordering");
    } catch (error) {
      console.log(error);
    }
  }

  public async reOrderTask() {
    this.callLoader(true, null);
    try {
      if (!this.aPIPath || !this.aPIData) {
        this.callLoader(false, null);
        return;
      }

      this.aPIData.reasonOfReordering = this.reasonOfReordering;
      let response = await Axios.post(
        BASE_API_URL + this.aPIPath,
        this.aPIData,
        { headers: authHeader() }
      );

      if (response.status === 201) {
        this.$snotify.success("Task Re-Ordered");
      }
      await this.cancel();
      await this.$emit("refresh-task-after-reorder");
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async showHistory(taskId, taskName) {
    try {
      this.taskName = taskName;
      let response = await Axios.get(
        BASE_API_URL +
          "processor/getReOrderHistoryData/" +
          this.$route.query.id +
          "/" +
          taskId,
        {
          headers: authHeader(),
        }
      );
      if (response.status === 201) {
        this.reOrderHistoryData = response.data;
        this.$modal.show("reOrderHistory");
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async showHistoryOfVerificationTask(taskId, taskName) {
    try {
      this.taskName = taskName;
      let response = await Axios.get(
        BASE_API_URL +
          "processor/getReOrderHistoryOfVerificationTask/" +
          this.$route.query.id +
          "/" +
          taskId,
        {
          headers: authHeader(),
        }
      );
      if (response.status === 201) {
        this.reOrderHistoryData = response.data;
        this.$modal.show("reOrderHistoryOfVerificationTask");
      }
    } catch (error) {
      console.log(error);
    }
  }
}
