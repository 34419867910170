
/* eslint-disable */
// IMPORT-----------------------------------------------------------
import { Component, Vue, Provide } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../config";
import { authHeader } from "../services/auth";
import pdf from "vue-pdf";
// import { PdfViewerPlugin, Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, Annotation, ThumbnailView, Print, TextSelection, TextSearch } from '@syncfusion/ej2-vue-pdfviewer';
// Vue.use(PdfViewerPlugin);
// IMPORT END-------------------------------------------------------

@Component({
  components: {
    pdf,
  },
})
export default class ViewDocument extends Vue {
  // IMPORT-----------------------------------------------------------
  public docName: any = null;
  public subDocName: any = null;
  public docType: any = null;
  public originalFilename = null;
  public files: any = [];
  public downloadFileContent: any = null;
  public fileToDisplay: any = null;
  public showPrevious = false;
  public showNext = false;
  public length = 0;
  public currentIndex = 0;
  public msgContent: any = {};
  public comment = "";
  public status = null;
  public statusError = "Please Select status";
  public errors = false;
  public loanId: any = null;
  public docId = null;
  public isComplete = false;
  public fileType: any = null;
  public borrowerName = null;
  public sessionObject: any = null;
  public uploadedBy = null;
  public numPages = 0;
  public loadedRatio = 0;
  public page = 1;
  public rotate = 0;
  public docStatus = null;
  public acceptedOrRejectedBY = null;
  public acceptedOrRejectedOn = null;
  public rejectedComment = null;
  public isUploadedDocNotified = false;
  public isConfirmation = false;

  // public loanNumber = ''
  // public allAttachments: any = []
  // IMPORT END-------------------------------------------------------

  public async showFile(body) {
    try {
      this.callLoader(true, 2);
      const response = await Axios.post(
        `${BASE_API_URL}loanChecklist/getSelectedDocument`,
        body,
        { headers: authHeader() }
      );
      if (response.status === 201) {
        this.files = response.data.files.sort();
        console.log(this.files, "his.files");

        this.isUploadedDocNotified = response.data.isUploadedDocNotified;
        this.fileType =
          this.files.length > 0
            ? this.files[this.currentIndex].path.split(".").pop()
            : null;
        this.downloadFileContent =
          this.files.length > 0 ? this.files[this.currentIndex].image : null;
        if (this.fileType && this.fileType.toLowerCase() == "pdf") {
          this.fileToDisplay = pdf.createLoadingTask(
            this.files.length > 0 ? this.files[this.currentIndex].image : null
          );
          this.fileToDisplay.promise.then((pdf) => {
            this.numPages = pdf.numPages;
          });
        } else
          this.fileToDisplay =
            this.files.length > 0 ? this.files[this.currentIndex].image : null;
        this.length = this.files.length - 1;
        this.originalFilename =
          this.files.length > 0
            ? this.files[this.currentIndex].originalName
            : null;
        this.docName =
          body.docCategory == "Condition" ? "Mortgage Loan Condition" : "";
        this.subDocName = body.docName.replace(
          /<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g,
          " "
        );
        this.docType = body.docCategory;
        this.loanId = body.loanId;
        this.docId = body.documentId;
        this.borrowerName = body.borrowerName;
        this.isComplete = body.isComplete;
        // this.uploadedBy = response.data.uploadedBy
        this.acceptedOrRejectedBY = response.data.acceptedOrRejectedBy;
        this.acceptedOrRejectedOn = response.data.acceptedOrRejectedOn;
        this.rejectedComment = response.data.comment;
        this.docStatus = response.data.status;
        this.page = 1;
        await this.conditionsOfShowingPrevNextButton();
        this.$modal.show("showFileModal");
        this.callLoader(false, null);
      }
    } catch (error) {
      this.callLoader(false, null);
      console.log(error);
    }
  }

  public async showFileForNonAuthenticateUser(body) {
    this.callLoader(true, null);

    try {
      const response = await Axios.post(
        `${BASE_API_URL}authentication/getDocumentForNonAuthenticateUser`,
        body
      );
      if (response.status === 201) {
        this.files = response.data.files;

        this.fileType =
          this.files.length > 0
            ? this.files[this.currentIndex].path.split(".").pop()
            : null;
        this.downloadFileContent = this.files[this.currentIndex].image;
        if (this.fileType && this.fileType.toLowerCase() == "pdf") {
          this.fileToDisplay = pdf.createLoadingTask(
            this.files.length > 0 ? this.files[this.currentIndex].image : null
          );
          this.fileToDisplay.promise.then((pdf) => {
            this.numPages = pdf.numPages;
          });
        } else
          this.fileToDisplay =
            this.files.length > 0 ? this.files[this.currentIndex].image : null;
        // this.fileToDisplay = this.files[this.currentIndex].image;
        this.originalFilename = this.files[this.currentIndex].originalName;
        this.length = this.files.length - 1;
        this.docName = body.docName;
        this.docId = body.documentId;
        this.docType = body.docCategory;
        this.loanId = body.loanId;
        // this.loanNumber =body.loanId;
        this.conditionsOfShowingPrevNextButton();
        this.$modal.show("showFileModal");
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async conditionsOfShowingPrevNextButton() {
    if (this.files.length === 1) {
      this.showPrevious = false;
      this.showNext = false;
    } else if (
      this.files.length > 1 &&
      this.currentIndex !== 0 &&
      this.currentIndex < this.files.length - 1
    ) {
      this.showNext = true;
      this.showPrevious = true;
    } else if (this.currentIndex === this.files.length - 1) {
      this.showNext = false;
      this.showPrevious = true;
    } else {
      this.showNext = true;
      this.showPrevious = false;
    }
  }

  public async changeIndex(type) {
    if (type === "Pre") {
      this.currentIndex -= 1;
      this.fileType =
        this.files.length > 0
          ? this.files[this.currentIndex].path.split(".").pop()
          : null;
      this.downloadFileContent =
        this.files.length > 0 ? this.files[this.currentIndex].image : null;
      if (this.fileType && this.fileType.toLowerCase() == "pdf") {
        this.fileToDisplay = pdf.createLoadingTask(
          this.files.length > 0 ? this.files[this.currentIndex].image : null
        );
        this.fileToDisplay.promise.then((pdf) => {
          this.numPages = pdf.numPages;
        });

      } else
        this.fileToDisplay =
          this.files.length > 0 ? this.files[this.currentIndex].image : null;
      this.originalFilename =
        this.files.length > 0
          ? this.files[this.currentIndex].originalName
          : null;
      this.page = 1;
      await this.conditionsOfShowingPrevNextButton();
    } else if (type === "Next") {
      console.log('a')
      this.currentIndex += 1;
      this.fileType =
        this.files.length > 0
          ? this.files[this.currentIndex].path.split(".").pop()
          : null;
      this.downloadFileContent =
        this.files.length > 0 ? this.files[this.currentIndex].image : null;
      if (this.fileType && this.fileType.toLowerCase() == "pdf") {
              console.log('b')

        this.fileToDisplay = pdf.createLoadingTask(
          this.files.length > 0 ? this.files[this.currentIndex].image : null
        );
        this.fileToDisplay.promise.then((pdf) => {
          this.numPages = pdf.numPages;
        });
            this.originalFilename = this.originalFilename ? `${this.originalFilename}.pdf` : `${this.files[this.currentIndex].path}`

      } else{
              console.log('c')
this.fileToDisplay =
          this.files.length > 0 ? this.files[this.currentIndex].image : null;
      this.originalFilename =
        this.files.length > 0
          ? this.files[this.currentIndex].originalName
          : null;
      this.page = 1;
      }
        
      await this.conditionsOfShowingPrevNextButton();
    }
  }

  public async acceptRejectDocument() {
    try {
      let isValid = this.status
        ? this.status == "Accept"
          ? true
          : this.comment && this.comment.trim() != ""
          ? true
          : false
        : false;
      if (isValid) {
        const obj = {
          docId: this.docId,
          loanId: this.loanId,
          type: this.status,
          comment: this.comment,
          docName:
            this.docType == "Condition"
              ? "Mortgage Loan Conditions"
              : this.docName.replace(
                  /<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g,
                  ""
                ),
        };
        const response = await Axios.post(
          `${BASE_API_URL}communication/acceptRejectDocument`,
          obj,
          { headers: authHeader() }
        );
        if (response.status === 201) {
          this.hideModal();
          this.$emit("refresh-docs");
        }
      } else {
        this.errors = true;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public deleteConfirmation() {
    this.isConfirmation = true;
    this.$modal.show("deleteConfirmation");
  }

  public async deleteFile(docId, docFilesId) {
    this.callLoader(true, 2);
    try {
      // this.loanId = this.$route.query.id;
      let response = await Axios.post(
        BASE_API_URL + "loanChecklist/deleteFile",
        {
          loanId: this.loanId,
          docId: docId,
          docFilesId: docFilesId,
        },
        {
          headers: authHeader(),
        }
      );
      this.files.splice(this.currentIndex, 1);
      if (this.files.length == 0) this.$modal.hide("showFileModal");
      else {
        this.currentIndex
          ? await this.changeIndex("Pre")
          : (async () => {
              this.currentIndex -= 1;
              await this.changeIndex("Next");
            })();
        // let indexx = this.currentIndex
        // indexx ? await this.changeIndex("Pre") : await this.changeIndex("Next");
        // await this.conditionsOfShowingPrevNextButton();
      }
      this.$snotify.success("File Deleted Successfully");
      this.$emit("call-mount-funtion");
      this.$emit("refresh-docs");
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public hideModal() {
    this.$modal.hide("viewAttachment");
    this.$modal.hide("showFileModal");
    this.msgContent = {};
    this.files = [];
    this.fileType = null;
    this.subDocName = null;
    this.docName = null;
    this.docType = null;
    this.originalFilename = null;
    this.showNext = false;
    this.showPrevious = false;
    this.status = null;
    this.comment = "";
    this.errors = false;
    this.loanId = null;
    this.docId = null;
    this.isComplete = false;
    this.page = 1;
    this.length = 0;
    this.downloadFileContent = null;
    this.fileToDisplay = null;
    this.currentIndex = 0;
    this.$emit("call-mount-funtion");
    // this.num = 1;
  }

  public async viewAttachment(data) {
    this.callLoader(true, 2);
    console.log('data',data);
    // this.allAttachments = data.attachments
    const response = await Axios.post(
      `${BASE_API_URL}loanChecklist/downLoadAttachments`,
      data.attachments,
      { headers: authHeader() }
    );

    if (response.status === 201) {
      this.files = response.data;
                    console.log(" this.files", this.files);

      this.fileType = this.files[this.currentIndex].fileType;
      this.downloadFileContent = this.files[this.currentIndex].image;
      if (this.fileType && this.fileType.toLowerCase() == "pdf") {
              console.log(" this.files",1);

        this.fileToDisplay = pdf.createLoadingTask(
          this.files[this.currentIndex].image
        );
        this.fileToDisplay.promise.then((pdf) => {
          this.numPages = pdf.numPages;
        });
       this.originalFilename = this.originalFilename ? `${this.originalFilename}.pdf` : `${this.files[this.currentIndex].path}.pdf`

      } else {this.fileToDisplay = this.files[this.currentIndex].image;
              this.originalFilename = this.originalFilename ? `${this.originalFilename}.jpg` : `${this.files[this.currentIndex].path}.jpg`

}
      this.length = this.files.length - 1;
      this.loanId = data.loanId;
      await this.conditionsOfShowingPrevNextButton();
      this.$modal.show("viewAttachment");
    }
    this.callLoader(false, null);
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
    this.sessionObject = this.$store.state.sessionObj;
  }
}
/* eslint-disable */
