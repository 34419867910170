
import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../../../config";
import { authHeader } from "../../../services/auth";
import ViewDocument from "../../../common-components/ViewDocument.vue";
// import suneditor from "suneditor";
import FollowUpTask from "../../../common-components/FollowUpTask.vue";
// import plugins from "suneditor/src/plugins";
// import CommonNotifyOnRejection from "../../../common-components/CommonNotifyOnRejection.vue";
@Component({
  components: {
    ViewDocument,
    FollowUpTask,
    // CommonNotifyOnRejection,
  },
})
export default class VoeReceived extends Vue {
  /* eslint-disable */
  public loanId: any = this.$route.query.id;
  public voeRecieved: any = {};
  public error: any = {
    show: false,
    msg: null,
  };
  public employementData: any = [];
  public isUploading = false;
  @Prop()
  public taskId: any;
  public imageLoader = false;
  public isCompleted = false;
  @Prop()
  public isLoanInactive: any;
  @Prop()
  public reOrderCount: any;
  public countToSendOnServer: any = null;
  public isOrderPlacedOnce = false;
  public isVoeMarkedOnce = false;
  // public notifyBroker = false;
  /**
   * Taking dsData variable for checking form data is changed or not only for comaprison
   */
  public dsData: any = {};

  /**************************************************************** */
  public selectedRefId: any = null;
  public selectedBorroweId: any = null;
  @Prop()
  public isTaskDisabled: any;
  public changedValues: any = [];
  public editor: any = null;
  public errorMessage = "";
  public errorSubject = "";
  public errorOption = "";
  public errorEmail = "";
  public errorFax = "";

  public async openConfirmationPopup(refId) {
    this.selectedRefId = refId;
    this.$modal.show("OrderConfirmation");
  }

  public async performSomeTask() {
    this.$modal.hide("OrderConfirmation");
    await this.saveVoeRecievedTask(false, this.selectedRefId);
    this.selectedRefId = null;
  }

  public resetVerificationOrderData() {
    let index = this.voeRecieved.employmentInfo.findIndex(
      (e) => e.refId.toString() === this.selectedRefId
    );
    this.voeRecieved.employmentInfo[index].isVoeRecieved = false;
    this.$modal.hide("OrderConfirmation");
    this.selectedRefId = null;
  }

  public async checkSaveValidation() {
    try {
      if (
        !this.voeRecieved.isVoeRecieved &&
        this.voeRecieved.isVoeRecieved == this.dsData.isVoeRecieved
      )
        return true;
      else return false;
    } catch (error) {
      console.log(error);
    }
  }
  public cancel() {
    try {
      this.$modal.hide("folloupModal");
      this.errorMessage = "";
      this.errorSubject = "";
    } catch (error) {
      console.log(error);
    }
  }


  public async sendFollowUpMailToVoe(refId, borrowerId) {
    this.callLoader(true, 1);
    try {
      let voeRData =await this.getVoeFollowUpData(refId)
      
      await this.$refs.refFollowUp["openFollowUpModal"]({
        label: "Select method to send",
        message: "Follow Up",
        apiPath: `processor/sendFollowUpMailToWVOE`,
        data: { refId, borrowerId, loanId: this.$route.query.id, taskId: this.taskId, reOrderCount: this.countToSendOnServer, extraInfo: voeRData },
      });
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  // public async openFollowUpModal(refId, borrowerId) {
  //   try {
  //     this.selectedBorroweId = borrowerId;
  //     this.selectedRefId = refId;
  //     this.$modal.show("folloupModal");
  //     setTimeout(async () => {
  //       this.editor = await suneditor.create("pageEditor", {
  //         plugins: {
  //           ...plugins,
  //         },
  //         width: "auto",
  //         height: "100px",
  //         buttonList: [["bold"], ["list"]],
  //       });
  //     }, 1);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  // public async sendFollowUpMailToWVOE() {
  //   this.callLoader(true, 1);

  //   try {
  //     this.followUpData.message = this.editor.getContents();
  //     if (this.isTaskDisabled) {
  //       this.callLoader(false, null);
  //       this.$snotify.info("Task is not assigned to you.");
  //       this.getVoeReceivedData();
  //       return;
  //     }
  //     if (
  //       this.followUpData.message
  //         .replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, "")
  //         .trim() != ""
  //     ) {
  //       this.errorMessage = "";
  //     } else {
  //       this.errorMessage = "Please add a Message";
  //       this.errorSubject = "Please add a Subject";
  //       this.errorOption = "Please Select a method";
  //       this.errorEmail = "Please add a Email";
  //       this.errorFax = "Please add a fax";
  //     }
  //     if (
  //       this.followUpData.message
  //         .replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, "")
  //         .trim() != "" &&
  //       this.followUpData.subject &&
  //       this.followUpData.option &&
  //       (this.followUpData.email || this.followUpData.fax)
  //     ) {
  //       let response = await Axios.post(
  //         BASE_API_URL + "processor/sendFollowUpMailToWVOE",
  //         {
  //           borrowerId: this.selectedBorroweId,
  //           refId: this.selectedRefId,
  //           followUp: this.followUpData,
  //           loanId: this.$route.query.id,
  //           taskId: this.taskId,
  //         },
  //         {
  //           headers: authHeader(),
  //         }
  //       );
  //       if (response.status === 201) {
  //         this.$snotify.success("Follow-Up Mail Sent");
  //         this.cancel();
  //         this.$emit("refresh-task-after-sening-mail");
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   this.callLoader(false, null);
  // }

  public async getVoeFollowUpData(refId) {
    this.callLoader(true, null);
    try {
      this.changedValues = [];
      const response = await Axios.get(
        BASE_API_URL +
          "processor/getVoeFollowUpData/" +
          this.loanId +
          "/" +
          this.taskId +
          "/" +
          refId +
          "/" +
          this.countToSendOnServer,
        {
          headers: authHeader(),
        }
      );
      if (response.status === 201) {
        return JSON.parse(JSON.stringify(response.data));
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async uploadReport(event, employee) {
    if (this.isTaskDisabled) {
      this.callLoader(false, null);
      this.$snotify.info("Task is not assigned to you.");
      this.getVoeReceivedData();
      return;
    }
    employee.isUploading = true;
    this.callLoader(true, 1);
    try {
      // this.isUploading = false;
      let files = event.target.files;
      let formData: any = new FormData();
      formData.append("loanId", this.loanId);
      formData.append("taskId", this.taskId);
      formData.append("reOrderCount", this.reOrderCount);
      formData.append("verificationId", employee.refId);
      Array.from(files).forEach((f) => {
        formData.append("file", f);
      });

      let response = await Axios.post(
        BASE_API_URL + "processor/uploadVoeRecievedReport",
        formData,
        {
          headers: authHeader(),
        }
      );
      if (response.status === 201) {
        this.$snotify.success(response.data.message);
        employee.doc = response.data.doc;
      }
      this.getVoeReceivedData();
    } catch (error) {
      console.log(error);
    }
    employee.isUploading = false;
    this.callLoader(false, null);
  }

  public async saveVoeRecievedTask(isComplete, refId) {
    this.callLoader(true, null);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getVoeReceivedData();
        return;
      }
      // if (!isComplete) {
      //   let notValid1 = await this.checkSaveValidation();
      //   if (notValid1) {
      //     this.callLoader(false, null);
      //     this.$snotify.info("No Changes");
      //     return;
      //   }
      // }
      if (isComplete) {
        if (
          this.voeRecieved.employmentInfo.find(
            (e) => !e.isVoeRecieved && !e.remark && !e.isNA
          )
        ) {
          this.error.show = true;
          this.error.msg = "All fields are mandatory.";
          this.callLoader(false, null);
          return;
        } else if (
          this.voeRecieved.employmentInfo.find((e) => !e.doc && !e.isNA)
        ) {
          this.error.show = true;
          this.error.msg = "Please Upload WVOE Report";
          this.callLoader(false, null);
          return;
        } else if (
          this.voeRecieved.employmentInfo.find((e) => !e.remark && e.isNA)
        ) {
          this.error.show = true;
          this.error.msg = "Please Add Remark";
          this.callLoader(false, null);
          return;
        }
      }

    
      let response = await Axios.post(
        BASE_API_URL + "processor/saveVoeRecievedTask",
        {
          isComplete: isComplete,
          voeRecieved: this.voeRecieved,
          loanId: this.loanId,
          taskId: this.taskId,
          reOrderCount: this.countToSendOnServer,
          refId: refId,
        },
        {
          headers: authHeader(),
        }
      );
      if (!isComplete) {
        this.$snotify.success("WVOE Recieved Information has been saved");
        this.error.show = false;
        this.error.msg = "";
      }

  
      if (response.status == 201 && isComplete)
        this.$snotify.success(response.data.msg);
      if (isComplete) this.$emit("refresh-task-list", true);
      await this.getVoeReceivedData();
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async getVoeReceivedData() {
    this.callLoader(true, null);
    try {
      this.changedValues = [];
      const response = await Axios.get(
        BASE_API_URL +
          "processor/getVoeReceivedData/" +
          this.loanId +
          "/" +
          this.taskId +
          "/" +
          this.countToSendOnServer,
        {
          headers: authHeader(),
        }
      );
      if (response.status === 201) {
        this.voeRecieved = JSON.parse(
          JSON.stringify(response.data.voeRecieved)
        );
        this.dsData = JSON.parse(JSON.stringify(response.data.voeRecieved));
        // this.doc = response.data.doc;
        this.employementData = response.data.employementData;
        this.isCompleted = response.data.isCompleted;
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async viewDocument(document) {
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getVoeReceivedData();
        return;
      }
      document.loanId = this.$route.query.id;
      this.$refs.ref["showFile"](document);
    } catch (error) {
      console.log(error);
    }
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public async allMountFunction(count) {
    this.countToSendOnServer = count;
    this.getVoeReceivedData();
  }

  mounted() {
    this.allMountFunction(this.reOrderCount);
  }
}
