var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"loan-process loan-process-wrapper-header"},[_c('div',{staticClass:"col-lg-12 p-0"},[_c('loanInfo',{ref:"loanInfo"})],1),_c('b-card',{staticStyle:{"border-radius":"0px !important"},attrs:{"no-body":""}},[_c('b-tabs',{attrs:{"card":"","id":"tabs-list"},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[(
          _vm.$store.state.sessionObj.userType === 3 ||
          _vm.$store.state.sessionObj.userType === 1
        )?_c('b-tab',{staticClass:"p-0",attrs:{"title":"Application"},on:{"click":function($event){_vm.step = 1}}},[_c('b-card-text',[(_vm.step == 1)?_c('urla',{attrs:{"isLoanInactive":_vm.isLoanInactive}}):_vm._e()],1)],1):_vm._e(),(
          _vm.hasWorkflow &&
          (_vm.$store.state.sessionObj.userType === 3 ||
            _vm.$store.state.sessionObj.userType === 1)
        )?_c('b-tab',{staticClass:"p-0",attrs:{"title":"Workflow"},on:{"click":function($event){_vm.checkUrlaTabsValidations(2) && _vm.checkLoanHasWorkflow()}}},[_c('b-card-text',[(_vm.step == 2)?_c('workflow',{attrs:{"isLoanInactive":_vm.isLoanInactive},on:{"refreshMileStone":function($event){return _vm.refreshMileStone()}}}):(_vm.step == 1)?_c('urla',{attrs:{"isLoanInactive":_vm.isLoanInactive}}):_vm._e()],1)],1):_vm._e(),_c('b-tab',{staticClass:"p-0",attrs:{"title":"Loan Checklist"},on:{"click":function($event){return _vm.checkUrlaTabsValidations(3)}}},[_c('b-card-text',[(_vm.step == 3)?_c('documents'):(_vm.step == 1)?_c('urla',{attrs:{"isLoanInactive":_vm.isLoanInactive}}):_vm._e()],1)],1),(
          _vm.$store.state.sessionObj.userType === 3 ||
          _vm.$store.state.sessionObj.userType === 1 ||
          _vm.$store.state.sessionObj.userType === 2
        )?_c('b-tab',{staticClass:"p-0",attrs:{"title":"Communication"},on:{"click":function($event){return _vm.checkUrlaTabsValidations(4)}}},[_c('b-card-text',[(_vm.step == 4)?_c('communication'):(_vm.step == 1)?_c('urla',{attrs:{"isLoanInactive":_vm.isLoanInactive}}):_vm._e()],1)],1):_vm._e(),(
          _vm.$store.state.sessionObj.userType === 3 ||
          _vm.$store.state.sessionObj.userType === 1 ||
          _vm.$store.state.sessionObj.userType === 2
        )?_c('b-tab',{staticClass:"p-0",attrs:{"title":"Contacts"},on:{"click":function($event){return _vm.checkUrlaTabsValidations(5)}}},[_c('b-card-text',[(_vm.step == 5)?_c('contacts',{attrs:{"isLoanInactive":_vm.isLoanInactive}}):(_vm.step == 1)?_c('urla',{attrs:{"isLoanInactive":_vm.isLoanInactive}}):_vm._e()],1)],1):_vm._e(),(
          _vm.$store.state.sessionObj.userType === 3 ||
          _vm.$store.state.sessionObj.userType === 1
        )?_c('b-tab',{staticClass:"p-0",attrs:{"title":"Task Allocations"},on:{"click":function($event){return _vm.checkUrlaTabsValidations(6)}}},[_c('b-card-text',[(_vm.step == 6)?_c('task-allocation'):(_vm.step == 1)?_c('urla',{attrs:{"isLoanInactive":_vm.isLoanInactive}}):_vm._e()],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }